import { useEditContext } from "react-admin";

const CopyButton = ({ onClick }) => {
  const { record, isLoading } = useEditContext();
  if (isLoading) return null;
  return (
    <div>
      <button
        onClick={(e) => {
          e.preventDefault();
          onClick(record);
        }}
      >
        Copiar Treino
      </button>
    </div>
  );
};

export default CopyButton;
