import List from "./list";
import Create from "./create";
import Edit from "./edit";
import Show from "./show";

const UserPages = {
  list: List,
  create: Create,
  edit: Edit,
  show: Show,
};

export default UserPages;
