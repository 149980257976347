import {
  required,
  Create,
  TextInput,
  SimpleFormIterator,
  TabbedForm,
  BooleanInput,
  ArrayInput,
  RadioButtonGroupInput,
  ReferenceInput,
  NumberInput,
  FormTab,
} from "react-admin";

import { focusOptions } from "../../assets/options";

const getBasicInfo = () => (
  <FormTab label="Informações básicas">
    <ReferenceInput source="user" reference="users" />

    <TextInput
      validate={required()}
      fullWidth
      helpertext="Name"
      source="name"
    />
    <BooleanInput label="Active" source="active" />
    <RadioButtonGroupInput
      source="type"
      choices={focusOptions}
      validate={required()}
    />

    <TextInput
      fullWidth
      helpertext="Playlist para o exercício"
      source="playlist"
    />
    <TextInput fullWidth label="Comentarios do treino" source="comment" />
  </FormTab>
);

const getWarmUp = () => (
  <FormTab label="Aquecimento">
    <ArrayInput source="warmUp">
      <SimpleFormIterator>
        <ReferenceInput source="exercise" reference="exercises" />

        <TextInput label="Repetições" source="reps" />
        <TextInput label="Series" source="series" />
        <TextInput label="Descanso" source="rest" />
        <TextInput label="Carga recomendada" source="proposedLoad" />
        <NumberInput label="Tempo para o exercício (segundos)" source="timer" />
        <TextInput helpertext="Música para o exercício" source="music" />
        <TextInput label="Comentarios" source="comment" />
      </SimpleFormIterator>
    </ArrayInput>
  </FormTab>
);
const getContent = () => (
  <FormTab label="Conteúdo">
    <ArrayInput source="content">
      <SimpleFormIterator>
        <ReferenceInput source="exercise" reference="exercises" />

        <TextInput label="Repetições" source="reps" />
        <TextInput label="Series" source="series" />
        <TextInput label="Descanso" source="rest" />
        <TextInput label="Carga recomendada" source="proposedLoad" />
        <NumberInput label="Tempo para o exercício (segundos)" source="timer" />
        <TextInput helpertext="Música para o exercício" source="music" />
        <TextInput label="Comentarios" source="comment" />

        <BooleanInput label="Set?" source="isSet" />

        <ArrayInput source="setExercises">
          <SimpleFormIterator>
            <ReferenceInput source="exercise" reference="exercises" />

            <TextInput label="Repetições" source="reps" />
            <TextInput label="Series" source="series" />
            <TextInput label="Descanso" source="rest" />
            <TextInput label="Carga recomendada" source="proposedLoad" />
            <NumberInput
              label="Tempo para o exercício (segundos)"
              source="timer"
            />
            <TextInput helpertext="Música para o exercício" source="music" />
            <TextInput label="Comentarios" source="comment" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleFormIterator>
    </ArrayInput>
  </FormTab>
);

const WorkoutsCreate = (props) => (
  <Create {...props} undoable={false}>
    <TabbedForm>
      {getBasicInfo()}
      {getWarmUp()}
      {getContent()}
    </TabbedForm>
  </Create>
);

export default WorkoutsCreate;
