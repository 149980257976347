import * as React from "react";
import { List, Datagrid, TextField } from "react-admin";

const NotificationsList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit" bulkActionButtons={false} >
      <TextField label="Título" source="title" />
      <TextField label="Grupo" source="group" />
    </Datagrid>
  </List>
);

export default NotificationsList;
