import WorkoutsPages from "./workouts";

import UserPages from "./users";
import EvaluationsPages from "./evaluations";
import ExercisesPages from "./exercises";
import ManagersPages from "./managers";
import LoginPage from "./login";
import NotificationsPage from "./notifications";

const Pages = {
  Workouts: {
    list: WorkoutsPages.list,
    create: WorkoutsPages.create,
    edit: WorkoutsPages.edit,
    show: WorkoutsPages.show,
  },
  Users: {
    list: UserPages.list,
    create: UserPages.create,
    edit: UserPages.edit,
    show: UserPages.show,
  },
  Evaluations: {
    list: EvaluationsPages.list,
    create: EvaluationsPages.create,
    edit: EvaluationsPages.edit,
  },
  Exercises: {
    list: ExercisesPages.list,
    create: ExercisesPages.create,
    edit: ExercisesPages.edit,
  },
  Managers: {
    list: ManagersPages.list,
    create: ManagersPages.create,
    edit: ManagersPages.edit,
  },
  Notifications: {
    list: NotificationsPage.list,
    create: NotificationsPage.create,
    edit: NotificationsPage.edit,
  },
  LoginPage,
};

export default Pages;
