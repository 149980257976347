import * as React from "react";
import {
  required,
  Edit,
  TextInput,
  SimpleForm,
  RadioButtonGroupInput,
  BooleanInput,
  SelectInput,
} from "react-admin";

import { targetAreas, exerciseTypes } from "../../assets/options";
import { useWatch } from "react-hook-form";
import { exercises } from "./garminExerciseNames";

const categoryChoices = [
  { id: "BENCH_PRESS", name: "BENCH_PRESS" },
  { id: "CALF_RAISE", name: "CALF_RAISE" },
  { id: "CARDIO", name: "CARDIO" },
  { id: "CARRY", name: "CARRY" },
  { id: "CHOP", name: "CHOP" },
  { id: "CORE", name: "CORE" },
  { id: "CRUNCH", name: "CRUNCH" },
  { id: "CURL", name: "CURL" },
  { id: "DEADLIFT", name: "DEADLIFT" },
  { id: "FLYE", name: "FLYE" },
  { id: "HIP_RAISE", name: "HIP_RAISE" },
  { id: "HIP_STABILITY", name: "HIP_STABILITY" },
  { id: "HIP_SWING", name: "HIP_SWING" },
  { id: "HYPEREXTENSION", name: "HYPEREXTENSION" },
  { id: "LATERAL_RAISE", name: "LATERAL_RAISE" },
  { id: "LEG_CURL", name: "LEG_CURL" },
  { id: "LEG_RAISE", name: "LEG_RAISE" },
  { id: "LUNGE", name: "LUNGE" },
  { id: "OLYMPIC_LIFT", name: "OLYMPIC_LIFT" },
  { id: "PLANK", name: "PLANK" },
  { id: "PLYO", name: "PLYO" },
  { id: "PULL_UP", name: "PULL_UP" },
  { id: "PUSH_UP", name: "PUSH_UP" },
  { id: "ROW", name: "ROW" },
  { id: "SHOULDER_PRESS", name: "SHOULDER_PRESS" },
  { id: "SHOULDER_STABILITY", name: "SHOULDER_STABILITY" },
  { id: "SHRUG", name: "SHRUG" },
  { id: "SIT_UP", name: "SIT_UP" },
  { id: "SQUAT", name: "SQUAT" },
  { id: "TOTAL_BODY", name: "TOTAL_BODY" },
  { id: "TRICEPS_EXTENSION", name: "TRICEPS_EXTENSION" },
  { id: "WARM_UP", name: "WARM_UP" },
  { id: "RUN", name: "RUN" },
  { id: "BIKE", name: "BIKE" },
  { id: "CARDIO_SENSORS", name: "CARDIO_SENSORS" },
  { id: "UNKNOWN", name: "UNKNOWN" },
  { id: "INVALID", name: "INVALID" },
];

const ExerciseNameInput = () => {
  const category = useWatch({ name: "garmin.category" });

  return (
    <SelectInput
      choices={category ? exercises[category] : []}
      source="garmin.name"
    />
  );
};

const ExerciseEdit = (props) => (
  <Edit {...props} undoable={false} redirect="show">
    <SimpleForm>
      <TextInput
        validate={required()}
        fullWidth
        helpertext="Nome do exercício"
        source="name"
      />
      <TextInput fullWidth helpertext="Link do vídeo" source="video" />
      <RadioButtonGroupInput
        source="type"
        choices={exerciseTypes}
        validate={required()}
      />
      <RadioButtonGroupInput
        source="muscleGroup"
        choices={targetAreas}
        validate={required()}
      />
      <RadioButtonGroupInput
        source="secondaryMuscleGroup"
        choices={targetAreas}
      />
      <BooleanInput source="premium" />

      <SelectInput source="garmin.category" choices={categoryChoices} />
      <ExerciseNameInput />
    </SimpleForm>
  </Edit>
);

export default ExerciseEdit;
