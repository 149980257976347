import configAxios from "./axios";
import auth from "./auth";
import users from "./users";
import managers from "./managers";
import exercises from "./exercises";
import workouts from "./workouts";
import evaluations from "./evaluations";
import notifications from "./notifications";
import location from "./location";

export function configClient() {
  const axios = configAxios();

  return {
    auth: auth(axios),
    users: users(axios),
    managers: managers(axios),
    exercises: exercises(axios),
    workouts: workouts(axios),
    evaluations: evaluations(axios),
    notifications: notifications(axios),
    location: location(axios),
  };
}

export default configClient;
