const exercises = (axios) => ({
  create: (params) => axios.post("/admin/exercises", params),

  list: (params) => axios.get("/admin/exercises/list", { params }),
  many: (ids) => axios.post("/admin/exercises/many", { ids }),

  show: (exerciseId) => axios.get(`/admin/exercises/${exerciseId}`),
  delete: (exerciseId) => axios.delete(`/admin/exercises/${exerciseId}`),
  update: (params) => axios.patch(`/admin/exercises/${params.id}`, params),
});

export default exercises;
