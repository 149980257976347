import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  SearchInput,
  BooleanInput,
  SelectArrayInput,
} from "react-admin";

import { targetAreas, exerciseTypes } from "../../assets/options";

const exerciseFilters = [
  <SearchInput source="name" alwaysOn />,
  <BooleanInput source="premium" />,
  <BooleanInput source="hasVideo" />,
  <SelectArrayInput source="muscleGroup" choices={targetAreas} />,
  <SelectArrayInput source="type" choices={exerciseTypes} />,
];

const ExerciseList = (props) => (
  <List filters={exerciseFilters} {...props}>
    <Datagrid rowClick="edit" bulkActionButtons={false} >
      <TextField label="Nome" source="name" />
      <TextField label="Tipo de exercício" source="type" />
      <TextField label="Grupo Muscular" source="muscleGroup" />
      <BooleanField label="Tem vídeo" source="hasVideo" />
      <BooleanField label="Premium" source="premium" />
    </Datagrid>
  </List>
);

export default ExerciseList;
