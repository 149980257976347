import * as React from "react";
import { List, Datagrid, TextField, EditButton, DateField } from "react-admin";

export const EvaluationsList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="user.name" />
      <TextField source="user.surname" />
      <TextField source="type" />
      <TextField source="objective" />
      <DateField
        source="createdAt"
        locales="pt-BR"
        options={{ timeZone: "UTC" }}
      />
      <DateField
        source="updatedAt"
        locales="pt-BR"
        options={{ timeZone: "UTC" }}
      />
      <EditButton />
    </Datagrid>
  </List>
);

export default EvaluationsList;
