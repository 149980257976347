import * as React from 'react'
import {
  required,
  Create,
  TextInput,
  SimpleForm,
} from 'react-admin'

const ManagersCreate = props => (
  <Create {...props} undoable={false}>
    <SimpleForm>
      <TextInput
        validate={required()}
        fullWidth
        helpertext="Name"
        source="name"
      />
      <TextInput
        fullWidth
        helpertext="email"
        source="email"
        validate={required()}
      />
      <TextInput
        fullWidth
        helpertext="password"
        source="password"
        validate={required()}
      />
    </SimpleForm>
  </Create>
)

export default ManagersCreate;