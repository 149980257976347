import * as React from "react";
import { defaultTheme, Admin, Resource, CustomRoutes } from "react-admin";
import CustomLayout from "./components/CustomLayout";
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
import Icons from "assets/icons";

import { Route } from "react-router-dom";

import Pages from "./pages";

import CopyWorkouts from "pages/copyWorkouts";
import Dashboard from "Dashboard";

const lightTheme = defaultTheme;
const darkTheme = { ...defaultTheme, palette: { mode: "dark" } };

function isoDateStringToFormattedDate(isoDateString) {
  const date = new Date(isoDateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-indexed
  const year = date.getFullYear();

  return `${day.toString().padStart(2, "0")}/${month
    .toString()
    .padStart(2, "0")}/${year}`;
}

const App = () => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
    <Admin
      theme={lightTheme}
      darkTheme={darkTheme}
      dataProvider={dataProvider}
      loginPage={Pages.LoginPage}
      authProvider={authProvider}
      layout={CustomLayout}
      dashboard={Dashboard}
    >
      <Resource
        options={{ label: "Exercícios" }}
        name="exercises"
        list={Pages.Exercises.list}
        edit={Pages.Exercises.edit}
        create={Pages.Exercises.create}
        icon={Icons.FitnessCenter}
        recordRepresentation={(record) => `${record.name}`}
      />
      <Resource
        options={{ label: "Usuários" }}
        name="users"
        list={Pages.Users.list}
        edit={Pages.Users.edit}
        show={Pages.Users.show}
        create={Pages.Users.create}
        icon={Icons.People}
        recordRepresentation={(record) => `${record.name} ${record.surname}`}
      />
      <Resource
        options={{ label: "Treinos" }}
        name="workouts"
        list={Pages.Workouts.list}
        edit={Pages.Workouts.edit}
        show={Pages.Workouts.show}
        create={Pages.Workouts.create}
        icon={Icons.AssignmentInd}
        recordRepresentation={(record) =>
          `${record.name} - tipo: ${record.type} - ativo: ${
            record.active
          } - treinado: ${
            !!record.lastTrained
              ? `Sim (${new Date(record.lastTrained).toLocaleDateString()})`
              : "Nao"
          }`
        }
      />
      <Resource
        options={{ label: "Avaliações" }}
        name="evaluations"
        list={Pages.Evaluations.list}
        edit={Pages.Evaluations.edit}
        create={Pages.Evaluations.create}
        icon={Icons.Assignment}
        recordRepresentation={(record) =>
          `${record.objective} - criada em ${isoDateStringToFormattedDate(
            record.createdAt
          )}`
        }
      />
      <Resource
        options={{ label: "Administradores" }}
        name="managers"
        list={Pages.Managers.list}
        edit={Pages.Managers.edit}
        create={Pages.Managers.create}
        icon={Icons.Assignment}
      />
      <Resource
        options={{ label: "Notificações" }}
        name="notifications"
        list={Pages.Notifications.list}
        edit={Pages.Notifications.edit}
        create={Pages.Notifications.create}
        icon={Icons.Notifications}
      />

      <CustomRoutes>
        <Route path="/copyWorkouts" element={<CopyWorkouts />} />
      </CustomRoutes>
    </Admin>
  </MuiPickersUtilsProvider>
);

export default App;
