import List from "./list";
import Create from "./create";
import Edit from "./edit";

const EvaluationPages = {
  list: List,
  create: Create,
  edit: Edit,
};

export default EvaluationPages;
