import * as React from "react";
import {
  required,
  Create,
  TextInput,
  TabbedForm,
  FormTab,
  DateInput,
  RadioButtonGroupInput,
  BooleanInput,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";

import { genderChoices } from "../../assets/options";
import Div from "../../components/Div";

const getUserInfoFormTab = () => (
  <FormTab label="Usuário">
    <Div.ThreeColumns>
      <TextInput
        validate={required()}
        fullWidth
        helpertext="Name"
        source="name"
      />
      <TextInput
        validate={required()}
        fullWidth
        helpertext="Sobrenome"
        source="surname"
      />
      <DateInput
        fullWidth
        helpertext="Data de nascimento"
        source="birthDate"
        validate={required()}
      />
    </Div.ThreeColumns>
    <Div.ThreeColumns>
      <TextInput fullWidth helpertext="Celular" source="phoneNumber" />
      <TextInput
        fullWidth
        helpertext="Email"
        source="email"
        validate={required()}
      />
      <TextInput
        fullWidth
        helpertext="Senha"
        source="password"
        validate={required()}
      />
    </Div.ThreeColumns>
    <Div.TwoColumns>
      <div>
        <BooleanInput
          source="accountInformation.active"
          label="Usuario ativo"
        />
        <BooleanInput
          source="accountInformation.premium"
          label="Usuario premium"
        />
      </div>
      <div>
        <DateInput
          fullWidth
          helpertext="Vencimento do plano"
          source="accountInformation.planExpiration"
        />
        <DateInput
          fullWidth
          helpertext="Último pagamento"
          source="accountInformation.lastPayment"
        />
      </div>
    </Div.TwoColumns>
    <RadioButtonGroupInput source="gender" choices={genderChoices} />
  </FormTab>
);

const getUserCommentFormTab = () => (
  <FormTab label="Comments">
    <RichTextInput source="comments" />
  </FormTab>
);

const UserCreate = (props) => (
  <Create {...props} undoable={false}>
    <TabbedForm>
      {getUserInfoFormTab()}
      {getUserCommentFormTab()}
    </TabbedForm>
  </Create>
);

export default UserCreate;
