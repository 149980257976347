
import List from './list'
import Edit from './edit'
import Create from './create'

const ExercisePages = {
  edit: Edit,
  create: Create,
  list: List
}

export default ExercisePages;