import {
  List as AdminList,
  EditButton,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  useRecordContext,
  TopToolbar,
  useListContext,
  useUpdateMany,
  useUnselectAll,
  useNotify,
  useRefresh,
  Confirm,
} from "react-admin";
import Button from "@material-ui/core/Button";
import { useState } from "react";

const Name = ({ source }) => {
  const record = useRecordContext();
  if (!record || !record[source] || !record[source].name) return null;
  const fullName = `${record[source].name} ${record[source].surname}`;
  return <span>{fullName}</span>;
};

const Actions = () => {
  const unselectAll = useUnselectAll("workouts");
  const notify = useNotify();
  const refresh = useRefresh();
  const { selectedIds } = useListContext();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [updateMany, { isLoading }] = useUpdateMany(
    "workouts",
    { ids: selectedIds, data: { active: false } },
    {
      onSuccess: () => {
        notify("Workouts updated");
        unselectAll();
      },
      onError: (error) => {
        notify("Error: workouts not updated", { type: "error" });
        refresh();
      },
    }
  );

  const deactivateWorkouts = () => {
    updateMany();
    setIsDialogOpen(false);
  };
  return (
    <TopToolbar>
      <Button color="primary" onClick={() => setIsDialogOpen(true)}>
        Desativar treinos
      </Button>

      <Confirm
        isOpen={isDialogOpen}
        loading={isLoading}
        title="Desativar treinos?"
        content="Seguir com a desativação dos treinos?"
        onConfirm={() => deactivateWorkouts()}
        onClose={() => setIsDialogOpen(false)}
      />
    </TopToolbar>
  );
};

export const List = (props) => (
  <AdminList {...props}>
    <Datagrid rowClick="show" bulkActionButtons={<Actions />}>
      <BooleanField label="Ativo" source="active" />
      <BooleanField label="Treinado" source="beenTrained" />
      <TextField label="Nome" source="name" />
      <Name label="Usuario" source="user" />
      <DateField
        label="Criado em"
        source="createdAt"
        locales="pt-BR"
        options={{ timeZone: "UTC" }}
      />
      <DateField
        label="Atualizado em"
        source="updatedAt"
        locales="pt-BR"
        options={{ timeZone: "UTC" }}
      />
      <DateField
        label="Treinado em"
        source="lastTrained"
        locales="pt-BR"
        options={{ timeZone: "UTC" }}
      />
      <EditButton />
    </Datagrid>
  </AdminList>
);

export default List;
