import { useShowContext } from "react-admin";

const SendToGarminButton = ({ onClick }) => {
  const { record, isLoading } = useShowContext();
  if (isLoading) return null;
  return (
    <div>
      <button
        onClick={(e) => {
          e.preventDefault();
          onClick(record);
        }}
      >
        Enviar para Garmin
      </button>
    </div>
  );
};

export default SendToGarminButton;
