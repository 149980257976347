const workouts = (axios) => ({
  list: (params) => axios.get("/admin/workouts/list", { params }),
  listToCopy: (params) => axios.get("/admin/workouts/listToCopy", { params }),
  many: (ids) => axios.post("/admin/workouts/many", { ids }),

  create: (params) => axios.post("/admin/workouts", params),

  show: (workoutID) => axios.get(`/admin/workouts/${workoutID}`),
  delete: (workoutID) => axios.delete(`/admin/workouts/${workoutID}`),
  update: (params) => axios.patch(`/admin/workouts/${params.id}`, params),

  copy: (params) => axios.post(`/admin/workouts/copy`, params),
  copyMultiple: (params) => axios.post(`/admin/workouts/copyMultiple`, params),
  updateMany: (params) => axios.post(`/admin/workouts/updateMany`, params),
  deleteMany: (params) => axios.post(`/admin/workouts/deleteMany`, params),

  sendToGarmin: (params) => axios.post("/admin/workouts/sendToGarmin", params),
});

export default workouts;
