import * as React from "react";

const Dashboard = () => (
  <div
    style={{
      maxWidth: "100%",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
    }}
  >
    <iframe
      title="Idades"
      style={{
        background: "#FFFFFF",
        border: "none",
        borderRadius: "2px",
        boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
      }}
      width="640"
      height="480"
      src="https://charts.mongodb.com/charts-limemovement-vybxsdt/embed/charts?id=66907742-7c21-46b3-8f0c-1bcceee4f130&maxDataAge=3600&theme=light&autoRefresh=true"
    ></iframe>

    <iframe
      title="Idades premium"
      style={{
        background: "#FFFFFF",
        border: "none",
        borderRadius: "2px",
        boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
      }}
      width="640"
      height="480"
      src="https://charts.mongodb.com/charts-limemovement-vybxsdt/embed/charts?id=20cb1242-481c-40f2-a5e8-8a13ada11253&maxDataAge=3600&theme=light&autoRefresh=true"
    ></iframe>

    <iframe
      title="Paises"
      style={{
        background: "#FFFFFF",
        border: "none",
        borderRadius: "2px",
        boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
      }}
      width="640"
      height="480"
      src="https://charts.mongodb.com/charts-limemovement-vybxsdt/embed/charts?id=66a3da1b-3385-4d9a-89e7-87fc9c708310&maxDataAge=3600&theme=light&autoRefresh=true"
    ></iframe>
    <iframe
      title="Estados brasileiros"
      style={{
        background: "#FFFFFF",
        border: "none",
        borderRadius: "2px",
        boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
      }}
      width="640"
      height="480"
      src="https://charts.mongodb.com/charts-limemovement-vybxsdt/embed/charts?id=66a3dbe5-700c-4981-8cdb-52197ab80844&maxDataAge=3600&theme=light&autoRefresh=true"
    ></iframe>
    <iframe
      title="Estados EUA"
      style={{
        background: "#FFFFFF",
        border: "none",
        borderRadius: "2px",
        boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
      }}
      width="640"
      height="480"
      src="https://charts.mongodb.com/charts-limemovement-vybxsdt/embed/charts?id=66a3dc2b-46e4-449f-8f44-a5aa3e587649&maxDataAge=3600&theme=light&autoRefresh=true"
    ></iframe>
  </div>
);

export default Dashboard;
