import * as React from "react";
import {
  required,
  Create,
  TextInput,
  SimpleForm,
  RadioButtonGroupInput,
} from "react-admin";

import { notificationGroupOptions } from "../../assets/options";

const NotificationCreate = (props) => (
  <Create {...props} undoable={false}>
    <SimpleForm>
      <TextInput
        validate={required()}
        fullWidth
        helpertext="Título"
        source="title"
      />
      <TextInput fullWidth helpertext="Conteúdo" source="body" />
      <RadioButtonGroupInput
        source="group"
        choices={notificationGroupOptions}
        validate={required()}
      />
    </SimpleForm>
  </Create>
);

export default NotificationCreate;
