import api from "./api";

function mapObjectIDToId(obj) {
  return {
    ...obj,
    id: obj._id,
  };
}

const dataProvider = {
  getList: async (resource, params) => {
    // console.log("get list", resource, params);
    let response;
    let objectReturn;
    try {
      if (resource === "users") response = await api().users.list(params);
      else if (resource === "exercises")
        response = await api().exercises.list(params);
      else if (resource === "workouts")
        response = await api().workouts.list(params);
      else if (resource === "workoutsToCopy")
        response = await api().workouts.listToCopy(params);
      else if (resource === "managers") response = await api().managers.list();
      else if (resource === "evaluations")
        response = await api().evaluations.list(params);
      else if (resource === "notifications")
        response = await api().notifications.list(params);
      else throw new Error("Request not implemented for resource: " + resource);
    } catch (error) {
      console.error(error);
      return {
        data: [],
        total: 0,
      };
    }

    objectReturn = response.data;

    if (resource === "exercises") {
      objectReturn = {
        data: response.data.exercises.map((exercise) => ({
          ...exercise,
          hasVideo: exercise.video ? true : false,
          id: exercise._id,
        })),
        total: response.data.total || response.data.exercises.length,
      };
    } else if (resource === "workouts" || resource === "workoutsToCopy") {
      objectReturn = {
        data: response.data.workouts.map((workout) => ({
          ...workout,
          id: workout._id,
        })),
        total: response.data.total || response.data.workouts.length,
      };
    } else if (resource === "users") {
      objectReturn = {
        data: response.data.users.map((user) => ({
          ...user,
          id: user._id,
        })),
        total: response.data.total || response.data.users.length,
      };
    } else if (resource === "evaluations") {
      objectReturn = {
        data: response.data.evaluations.map((evaluation) => ({
          ...evaluation,
          id: evaluation._id,
        })),
        total: response.data.total || response.data.evaluations.length,
      };
    } else if (resource === "managers") {
      objectReturn = {
        data: response.data.managers.map((manager) => ({
          ...manager,
          id: manager._id,
        })),
        total: response.data.total || response.data.managers.length,
      };
    } else if (resource === "notifications") {
      objectReturn = {
        data: response.data.notifications.map((manager) => ({
          ...manager,
          id: manager._id,
        })),
        total: response.data.total || response.data.notifications.length,
      };
    }

    return objectReturn;
  },

  getOne: async (resource, params) => {
    // console.log("get one", resource, params);
    let response;
    try {
      if (resource === "users") response = await api().users.show(params.id);
      else if (resource === "exercises")
        response = await api().exercises.show(params.id);
      else if (resource === "workouts")
        response = await api().workouts.show(params.id);
      else if (resource === "managers")
        response = await api().managers.show(params.id);
      else if (resource === "evaluations")
        response = await api().evaluations.show(params.id);
      else if (resource === "notifications")
        response = await api().notifications.show(params.id);
      else
        throw new Error(
          "Request not implemented for this resource:" + resource
        );
    } catch (error) {
      // console.log(error);
      return {
        data: undefined,
      };
    }

    if (resource === "users") {
      for (let workout of response.data.workouts) {
        workout.beenTrained = workout.lastTrained ? true : false;
      }

      response.data = {
        ...response.data,
        isUserIntegratedWithGarmin:
          !!response.data.thirdPartyApps.garmin.userAccessToken &&
          !!response.data.thirdPartyApps.garmin.userAccessTokenSecret,
      };
    }

    return {
      data: mapObjectIDToId(response.data),
    };
  },

  getMany: async (resource, params) => {
    // console.log("get many", resource, params);
    let response;
    let objectReturn;

    try {
      if (resource === "users") response = await api().users.many(params.ids);
      else if (resource === "exercises")
        response = await api().exercises.many(params.ids);
      else if (resource === "workouts")
        response = await api().workouts.many(params.ids);
      else if (resource === "managers") response = await api().managers.list();
      else if (resource === "evaluations")
        response = await api().evaluations.many(params.ids);
      else if (resource === "notifications")
        response = await api().notifications.many(params.ids);
      else throw new Error("Request not implemented for resource: " + resource);
    } catch (error) {
      console.error(error);
      return {
        data: [],
        total: 0,
      };
    }

    objectReturn = response.data;

    if (resource === "exercises") {
      objectReturn = {
        data: response.data.exercises.map((exercise) => ({
          ...exercise,
          id: exercise._id,
        })),
        total: response.data.exercises.length,
      };
    } else if (resource === "workouts") {
      objectReturn = {
        data: response.data.workouts.map((workout) => ({
          ...workout,
          id: workout._id,
        })),
        total: response.data.workouts.length,
      };
    } else if (resource === "users") {
      objectReturn = {
        data: response.data.users.map((user) => ({
          ...user,
          id: user._id,
        })),
        total: response.data.users.length,
      };
    } else if (resource === "evaluations") {
      objectReturn = {
        data: response.data.evaluations.map((evaluation) => ({
          ...evaluation,
          id: evaluation._id,
        })),
        total: response.data.evaluations.length,
      };
    }

    // console.log("returning", objectReturn);
    return objectReturn;
  },

  update: async (resource, params) => {
    // console.log("update", resource, params);
    let response;
    if (resource === "exercises") {
      const exercise = params.data;
      response = await api().exercises.update(exercise);
    } else if (resource === "workouts") {
      const workout = params.data;
      response = await api().workouts.update(workout);
    } else if (resource === "users") {
      const user = params.data;
      response = await api().users.update(user);
    } else if (resource === "evaluations") {
      const evaluation = params.data;
      response = await api().evaluations.update(evaluation);
    } else if (resource === "notifications") {
      const notification = params.data;
      response = await api().notifications.update(notification);
    } else {
      throw new Error("Request not implemented for this resource:" + resource);
    }
    return {
      data: mapObjectIDToId(response.data),
    };
  },

  create: async (resource, params) => {
    // console.log("create", resource, params);
    let response;
    try {
      if (resource === "users") {
        response = await api().users.create(params.data);
      } else if (resource === "exercises") {
        response = await api().exercises.create(params.data);
      } else if (resource === "managers") {
        response = await api().managers.create(params.data);
      } else if (resource === "workouts") {
        if (typeof params.data.active === "undefined") {
          params.data.active = false;
        }
        response = await api().workouts.create(params.data);
      } else if (resource === "evaluations") {
        response = await api().evaluations.create(params.data);
      } else if (resource === "notifications") {
        response = await api().notifications.create(params.data);
      } else {
        throw new Error(
          "Request not implemented for this resource:" + resource
        );
      }
    } catch (error) {
      console.error(error);
    }

    return {
      data: mapObjectIDToId(response.data),
    };
  },

  delete: async (resource, params) => {
    // console.log("delete", resource, params);
    let response;
    try {
      if (resource === "users") {
        response = await api().users.delete(params.id);
      } else if (resource === "exercises") {
        response = await api().exercises.delete(params.id);
      } else if (resource === "workouts") {
        response = await api().workouts.delete(params.id);
      } else if (resource === "evaluations") {
        response = await api().evaluations.delete(params.id);
      } else if (resource === "notifications") {
        response = await api().notifications.delete(params.id);
      } else
        throw new Error(
          "Request not implemented for this resource:" + resource
        );
    } catch (error) {
      console.error(error);
    }

    return {
      data: mapObjectIDToId(response.data),
    };
  },

  updateMany: async (resource, params) => {
    let response;
    try {
      if (resource === "workouts") {
        response = await api().workouts.updateMany({
          ids: params.ids,
          data: params.data,
        });
      } else
        throw new Error(
          "Request not implemented for this resource:" + resource
        );
    } catch (error) {
      console.error(error);
    }

    return {
      data: response.data.workouts.map((workout) => ({
        ...workout,
        id: workout._id,
      })),
      total: response.data.total || response.data.workouts.length,
    };
  },

  deleteMany: async (resource, params) => {
    let response;
    try {
      if (resource === "workouts") {
        response = await api().workouts.deleteMany({
          ids: params.ids,
        });
      } else
        throw new Error(
          "Request not implemented for this resource:" + resource
        );
    } catch (error) {
      console.error(error);
    }

    return {
      data: response.data.workouts.map((workout) => ({
        ...workout,
        id: workout._id,
      })),
      total: response.data.total || response.data.workouts.length,
    };
  },
};

export default dataProvider;
