import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  BooleanField,
  SearchInput,
  BooleanInput,
} from "react-admin";

const userFilters = [
  <SearchInput source="name" alwaysOn />,
  <BooleanInput source="accountInformation.premium" />,
  <BooleanInput source="accountInformation.active" />,
];

const UserList = (props) => (
  <List filters={userFilters} {...props}>
    <Datagrid rowClick="show" bulkActionButtons={false} >
      <BooleanField source="accountInformation.active" />
      <BooleanField source="accountInformation.premium" />
      <TextField source="name" />
      <TextField source="surname" />
      <EmailField source="email" />
      <TextField source="phoneNumber" />
      <EditButton />
    </Datagrid>
  </List>
);

export default UserList;
