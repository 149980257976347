const users = (axios) => ({
  list: (params) => axios.get("/admin/users/list", { params }),
  many: (ids) => axios.post("/admin/users/many", { ids }),

  create: (data) => axios.post(`/admin/users`, data),

  update: (params) => axios.patch(`/admin/users/${params.id}`, params),
  show: (userID) => axios.get(`/admin/users/${userID}`),
  delete: (userID) => axios.delete(`/admin/users/${userID}`),
});

export default users;
