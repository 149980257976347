import * as React from "react";
import {
  required,
  Edit,
  TextInput,
  TabbedForm,
  FormTab,
  DateInput,
  RadioButtonGroupInput,
  BooleanInput,
  ArrayField,
  SingleFieldList,
  ReferenceField,
  TextField,
  SelectInput,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { genderChoices } from "../../assets/options";
import Div from "../../components/Div";
import { useEffect } from "react";
import { useState } from "react";
import api from "../../api";

const getUserInfoFormTab = () => (
  <FormTab label="Usuário">
    <Div.ThreeColumns>
      <TextInput
        validate={required()}
        fullWidth
        helpertext="Name"
        source="name"
      />
      <TextInput
        validate={required()}
        fullWidth
        helpertext="Sobrenome"
        source="surname"
      />
      <DateInput fullWidth helpertext="Data de nascimento" source="birthDate" />
    </Div.ThreeColumns>
    <Div.TwoColumns>
      <TextInput fullWidth helpertext="Celular" source="phoneNumber" />
      <TextInput
        validate={required()}
        fullWidth
        helpertext="Email"
        source="email"
      />
    </Div.TwoColumns>
    <Div.ThreeColumns>
      <div>
        <BooleanInput
          source="accountInformation.active"
          label="Usuario ativo"
        />
        <BooleanInput
          source="accountInformation.premium"
          label="Usuario premium"
        />
        <BooleanInput
          source="thirdPartyApps.garmin.allowUserIntegration"
          label="Permitir integração com garmin"
        />
      </div>
      <div>
        <DateInput
          fullWidth
          helpertext="Vencimento do plano"
          source="accountInformation.planExpiration"
        />
        <DateInput
          fullWidth
          helpertext="Último pagamento"
          source="accountInformation.lastPayment"
        />
      </div>
      <div>
        <p>Status do plano</p>
        <TextField source="userPlan.status" />
        <p>Expiração do plano (em dias)</p>
        <TextField source="userPlan.expiresIn" />
      </div>
    </Div.ThreeColumns>
    <RadioButtonGroupInput source="gender" choices={genderChoices} />
  </FormTab>
);

const getUserEvaluationFormTab = () => (
  <FormTab label="Avaliações">
    <ArrayField source="evaluations">
      <SingleFieldList
        style={{ display: "flex", flexDirection: "column", gap: 10 }}
      >
        <ReferenceField source="_id" reference="evaluations" />
      </SingleFieldList>
    </ArrayField>
  </FormTab>
);

const getUserWorkoutsFormTab = () => (
  <FormTab label="Treinos">
    <ArrayField source="workouts">
      <SingleFieldList
        style={{ display: "flex", flexDirection: "column", gap: 10 }}
      >
        <ReferenceField source="_id" reference="workouts" />
      </SingleFieldList>
    </ArrayField>
  </FormTab>
);

const getUserCommentFormTab = () => (
  <FormTab label="Comments">
    <RichTextInput source="comments" />
  </FormTab>
);

const UserEdit = (props) => {
  const [isCountryLoading, setIsCountryLoading] = useState(true);
  const [isStateLoading, setIsStateLoading] = useState(false);
  const [isCityLoading, setIsCityLoading] = useState(false);

  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();

  useEffect(() => {
    async function fetchCountryOptions() {
      const { data } = await api().location.getAllCountries();
      const countryOptions = Object.entries(data).map(([id, country]) => ({
        id,
        name: country["name-pt"],
      }));
      setIsCountryLoading(false);
      setCountryOptions(countryOptions);
    }

    fetchCountryOptions();
  }, []);

  useEffect(() => {
    async function fetchStateOptions() {
      const { data } = await api().location.getStatesFromCountry(
        selectedCountry
      );
      const stateOptions = data.map((state) => ({ id: state, name: state }));
      setIsStateLoading(false);
      setStateOptions(stateOptions);
    }
    if (selectedCountry) fetchStateOptions();
  }, [selectedCountry]);

  useEffect(() => {
    async function fetchCityOptions() {
      const { data } = await api().location.getCitiesFromState(
        selectedCountry,
        selectedState
      );
      const cityOptions = data.map((city) => ({ id: city, name: city }));
      setIsCityLoading(false);
      setCityOptions(cityOptions);
    }

    if (selectedState && selectedCountry) fetchCityOptions();
  }, [selectedCountry, selectedState]);

  return (
    <Edit {...props} undoable={false} redirect="show">
      <TabbedForm>
        {getUserInfoFormTab()}
        <FormTab label="Localizacao">
          <SelectInput
            source="address.country"
            isLoading={isCountryLoading}
            choices={countryOptions}
            onChange={(ev) => {
              setIsStateLoading(true);
              setSelectedCountry(ev.target.value);
            }}
          />

          <SelectInput
            source="address.state"
            isLoading={isStateLoading}
            choices={stateOptions}
            onChange={(ev) => {
              setIsCityLoading(true);
              setSelectedState(ev.target.value);
            }}
          />

          <SelectInput
            source="address.city"
            isLoading={isCityLoading}
            choices={cityOptions}
          />
        </FormTab>
        {getUserEvaluationFormTab()}
        {getUserWorkoutsFormTab()}
        {getUserCommentFormTab()}
      </TabbedForm>
    </Edit>
  );
};

export default UserEdit;
