import * as React from "react";
import {
  TextField,
  EmailField,
  BooleanField,
  ArrayField,
  SingleFieldList,
  ReferenceField,
  DateField,
  Show,
  TabbedShowLayout,
  Datagrid,
  RichTextField,
  useUnselectAll,
  useNotify,
  useRefresh,
  useListContext,
  useUpdateMany,
  TopToolbar,
  Confirm,
  SimpleShowLayout,
  useDeleteMany,
  useRecordContext,
  useGetOne,
} from "react-admin";
import { useState } from "react";
import { ToggleOff, ToggleOn, Delete } from "@material-ui/icons";
import { Button } from "@material-ui/core";

const Actions = () => {
  const unselectAll = useUnselectAll("workouts");
  const notify = useNotify();
  const refresh = useRefresh();
  const { selectedIds, data } = useListContext();
  const [isDeactivateWorkoutsDialogOpen, setDeactivateWorkoutsDialogOpen] =
    useState(false);

  const [isActivateWorkoutsDialogOpen, setActivateWorkoutsDialogOpen] =
    useState(false);

  const [isDeleteWorkoutsDialogOpen, setDeleteWorkoutsDialogOpen] =
    useState(false);

  const [updateMany, { isupdating }] = useUpdateMany(
    "workouts",
    { ids: selectedIds, data: { active: false } },
    {
      onSuccess: () => {
        notify("Workouts updated");
      },
      onError: (error) => {
        notify("Error: workouts not updated", { type: "error" });
      },
    }
  );

  const [deleteMany, { isDeleting }] = useDeleteMany(
    "workouts",
    { ids: selectedIds },
    {
      onSuccess: () => {
        notify("Workouts deleted");
      },
      onError: (error) => {
        notify("Error: workouts not deleted", { type: "error" });
      },
    }
  );

  const getSelectedIds = () => {
    const selected = selectedIds.map(
      (s) => data[parseInt(s.replace(/\D/g, ""))]._id
    );
    return selected;
  };

  const deactivateWorkouts = () => {
    updateMany("workouts", { ids: getSelectedIds(), data: { active: false } });
    setDeactivateWorkoutsDialogOpen(false);
    unselectAll();
    refresh();
  };

  const activateWorkouts = () => {
    updateMany("workouts", { ids: getSelectedIds(), data: { active: true } });
    setActivateWorkoutsDialogOpen(false);
    unselectAll();
    refresh();
  };

  const deleteWorkouts = () => {
    deleteMany("workouts", { ids: getSelectedIds() });
    setDeleteWorkoutsDialogOpen(false);
    unselectAll();
    refresh();
  };

  return (
    <TopToolbar>
      <Button
        color="secondary"
        onClick={() => setDeactivateWorkoutsDialogOpen(true)}
        endIcon={<ToggleOff />}
        variant="contained"
      >
        Desativar
      </Button>
      <Button
        color="secondary"
        onClick={() => setActivateWorkoutsDialogOpen(true)}
        endIcon={<ToggleOn />}
        variant="contained"
      >
        Ativar
      </Button>
      <Button
        color="secondary"
        onClick={() => setDeleteWorkoutsDialogOpen(true)}
        endIcon={<Delete />}
        variant="contained"
      >
        Deletar
      </Button>

      <Confirm
        isOpen={isDeactivateWorkoutsDialogOpen}
        loading={isupdating}
        title="Desativar treinos?"
        content="Seguir com a desativação dos treinos?"
        onConfirm={() => deactivateWorkouts()}
        onClose={() => setDeactivateWorkoutsDialogOpen(false)}
      />
      <Confirm
        isOpen={isActivateWorkoutsDialogOpen}
        loading={isupdating}
        title="Ativar treinos?"
        content="Seguir com a Ativação dos treinos?"
        onConfirm={() => activateWorkouts()}
        onClose={() => setActivateWorkoutsDialogOpen(false)}
      />
      <Confirm
        isOpen={isDeleteWorkoutsDialogOpen}
        loading={isDeleting}
        title="Apagar treinos?"
        content="Seguir com a deleção dos treinos?"
        onConfirm={() => deleteWorkouts()}
        onClose={() => setDeleteWorkoutsDialogOpen(false)}
      />
    </TopToolbar>
  );
};

const Scores = ({ scores }) => {
  const tableStyle = {
    borderCollapse: "collapse",
    fontWeight: 500,
    width: "100%",
  };
  const thStyle = {
    fontWeight: 600,
    padding: "6px 16px",
  };
  const tdStyle = {
    fontWeight: 500,
    padding: "6px 16px",
  };
  return (
    <table style={tableStyle}>
      <tr>
        <th style={thStyle}>Nota</th>
        <th style={thStyle}>Comentario</th>
        <th style={thStyle}>Criado em</th>
      </tr>
      {scores.map((score) => (
        <tr>
          <td style={tdStyle}>{score.score}</td>
          <td style={tdStyle}>{score.comment}</td>
          <td style={tdStyle}>
            {new Date(score.createdAt).toLocaleDateString("pt-BR", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </td>
        </tr>
      ))}
    </table>
  );
};

const WorkoutInfo = () => {
  const record = useRecordContext();
  const { data, isPending, error, refetch } = useGetOne("workouts", {
    id: record._id,
    meta: null,
  });

  if (isPending) return;
  const scores = data?.scores || [];
  return (
    <SimpleShowLayout>
      {scores.length ? <Scores scores={scores} /> : <div>Treino sem nota</div>}

      <ArrayField source="warmUp" label="Aquecimento">
        <Datagrid bulkActionButtons={false}>
          <ReferenceField source="exercise" reference="exercises" label="Nome">
            <TextField source="name" />
          </ReferenceField>
          <TextField label="Repetições" source="reps" />
          <TextField label="Series" source="series" />
        </Datagrid>
      </ArrayField>

      <ArrayField source="content" label="Conteudo">
        <Datagrid bulkActionButtons={false}>
          <ReferenceField source="exercise" reference="exercises" label="Nome">
            <TextField source="name" />
          </ReferenceField>
          <TextField label="Repetições" source="reps" />
          <TextField label="Series" source="series" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  );
};

const getWorkoutsTab = () => (
  <TabbedShowLayout.Tab label="Treinos">
    <ArrayField source="workouts">
      <Datagrid bulkActionButtons={<Actions />} expand={<WorkoutInfo />}>
        <ReferenceField source="_id" reference="workouts" label="Treino">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField label="Ativo" source="active" />
        <BooleanField label="Treinado" source="beenTrained" />
        <DateField
          label="Ultimo treino"
          source="lastTrained"
          locales="pt-BR"
          options={{ timeZone: "UTC" }}
        />
        <TextField source="type" />
      </Datagrid>
    </ArrayField>
  </TabbedShowLayout.Tab>
);

const getCommentsTab = () => (
  <TabbedShowLayout.Tab label="Comentarios">
    <RichTextField source="comments" />
  </TabbedShowLayout.Tab>
);

const getEvaluationsTab = () => (
  <TabbedShowLayout.Tab label="Avaliações">
    <ArrayField source="evaluations">
      <SingleFieldList
        style={{ display: "flex", flexDirection: "column", gap: 10 }}
      >
        <ReferenceField source="_id" reference="evaluations" />
      </SingleFieldList>
    </ArrayField>
  </TabbedShowLayout.Tab>
);

const getAddressTab = () => (
  <TabbedShowLayout.Tab label="Localizacao">
    <TextField source="address.country" />
    <TextField source="address.state" />
    <TextField source="address.city" />
  </TabbedShowLayout.Tab>
);

const getInfoTab = () => (
  <TabbedShowLayout.Tab label="Informações">
    <TextField source="name" />
    <TextField source="surname" />
    <EmailField source="email" />
    <TextField source="phoneNumber" />
    <DateField
      source="birthDate"
      locales="pt-BR"
      options={{ timeZone: "UTC" }}
    />
    <DateField
      source="lastLogin"
      locales="pt-BR"
      options={{ timeZone: "UTC" }}
    />
    <BooleanField source="requestedDeletion" />
    <DateField
      source="requesteDeletionAt"
      locales="pt-BR"
      options={{ timeZone: "UTC" }}
    />
    <TextField source="userPlan.status" />
    <TextField source="userPlan.expiresIn" />
    <BooleanField source="accountInformation.active" label="Usuário ativo" />
    <BooleanField source="accountInformation.premium" label="Usuário premium" />
    <BooleanField
      source="thirdPartyApps.garmin.allowUserIntegration"
      label="Permitir integração com Garmin"
    />
    <BooleanField
      source="isUserIntegratedWithGarmin"
      label="Usuário já integrado com Garmin"
    />
  </TabbedShowLayout.Tab>
);

const UserShow = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        {getInfoTab()}

        {getAddressTab()}

        {getEvaluationsTab()}
        {getWorkoutsTab()}
        {getCommentsTab()}
      </TabbedShowLayout>
    </Show>
  );
};

export default UserShow;
