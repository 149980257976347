const BANDED_EXERCISES = [
  { id: "AB_TWIST", name: "AB_TWIST" },
  { id: "BACK_EXTENSION", name: "BACK_EXTENSION" },
  { id: "BICYCLE_CRUNCH", name: "BICYCLE_CRUNCH" },
  { id: "CALF_RAISES", name: "CALF_RAISES" },
  { id: "CLAM_SHELLS", name: "CLAM_SHELLS" },
  { id: "CURL", name: "CURL" },
  { id: "DEADBUG", name: "DEADBUG" },
  { id: "DEADLIFT", name: "DEADLIFT" },
  { id: "DONKEY_KICK", name: "DONKEY_KICK" },
  { id: "EXTERNAL_ROTATION", name: "EXTERNAL_ROTATION" },
  {
    id: "EXTERNAL_ROTATION_AT_90_DEGREE_ABDUCTION",
    name: "EXTERNAL_ROTATION_AT_90_DEGREE_ABDUCTION",
  },
  { id: "FIRE_HYDRANT", name: "FIRE_HYDRANT" },
  { id: "FLY", name: "FLY" },
  { id: "FRONT_RAISE", name: "FRONT_RAISE" },
  { id: "GLUTE_BRIDGE", name: "GLUTE_BRIDGE" },
  { id: "HAMSTRING_CURLS", name: "HAMSTRING_CURLS" },
  { id: "HIGH_PLANK_LEG_LIFTS", name: "HIGH_PLANK_LEG_LIFTS" },
  { id: "HIP_EXTENSION", name: "HIP_EXTENSION" },
  { id: "INTERNAL_ROTATION", name: "INTERNAL_ROTATION" },
  { id: "JUMPING_JACK", name: "JUMPING_JACK" },
  { id: "KNEELING_CRUNCH", name: "KNEELING_CRUNCH" },
  { id: "LATERAL_BAND_WALKS", name: "LATERAL_BAND_WALKS" },
  { id: "LATERAL_RAISE", name: "LATERAL_RAISE" },
  { id: "LATPULL", name: "LATPULL" },
  { id: "LEG_ABDUCTION", name: "LEG_ABDUCTION" },
  { id: "LEG_ADDUCTION", name: "LEG_ADDUCTION" },
  { id: "LEG_EXTENSION", name: "LEG_EXTENSION" },
  { id: "LUNGE", name: "LUNGE" },
  { id: "PLANK", name: "PLANK" },
  { id: "PULL_APART", name: "PULL_APART" },
  { id: "PUSH_UPS", name: "PUSH_UPS" },
  { id: "REVERSE_CRUNCH", name: "REVERSE_CRUNCH" },
  { id: "ROW", name: "ROW" },
  { id: "SHOULDER_ABDUCTION", name: "SHOULDER_ABDUCTION" },
  { id: "SHOULDER_EXTENSION", name: "SHOULDER_EXTENSION" },
  { id: "SHOULDER_EXTERNAL_ROTATION", name: "SHOULDER_EXTERNAL_ROTATION" },
  {
    id: "SHOULDER_FLEXION_TO_90_DEGREES",
    name: "SHOULDER_FLEXION_TO_90_DEGREES",
  },
  { id: "SIDE_PLANK_LEG_LIFTS", name: "SIDE_PLANK_LEG_LIFTS" },
  { id: "SQUAT", name: "SQUAT" },
  { id: "SQUAT_TO_PRESS", name: "SQUAT_TO_PRESS" },
  { id: "TRICEP_EXTENSION", name: "TRICEP_EXTENSION" },
  { id: "TRICEP_KICKBACK", name: "TRICEP_KICKBACK" },
  { id: "UPRIGHT_ROW", name: "UPRIGHT_ROW" },
  {
    id: "WALL_CRAWL_WITH_EXTERNAL_ROTATION",
    name: "WALL_CRAWL_WITH_EXTERNAL_ROTATION",
  },
];

const BATTLE_ROPE = [
  { id: "ALTERNATING_FIGURE_EIGHT", name: "ALTERNATING_FIGURE_EIGHT" },
  { id: "ALTERNATING_JUMP_WAVE", name: "ALTERNATING_JUMP_WAVE" },
  {
    id: "ALTERNATING_KNEELING_TO_STANDING_WAVE",
    name: "ALTERNATING_KNEELING_TO_STANDING_WAVE",
  },
  { id: "ALTERNATING_LUNGE_WAVE", name: "ALTERNATING_LUNGE_WAVE" },
  { id: "ALTERNATING_SQUAT_WAVE", name: "ALTERNATING_SQUAT_WAVE" },
  { id: "ALTERNATING_WAVE", name: "ALTERNATING_WAVE" },
  {
    id: "ALTERNATING_WAVE_WITH_LATERAL_SHUFFLE",
    name: "ALTERNATING_WAVE_WITH_LATERAL_SHUFFLE",
  },
  { id: "CLAP_WAVE", name: "CLAP_WAVE" },
  { id: "DOUBLE_ARM_FIGURE_EIGHT", name: "DOUBLE_ARM_FIGURE_EIGHT" },
  {
    id: "DOUBLE_ARM_SIDE_TO_SIDE_SNAKE",
    name: "DOUBLE_ARM_SIDE_TO_SIDE_SNAKE",
  },
  { id: "DOUBLE_ARM_SIDE_WAVE", name: "DOUBLE_ARM_SIDE_WAVE" },
  { id: "DOUBLE_ARM_SLAM", name: "DOUBLE_ARM_SLAM" },
  { id: "DOUBLE_ARM_WAVE", name: "DOUBLE_ARM_WAVE" },
  { id: "GRAPPLER_TOSS", name: "GRAPPLER_TOSS" },
  { id: "HIP_TOSS", name: "HIP_TOSS" },
  { id: "INSIDE_CIRCLE", name: "INSIDE_CIRCLE" },
  { id: "IN_AND_OUT_WAVE", name: "IN_AND_OUT_WAVE" },
  { id: "JUMPING_JACKS", name: "JUMPING_JACKS" },
  { id: "OUTSIDE_CIRCLE", name: "OUTSIDE_CIRCLE" },
  { id: "RAINBOW", name: "RAINBOW" },
  { id: "SIDEWINDER", name: "SIDEWINDER" },
  { id: "SIDE_PLANK_WAVE", name: "SIDE_PLANK_WAVE" },
  { id: "SITTING_RUSSIAN_TWIST", name: "SITTING_RUSSIAN_TWIST" },
  { id: "SNAKE_WAVE", name: "SNAKE_WAVE" },
  { id: "SPLIT_JACK", name: "SPLIT_JACK" },
  { id: "STAGE_COACH", name: "STAGE_COACH" },
  { id: "ULTIMATE_WARRIOR", name: "ULTIMATE_WARRIOR" },
  { id: "UPPER_CUTS", name: "UPPER_CUTS" },
];

const BENCH_PRESS = [
  {
    id: "ALTERNATING_DUMBBELL_CHEST_PRESS",
    name: "ALTERNATING_DUMBBELL_CHEST_PRESS",
  },
  {
    id: "ALTERNATING_DUMBBELL_CHEST_PRESS_ON_SWISS_BALL",
    name: "ALTERNATING_DUMBBELL_CHEST_PRESS_ON_SWISS_BALL",
  },
  { id: "BARBELL_BENCH_PRESS", name: "BARBELL_BENCH_PRESS" },
  { id: "BARBELL_BOARD_BENCH_PRESS", name: "BARBELL_BOARD_BENCH_PRESS" },
  { id: "BARBELL_FLOOR_PRESS", name: "BARBELL_FLOOR_PRESS" },
  {
    id: "CLOSE_GRIP_BARBELL_BENCH_PRESS",
    name: "CLOSE_GRIP_BARBELL_BENCH_PRESS",
  },
  { id: "DECLINE_DUMBBELL_BENCH_PRESS", name: "DECLINE_DUMBBELL_BENCH_PRESS" },
  { id: "DUMBBELL_BENCH_PRESS", name: "DUMBBELL_BENCH_PRESS" },
  { id: "DUMBBELL_FLOOR_PRESS", name: "DUMBBELL_FLOOR_PRESS" },
  { id: "INCLINE_BARBELL_BENCH_PRESS", name: "INCLINE_BARBELL_BENCH_PRESS" },
  { id: "INCLINE_DUMBBELL_BENCH_PRESS", name: "INCLINE_DUMBBELL_BENCH_PRESS" },
  {
    id: "INCLINE_SMITH_MACHINE_BENCH_PRESS",
    name: "INCLINE_SMITH_MACHINE_BENCH_PRESS",
  },
  {
    id: "ISOMETRIC_BARBELL_BENCH_PRESS",
    name: "ISOMETRIC_BARBELL_BENCH_PRESS",
  },
  { id: "KETTLEBELL_CHEST_PRESS", name: "KETTLEBELL_CHEST_PRESS" },
  {
    id: "NEUTRAL_GRIP_DUMBBELL_BENCH_PRESS",
    name: "NEUTRAL_GRIP_DUMBBELL_BENCH_PRESS",
  },
  {
    id: "NEUTRAL_GRIP_DUMBBELL_INCLINE_BENCH_PRESS",
    name: "NEUTRAL_GRIP_DUMBBELL_INCLINE_BENCH_PRESS",
  },
  { id: "ONE_ARM_FLOOR_PRESS", name: "ONE_ARM_FLOOR_PRESS" },
  { id: "PARTIAL_LOCKOUT", name: "PARTIAL_LOCKOUT" },
  {
    id: "REVERSE_GRIP_BARBELL_BENCH_PRESS",
    name: "REVERSE_GRIP_BARBELL_BENCH_PRESS",
  },
  {
    id: "REVERSE_GRIP_INCLINE_BENCH_PRESS",
    name: "REVERSE_GRIP_INCLINE_BENCH_PRESS",
  },
  { id: "SINGLE_ARM_CABLE_CHEST_PRESS", name: "SINGLE_ARM_CABLE_CHEST_PRESS" },
  {
    id: "SINGLE_ARM_DUMBBELL_BENCH_PRESS",
    name: "SINGLE_ARM_DUMBBELL_BENCH_PRESS",
  },
  { id: "SMITH_MACHINE_BENCH_PRESS", name: "SMITH_MACHINE_BENCH_PRESS" },
  {
    id: "SWISS_BALL_DUMBBELL_CHEST_PRESS",
    name: "SWISS_BALL_DUMBBELL_CHEST_PRESS",
  },
  {
    id: "TRIPLE_STOP_BARBELL_BENCH_PRESS",
    name: "TRIPLE_STOP_BARBELL_BENCH_PRESS",
  },
  { id: "WEIGHTED_ONE_ARM_FLOOR_PRESS", name: "WEIGHTED_ONE_ARM_FLOOR_PRESS" },
  {
    id: "WIDE_GRIP_BARBELL_BENCH_PRESS",
    name: "WIDE_GRIP_BARBELL_BENCH_PRESS",
  },
];

const BIKE_OUTDOOR = [
  {
    id: "BIKE_OUTDOOR",
    name: "BIKE_OUTDOOR",
  },
];

const CALF_RAISE = [
  { id: "DONKEY_CALF_RAISE", name: "DONKEY_CALF_RAISE" },
  { id: "SEATED_CALF_RAISE", name: "SEATED_CALF_RAISE" },
  { id: "SEATED_DUMBBELL_TOE_RAISE", name: "SEATED_DUMBBELL_TOE_RAISE" },
  {
    id: "SINGLE_LEG_BENT_KNEE_CALF_RAISE",
    name: "SINGLE_LEG_BENT_KNEE_CALF_RAISE",
  },
  { id: "SINGLE_LEG_DECLINE_PUSH_UP", name: "SINGLE_LEG_DECLINE_PUSH_UP" },
  { id: "SINGLE_LEG_DONKEY_CALF_RAISE", name: "SINGLE_LEG_DONKEY_CALF_RAISE" },
  {
    id: "SINGLE_LEG_HIP_RAISE_WITH_KNEE_HOLD",
    name: "SINGLE_LEG_HIP_RAISE_WITH_KNEE_HOLD",
  },
  {
    id: "SINGLE_LEG_STANDING_CALF_RAISE",
    name: "SINGLE_LEG_STANDING_CALF_RAISE",
  },
  {
    id: "SINGLE_LEG_STANDING_DUMBBELL_CALF_RAISE",
    name: "SINGLE_LEG_STANDING_DUMBBELL_CALF_RAISE",
  },
  { id: "STANDING_BARBELL_CALF_RAISE", name: "STANDING_BARBELL_CALF_RAISE" },
  { id: "STANDING_CALF_RAISE", name: "STANDING_CALF_RAISE" },
  { id: "STANDING_DUMBBELL_CALF_RAISE", name: "STANDING_DUMBBELL_CALF_RAISE" },
  { id: "WEIGHTED_DONKEY_CALF_RAISE", name: "WEIGHTED_DONKEY_CALF_RAISE" },
  { id: "WEIGHTED_SEATED_CALF_RAISE", name: "WEIGHTED_SEATED_CALF_RAISE" },
  {
    id: "WEIGHTED_SINGLE_LEG_BENT_KNEE_CALF_RAISE",
    name: "WEIGHTED_SINGLE_LEG_BENT_KNEE_CALF_RAISE",
  },
  {
    id: "WEIGHTED_SINGLE_LEG_DONKEY_CALF_RAISE",
    name: "WEIGHTED_SINGLE_LEG_DONKEY_CALF_RAISE",
  },
  { id: "WEIGHTED_STANDING_CALF_RAISE", name: "WEIGHTED_STANDING_CALF_RAISE" },
  { id: "_3_WAY_CALF_RAISE", name: "_3_WAY_CALF_RAISE" },
  { id: "_3_WAY_SINGLE_LEG_CALF_RAISE", name: "_3_WAY_SINGLE_LEG_CALF_RAISE" },
  { id: "_3_WAY_WEIGHTED_CALF_RAISE", name: "_3_WAY_WEIGHTED_CALF_RAISE" },
  {
    id: "_3_WAY_WEIGHTED_SINGLE_LEG_CALF_RAISE",
    name: "_3_WAY_WEIGHTED_SINGLE_LEG_CALF_RAISE",
  },
];

const CARDIO = [
  { id: "BOB_AND_WEAVE_CIRCLE", name: "BOB_AND_WEAVE_CIRCLE" },
  { id: "CARDIO_CORE_CRAWL", name: "CARDIO_CORE_CRAWL" },
  { id: "DOUBLE_UNDER", name: "DOUBLE_UNDER" },
  { id: "JUMPING_JACKS", name: "JUMPING_JACKS" },
  { id: "JUMP_ROPE", name: "JUMP_ROPE" },
  { id: "JUMP_ROPE_CROSSOVER", name: "JUMP_ROPE_CROSSOVER" },
  { id: "JUMP_ROPE_JOG", name: "JUMP_ROPE_JOG" },
  { id: "SKI_MOGULS", name: "SKI_MOGULS" },
  { id: "SPLIT_JACKS", name: "SPLIT_JACKS" },
  { id: "SQUAT_JACKS", name: "SQUAT_JACKS" },
  { id: "TRIPLE_UNDER", name: "TRIPLE_UNDER" },
  {
    id: "WEIGHTED_BOB_AND_WEAVE_CIRCLE",
    name: "WEIGHTED_BOB_AND_WEAVE_CIRCLE",
  },
  { id: "WEIGHTED_CARDIO_CORE_CRAWL", name: "WEIGHTED_CARDIO_CORE_CRAWL" },
  { id: "WEIGHTED_DOUBLE_UNDER", name: "WEIGHTED_DOUBLE_UNDER" },
  { id: "WEIGHTED_JUMPING_JACKS", name: "WEIGHTED_JUMPING_JACKS" },
  { id: "WEIGHTED_JUMP_ROPE", name: "WEIGHTED_JUMP_ROPE" },
  { id: "WEIGHTED_JUMP_ROPE_CROSSOVER", name: "WEIGHTED_JUMP_ROPE_CROSSOVER" },
  { id: "WEIGHTED_JUMP_ROPE_JOG", name: "WEIGHTED_JUMP_ROPE_JOG" },
  { id: "WEIGHTED_SKI_MOGULS", name: "WEIGHTED_SKI_MOGULS" },
  { id: "WEIGHTED_SPLIT_JACKS", name: "WEIGHTED_SPLIT_JACKS" },
  { id: "WEIGHTED_SQUAT_JACKS", name: "WEIGHTED_SQUAT_JACKS" },
  { id: "WEIGHTED_TRIPLE_UNDER", name: "WEIGHTED_TRIPLE_UNDER" },
];

const CARRY = [
  { id: "BAR_HOLDS", name: "BAR_HOLDS" },
  { id: "DUMBBELL_WAITER_CARRY", name: "DUMBBELL_WAITER_CARRY" },
  { id: "FARMERS_CARRY", name: "FARMERS_CARRY" },
  { id: "FARMERS_CARRY_ON_TOES", name: "FARMERS_CARRY_ON_TOES" },
  { id: "FARMERS_CARRY_WALK_LUNGE", name: "FARMERS_CARRY_WALK_LUNGE" },
  { id: "FARMERS_WALK", name: "FARMERS_WALK" },
  { id: "FARMERS_WALK_ON_TOES", name: "FARMERS_WALK_ON_TOES" },
  { id: "HEX_DUMBBELL_HOLD", name: "HEX_DUMBBELL_HOLD" },
  { id: "OVERHEAD_CARRY", name: "OVERHEAD_CARRY" },
];

const CHOP = [
  { id: "CABLE_PULL_THROUGH", name: "CABLE_PULL_THROUGH" },
  { id: "CABLE_ROTATIONAL_LIFT", name: "CABLE_ROTATIONAL_LIFT" },
  { id: "CABLE_WOODCHOP", name: "CABLE_WOODCHOP" },
  { id: "CROSS_CHOP_TO_KNEE", name: "CROSS_CHOP_TO_KNEE" },
  { id: "DUMBBELL_CHOP", name: "DUMBBELL_CHOP" },
  { id: "HALF_KNEELING_ROTATION", name: "HALF_KNEELING_ROTATION" },
  {
    id: "HALF_KNEELING_ROTATIONAL_CHOP",
    name: "HALF_KNEELING_ROTATIONAL_CHOP",
  },
  {
    id: "HALF_KNEELING_ROTATIONAL_REVERSE_CHOP",
    name: "HALF_KNEELING_ROTATIONAL_REVERSE_CHOP",
  },
  { id: "HALF_KNEELING_STABILITY_CHOP", name: "HALF_KNEELING_STABILITY_CHOP" },
  {
    id: "HALF_KNEELING_STABILITY_REVERSE_CHOP",
    name: "HALF_KNEELING_STABILITY_REVERSE_CHOP",
  },
  { id: "KNEELING_ROTATIONAL_CHOP", name: "KNEELING_ROTATIONAL_CHOP" },
  {
    id: "KNEELING_ROTATIONAL_REVERSE_CHOP",
    name: "KNEELING_ROTATIONAL_REVERSE_CHOP",
  },
  { id: "KNEELING_STABILITY_CHOP", name: "KNEELING_STABILITY_CHOP" },
  { id: "KNEELING_WOODCHOPPER", name: "KNEELING_WOODCHOPPER" },
  { id: "MEDICINE_BALL_WOOD_CHOPS", name: "MEDICINE_BALL_WOOD_CHOPS" },
  { id: "POWER_SQUAT_CHOPS", name: "POWER_SQUAT_CHOPS" },
  { id: "STANDING_ROTATIONAL_CHOP", name: "STANDING_ROTATIONAL_CHOP" },
  {
    id: "STANDING_SPLIT_ROTATIONAL_CHOP",
    name: "STANDING_SPLIT_ROTATIONAL_CHOP",
  },
  {
    id: "STANDING_SPLIT_ROTATIONAL_REVERSE_CHOP",
    name: "STANDING_SPLIT_ROTATIONAL_REVERSE_CHOP",
  },
  {
    id: "STANDING_STABILITY_REVERSE_CHOP",
    name: "STANDING_STABILITY_REVERSE_CHOP",
  },
  { id: "WEIGHTED_CROSS_CHOP_TO_KNEE", name: "WEIGHTED_CROSS_CHOP_TO_KNEE" },
  {
    id: "WEIGHTED_HALF_KNEELING_ROTATION",
    name: "WEIGHTED_HALF_KNEELING_ROTATION",
  },
  { id: "WEIGHTED_POWER_SQUAT_CHOPS", name: "WEIGHTED_POWER_SQUAT_CHOPS" },
];

const CORE = [
  { id: "ABS_JABS", name: "ABS_JABS" },
  { id: "ALTERNATING_PLATE_REACH", name: "ALTERNATING_PLATE_REACH" },
  { id: "ALTERNATING_SLIDE_OUT", name: "ALTERNATING_SLIDE_OUT" },
  { id: "BARBELL_ROLLOUT", name: "BARBELL_ROLLOUT" },
  { id: "BODY_BAR_OBLIQUE_TWIST", name: "BODY_BAR_OBLIQUE_TWIST" },
  { id: "CABLE_CORE_PRESS", name: "CABLE_CORE_PRESS" },
  { id: "CABLE_SIDE_BEND", name: "CABLE_SIDE_BEND" },
  { id: "CRESCENT_CIRCLE", name: "CRESCENT_CIRCLE" },
  { id: "CYCLING_RUSSIAN_TWIST", name: "CYCLING_RUSSIAN_TWIST" },
  { id: "ELEVATED_FEET_RUSSIAN_TWIST", name: "ELEVATED_FEET_RUSSIAN_TWIST" },
  { id: "GHD_BACK_EXTENSIONS", name: "GHD_BACK_EXTENSIONS" },
  { id: "HALF_TURKISH_GET_UP", name: "HALF_TURKISH_GET_UP" },
  { id: "HANGING_L_SIT", name: "HANGING_L_SIT" },
  { id: "INCHWORM", name: "INCHWORM" },
  { id: "KETTLEBELL_WINDMILL", name: "KETTLEBELL_WINDMILL" },
  { id: "KNEELING_AB_WHEEL", name: "KNEELING_AB_WHEEL" },
  { id: "L_SIT", name: "L_SIT" },
  { id: "MODIFIED_FRONT_LEVER", name: "MODIFIED_FRONT_LEVER" },
  { id: "OPEN_KNEE_TUCKS", name: "OPEN_KNEE_TUCKS" },
  { id: "OVERHEAD_WALK", name: "OVERHEAD_WALK" },
  { id: "RING_L_SIT", name: "RING_L_SIT" },
  { id: "RUSSIAN_TWIST", name: "RUSSIAN_TWIST" },
  { id: "SIDE_ABS_LEG_LIFT", name: "SIDE_ABS_LEG_LIFT" },
  { id: "SIDE_BEND", name: "SIDE_BEND" },
  { id: "SWISS_BALL_JACKKNIFE", name: "SWISS_BALL_JACKKNIFE" },
  { id: "SWISS_BALL_PIKE", name: "SWISS_BALL_PIKE" },
  { id: "SWISS_BALL_ROLLOUT", name: "SWISS_BALL_ROLLOUT" },
  { id: "TOES_TO_ELBOWS", name: "TOES_TO_ELBOWS" },
  { id: "TRIANGLE_HIP_PRESS", name: "TRIANGLE_HIP_PRESS" },
  { id: "TRX_SUSPENDED_JACKKNIFE", name: "TRX_SUSPENDED_JACKKNIFE" },
  { id: "TURKISH_GET_UP", name: "TURKISH_GET_UP" },
  { id: "U_BOAT", name: "U_BOAT" },
  { id: "WEIGHTED_ABS_JABS", name: "WEIGHTED_ABS_JABS" },
  {
    id: "WEIGHTED_ALTERNATING_SLIDE_OUT",
    name: "WEIGHTED_ALTERNATING_SLIDE_OUT",
  },
  { id: "WEIGHTED_BARBELL_ROLLOUT", name: "WEIGHTED_BARBELL_ROLLOUT" },
  { id: "WEIGHTED_CRESCENT_CIRCLE", name: "WEIGHTED_CRESCENT_CIRCLE" },
  {
    id: "WEIGHTED_CYCLING_RUSSIAN_TWIST",
    name: "WEIGHTED_CYCLING_RUSSIAN_TWIST",
  },
  {
    id: "WEIGHTED_ELEVATED_FEET_RUSSIAN_TWIST",
    name: "WEIGHTED_ELEVATED_FEET_RUSSIAN_TWIST",
  },
  { id: "WEIGHTED_GHD_BACK_EXTENSIONS", name: "WEIGHTED_GHD_BACK_EXTENSIONS" },
  { id: "WEIGHTED_HANGING_L_SIT", name: "WEIGHTED_HANGING_L_SIT" },
  { id: "WEIGHTED_KNEELING_AB_WHEEL", name: "WEIGHTED_KNEELING_AB_WHEEL" },
  { id: "WEIGHTED_L_SIT", name: "WEIGHTED_L_SIT" },
  {
    id: "WEIGHTED_MODIFIED_FRONT_LEVER",
    name: "WEIGHTED_MODIFIED_FRONT_LEVER",
  },
  { id: "WEIGHTED_OPEN_KNEE_TUCKS", name: "WEIGHTED_OPEN_KNEE_TUCKS" },
  { id: "WEIGHTED_RING_L_SIT", name: "WEIGHTED_RING_L_SIT" },
  { id: "WEIGHTED_SIDE_ABS_LEG_LIFT", name: "WEIGHTED_SIDE_ABS_LEG_LIFT" },
  { id: "WEIGHTED_SIDE_BEND", name: "WEIGHTED_SIDE_BEND" },
  {
    id: "WEIGHTED_SWISS_BALL_JACKKNIFE",
    name: "WEIGHTED_SWISS_BALL_JACKKNIFE",
  },
  { id: "WEIGHTED_SWISS_BALL_PIKE", name: "WEIGHTED_SWISS_BALL_PIKE" },
  { id: "WEIGHTED_SWISS_BALL_ROLLOUT", name: "WEIGHTED_SWISS_BALL_ROLLOUT" },
  { id: "WEIGHTED_TRIANGLE_HIP_PRESS", name: "WEIGHTED_TRIANGLE_HIP_PRESS" },
  {
    id: "WEIGHTED_TRX_SUSPENDED_JACKKNIFE",
    name: "WEIGHTED_TRX_SUSPENDED_JACKKNIFE",
  },
  { id: "WEIGHTED_U_BOAT", name: "WEIGHTED_U_BOAT" },
  { id: "WEIGHTED_WINDMILL_SWITCHES", name: "WEIGHTED_WINDMILL_SWITCHES" },
  { id: "WINDMILL_SWITCHES", name: "WINDMILL_SWITCHES" },
];

const CRUNCH = [
  { id: "CRUNCH", name: "CRUNCH" },
  { id: "BICYCLE_CRUNCH", name: "BICYCLE_CRUNCH" },
  { id: "CABLE_CRUNCH", name: "CABLE_CRUNCH" },
  { id: "CIRCULAR_ARM_CRUNCH", name: "CIRCULAR_ARM_CRUNCH" },
  { id: "CROSSED_ARMS_CRUNCH", name: "CROSSED_ARMS_CRUNCH" },
  { id: "CROSS_LEG_REVERSE_CRUNCH", name: "CROSS_LEG_REVERSE_CRUNCH" },
  { id: "CRUNCH_CHOP", name: "CRUNCH_CHOP" },
  { id: "DOUBLE_CRUNCH", name: "DOUBLE_CRUNCH" },
  { id: "ELBOW_TO_KNEE_CRUNCH", name: "ELBOW_TO_KNEE_CRUNCH" },
  { id: "FLUTTER_KICKS", name: "FLUTTER_KICKS" },
  {
    id: "FOAM_ROLLER_REVERSE_CRUNCH_ON_BENCH",
    name: "FOAM_ROLLER_REVERSE_CRUNCH_ON_BENCH",
  },
  {
    id: "FOAM_ROLLER_REVERSE_CRUNCH_WITH_DUMBBELL",
    name: "FOAM_ROLLER_REVERSE_CRUNCH_WITH_DUMBBELL",
  },
  {
    id: "FOAM_ROLLER_REVERSE_CRUNCH_WITH_MEDICINE_BALL",
    name: "FOAM_ROLLER_REVERSE_CRUNCH_WITH_MEDICINE_BALL",
  },
  { id: "FROG_PRESS", name: "FROG_PRESS" },
  {
    id: "HANGING_KNEE_RAISE_OBLIQUE_CRUNCH",
    name: "HANGING_KNEE_RAISE_OBLIQUE_CRUNCH",
  },
  { id: "HIP_CROSSOVER", name: "HIP_CROSSOVER" },
  { id: "HOLLOW_ROCK", name: "HOLLOW_ROCK" },
  { id: "INCLINE_REVERSE_CRUNCH", name: "INCLINE_REVERSE_CRUNCH" },
  { id: "KNEELING_CABLE_CRUNCH", name: "KNEELING_CABLE_CRUNCH" },
  { id: "KNEELING_CROSS_CRUNCH", name: "KNEELING_CROSS_CRUNCH" },
  {
    id: "KNEELING_OBLIQUE_CABLE_CRUNCH",
    name: "KNEELING_OBLIQUE_CABLE_CRUNCH",
  },
  { id: "KNEES_TO_ELBOW", name: "KNEES_TO_ELBOW" },
  { id: "LEG_CLIMB_CRUNCH", name: "LEG_CLIMB_CRUNCH" },
  { id: "LEG_EXTENSIONS", name: "LEG_EXTENSIONS" },
  { id: "LEG_LEVERS", name: "LEG_LEVERS" },
  { id: "MCGILL_CURL_UP", name: "MCGILL_CURL_UP" },
  {
    id: "MODIFIED_PILATES_ROLL_UP_WITH_BALL",
    name: "MODIFIED_PILATES_ROLL_UP_WITH_BALL",
  },
  { id: "PILATES_CRUNCH", name: "PILATES_CRUNCH" },
  { id: "PILATES_ROLL_UP_WITH_BALL", name: "PILATES_ROLL_UP_WITH_BALL" },
  { id: "RAISED_LEGS_CRUNCH", name: "RAISED_LEGS_CRUNCH" },
  { id: "REVERSE_CRUNCH", name: "REVERSE_CRUNCH" },
  { id: "REVERSE_CRUNCH_ON_A_BENCH", name: "REVERSE_CRUNCH_ON_A_BENCH" },
  { id: "REVERSE_CURL_AND_LIFT", name: "REVERSE_CURL_AND_LIFT" },
  { id: "ROTATIONAL_LIFT", name: "ROTATIONAL_LIFT" },
  {
    id: "SEATED_ALTERNATING_REVERSE_CRUNCH",
    name: "SEATED_ALTERNATING_REVERSE_CRUNCH",
  },
  { id: "SEATED_LEG_U", name: "SEATED_LEG_U" },
  {
    id: "SIDE_TO_SIDE_CRUNCH_AND_WEAVE",
    name: "SIDE_TO_SIDE_CRUNCH_AND_WEAVE",
  },
  { id: "SINGLE_LEG_REVERSE_CRUNCH", name: "SINGLE_LEG_REVERSE_CRUNCH" },
  { id: "SKATER_CRUNCH_CROSS", name: "SKATER_CRUNCH_CROSS" },
  { id: "STANDING_CABLE_CRUNCH", name: "STANDING_CABLE_CRUNCH" },
  { id: "STANDING_SIDE_CRUNCH", name: "STANDING_SIDE_CRUNCH" },
  { id: "STEP_CLIMB", name: "STEP_CLIMB" },
  {
    id: "STRAIGHT_LEG_CRUNCH_WITH_BALL",
    name: "STRAIGHT_LEG_CRUNCH_WITH_BALL",
  },
  { id: "SWISS_BALL_CRUNCH", name: "SWISS_BALL_CRUNCH" },
  { id: "SWISS_BALL_REVERSE_CRUNCH", name: "SWISS_BALL_REVERSE_CRUNCH" },
  { id: "SWISS_BALL_RUSSIAN_TWIST", name: "SWISS_BALL_RUSSIAN_TWIST" },
  { id: "SWISS_BALL_SIDE_CRUNCH", name: "SWISS_BALL_SIDE_CRUNCH" },
  {
    id: "THORACIC_CRUNCHES_ON_FOAM_ROLLER",
    name: "THORACIC_CRUNCHES_ON_FOAM_ROLLER",
  },
  { id: "TOES_TO_BAR", name: "TOES_TO_BAR" },
  { id: "TRICEPS_CRUNCH", name: "TRICEPS_CRUNCH" },
  { id: "WEIGHTED_BICYCLE_CRUNCH", name: "WEIGHTED_BICYCLE_CRUNCH" },
  { id: "WEIGHTED_CROSSED_ARMS_CRUNCH", name: "WEIGHTED_CROSSED_ARMS_CRUNCH" },
  {
    id: "WEIGHTED_CROSS_LEG_REVERSE_CRUNCH",
    name: "WEIGHTED_CROSS_LEG_REVERSE_CRUNCH",
  },
  { id: "WEIGHTED_CRUNCH", name: "WEIGHTED_CRUNCH" },
  { id: "WEIGHTED_CRUNCH_CHOP", name: "WEIGHTED_CRUNCH_CHOP" },
  { id: "WEIGHTED_DOUBLE_CRUNCH", name: "WEIGHTED_DOUBLE_CRUNCH" },
  {
    id: "WEIGHTED_ELBOW_TO_KNEE_CRUNCH",
    name: "WEIGHTED_ELBOW_TO_KNEE_CRUNCH",
  },
  { id: "WEIGHTED_FLUTTER_KICKS", name: "WEIGHTED_FLUTTER_KICKS" },
  {
    id: "WEIGHTED_FOAM_ROLLER_REVERSE_CRUNCH_ON_BENCH",
    name: "WEIGHTED_FOAM_ROLLER_REVERSE_CRUNCH_ON_BENCH",
  },
  {
    id: "WEIGHTED_HANGING_KNEE_RAISE_OBLIQUE_CRUNCH",
    name: "WEIGHTED_HANGING_KNEE_RAISE_OBLIQUE_CRUNCH",
  },
  { id: "WEIGHTED_HIP_CROSSOVER", name: "WEIGHTED_HIP_CROSSOVER" },
  { id: "WEIGHTED_HOLLOW_ROCK", name: "WEIGHTED_HOLLOW_ROCK" },
  {
    id: "WEIGHTED_INCLINE_REVERSE_CRUNCH",
    name: "WEIGHTED_INCLINE_REVERSE_CRUNCH",
  },
  {
    id: "WEIGHTED_KNEELING_CROSS_CRUNCH",
    name: "WEIGHTED_KNEELING_CROSS_CRUNCH",
  },
  { id: "WEIGHTED_LEG_EXTENSIONS", name: "WEIGHTED_LEG_EXTENSIONS" },
  { id: "WEIGHTED_MCGILL_CURL_UP", name: "WEIGHTED_MCGILL_CURL_UP" },
  {
    id: "WEIGHTED_MODIFIED_PILATES_ROLL_UP_WITH_BALL",
    name: "WEIGHTED_MODIFIED_PILATES_ROLL_UP_WITH_BALL",
  },
  { id: "WEIGHTED_PILATES_CRUNCH", name: "WEIGHTED_PILATES_CRUNCH" },
  {
    id: "WEIGHTED_PILATES_ROLL_UP_WITH_BALL",
    name: "WEIGHTED_PILATES_ROLL_UP_WITH_BALL",
  },
  { id: "WEIGHTED_RAISED_LEGS_CRUNCH", name: "WEIGHTED_RAISED_LEGS_CRUNCH" },
  { id: "WEIGHTED_REVERSE_CRUNCH", name: "WEIGHTED_REVERSE_CRUNCH" },
  {
    id: "WEIGHTED_REVERSE_CRUNCH_ON_A_BENCH",
    name: "WEIGHTED_REVERSE_CRUNCH_ON_A_BENCH",
  },
  {
    id: "WEIGHTED_REVERSE_CURL_AND_LIFT",
    name: "WEIGHTED_REVERSE_CURL_AND_LIFT",
  },
  { id: "WEIGHTED_ROTATIONAL_LIFT", name: "WEIGHTED_ROTATIONAL_LIFT" },
  {
    id: "WEIGHTED_SEATED_ALTERNATING_REVERSE_CRUNCH",
    name: "WEIGHTED_SEATED_ALTERNATING_REVERSE_CRUNCH",
  },
  { id: "WEIGHTED_SEATED_LEG_U", name: "WEIGHTED_SEATED_LEG_U" },
  {
    id: "WEIGHTED_SIDE_TO_SIDE_CRUNCH_AND_WEAVE",
    name: "WEIGHTED_SIDE_TO_SIDE_CRUNCH_AND_WEAVE",
  },
  {
    id: "WEIGHTED_SINGLE_LEG_REVERSE_CRUNCH",
    name: "WEIGHTED_SINGLE_LEG_REVERSE_CRUNCH",
  },
  { id: "WEIGHTED_SKATER_CRUNCH_CROSS", name: "WEIGHTED_SKATER_CRUNCH_CROSS" },
  { id: "WEIGHTED_STEP_CLIMB", name: "WEIGHTED_STEP_CLIMB" },
  { id: "WEIGHTED_SWISS_BALL_CRUNCH", name: "WEIGHTED_SWISS_BALL_CRUNCH" },
  {
    id: "WEIGHTED_SWISS_BALL_REVERSE_CRUNCH",
    name: "WEIGHTED_SWISS_BALL_REVERSE_CRUNCH",
  },
  {
    id: "WEIGHTED_SWISS_BALL_RUSSIAN_TWIST",
    name: "WEIGHTED_SWISS_BALL_RUSSIAN_TWIST",
  },
  {
    id: "WEIGHTED_SWISS_BALL_SIDE_CRUNCH",
    name: "WEIGHTED_SWISS_BALL_SIDE_CRUNCH",
  },
  {
    id: "WEIGHTED_THORACIC_CRUNCHES_ON_FOAM_ROLLER",
    name: "WEIGHTED_THORACIC_CRUNCHES_ON_FOAM_ROLLER",
  },
  { id: "WEIGHTED_TOES_TO_BAR", name: "WEIGHTED_TOES_TO_BAR" },
];

const CURL = [
  {
    id: "ALTERNATING_DUMBBELL_BICEPS_CURL",
    name: "ALTERNATING_DUMBBELL_BICEPS_CURL",
  },
  {
    id: "ALTERNATING_DUMBBELL_BICEPS_CURL_ON_SWISS_BALL",
    name: "ALTERNATING_DUMBBELL_BICEPS_CURL_ON_SWISS_BALL",
  },
  {
    id: "ALTERNATING_INCLINE_DUMBBELL_BICEPS_CURL",
    name: "ALTERNATING_INCLINE_DUMBBELL_BICEPS_CURL",
  },
  { id: "BARBELL_BICEPS_CURL", name: "BARBELL_BICEPS_CURL" },
  { id: "BARBELL_REVERSE_WRIST_CURL", name: "BARBELL_REVERSE_WRIST_CURL" },
  { id: "BARBELL_WRIST_CURL", name: "BARBELL_WRIST_CURL" },
  {
    id: "BEHIND_THE_BACK_BARBELL_REVERSE_WRIST_CURL",
    name: "BEHIND_THE_BACK_BARBELL_REVERSE_WRIST_CURL",
  },
  {
    id: "BEHIND_THE_BACK_ONE_ARM_CABLE_CURL",
    name: "BEHIND_THE_BACK_ONE_ARM_CABLE_CURL",
  },
  { id: "CABLE_BICEPS_CURL", name: "CABLE_BICEPS_CURL" },
  { id: "CABLE_HAMMER_CURL", name: "CABLE_HAMMER_CURL" },
  { id: "CHEATING_BARBELL_BICEPS_CURL", name: "CHEATING_BARBELL_BICEPS_CURL" },
  {
    id: "CLOSE_GRIP_EZ_BAR_BICEPS_CURL",
    name: "CLOSE_GRIP_EZ_BAR_BICEPS_CURL",
  },
  {
    id: "CROSS_BODY_DUMBBELL_HAMMER_CURL",
    name: "CROSS_BODY_DUMBBELL_HAMMER_CURL",
  },
  { id: "DEAD_HANG_BICEPS_CURL", name: "DEAD_HANG_BICEPS_CURL" },
  { id: "DECLINE_HAMMER_CURL", name: "DECLINE_HAMMER_CURL" },
  { id: "DUMBBELL_BICEPS_CURL", name: "DUMBBELL_BICEPS_CURL" },
  {
    id: "DUMBBELL_BICEPS_CURL_WITH_STATIC_HOLD",
    name: "DUMBBELL_BICEPS_CURL_WITH_STATIC_HOLD",
  },
  { id: "DUMBBELL_HAMMER_CURL", name: "DUMBBELL_HAMMER_CURL" },
  { id: "DUMBBELL_REVERSE_WRIST_CURL", name: "DUMBBELL_REVERSE_WRIST_CURL" },
  { id: "DUMBBELL_WRIST_CURL", name: "DUMBBELL_WRIST_CURL" },
  { id: "EZ_BAR_PREACHER_CURL", name: "EZ_BAR_PREACHER_CURL" },
  { id: "FORWARD_BEND_BICEPS_CURL", name: "FORWARD_BEND_BICEPS_CURL" },
  { id: "HAMMER_CURL_TO_PRESS", name: "HAMMER_CURL_TO_PRESS" },
  { id: "INCLINE_DUMBBELL_BICEPS_CURL", name: "INCLINE_DUMBBELL_BICEPS_CURL" },
  {
    id: "INCLINE_OFFSET_THUMB_DUMBBELL_CURL",
    name: "INCLINE_OFFSET_THUMB_DUMBBELL_CURL",
  },
  { id: "KETTLEBELL_BICEPS_CURL", name: "KETTLEBELL_BICEPS_CURL" },
  {
    id: "LYING_CONCENTRATION_CABLE_CURL",
    name: "LYING_CONCENTRATION_CABLE_CURL",
  },
  { id: "ONE_ARM_CONCENTRATION_CURL", name: "ONE_ARM_CONCENTRATION_CURL" },
  { id: "ONE_ARM_PREACHER_CURL", name: "ONE_ARM_PREACHER_CURL" },
  { id: "PLATE_PINCH_CURL", name: "PLATE_PINCH_CURL" },
  { id: "PREACHER_CURL_WITH_CABLE", name: "PREACHER_CURL_WITH_CABLE" },
  { id: "REVERSE_EZ_BAR_CURL", name: "REVERSE_EZ_BAR_CURL" },
  {
    id: "REVERSE_GRIP_BARBELL_BICEPS_CURL",
    name: "REVERSE_GRIP_BARBELL_BICEPS_CURL",
  },
  { id: "REVERSE_GRIP_WRIST_CURL", name: "REVERSE_GRIP_WRIST_CURL" },
  {
    id: "SEATED_ALTERNATING_DUMBBELL_BICEPS_CURL",
    name: "SEATED_ALTERNATING_DUMBBELL_BICEPS_CURL",
  },
  { id: "SEATED_DUMBBELL_BICEPS_CURL", name: "SEATED_DUMBBELL_BICEPS_CURL" },
  { id: "SEATED_REVERSE_DUMBBELL_CURL", name: "SEATED_REVERSE_DUMBBELL_CURL" },
  {
    id: "SPLIT_STANCE_OFFSET_PINKY_DUMBBELL_CURL",
    name: "SPLIT_STANCE_OFFSET_PINKY_DUMBBELL_CURL",
  },
  {
    id: "STANDING_ALTERNATING_DUMBBELL_CURLS",
    name: "STANDING_ALTERNATING_DUMBBELL_CURLS",
  },
  {
    id: "STANDING_DUMBBELL_BICEPS_CURL",
    name: "STANDING_DUMBBELL_BICEPS_CURL",
  },
  { id: "STANDING_EZ_BAR_BICEPS_CURL", name: "STANDING_EZ_BAR_BICEPS_CURL" },
  { id: "STANDING_ZOTTMAN_BICEPS_CURL", name: "STANDING_ZOTTMAN_BICEPS_CURL" },
  { id: "STATIC_CURL", name: "STATIC_CURL" },
  {
    id: "SWISS_BALL_DUMBBELL_OVERHEAD_TRICEPS_EXTENSION",
    name: "SWISS_BALL_DUMBBELL_OVERHEAD_TRICEPS_EXTENSION",
  },
  {
    id: "SWISS_BALL_EZ_BAR_PREACHER_CURL",
    name: "SWISS_BALL_EZ_BAR_PREACHER_CURL",
  },
  {
    id: "TWISTING_STANDING_DUMBBELL_BICEPS_CURL",
    name: "TWISTING_STANDING_DUMBBELL_BICEPS_CURL",
  },
  { id: "WIDE_GRIP_EZ_BAR_BICEPS_CURL", name: "WIDE_GRIP_EZ_BAR_BICEPS_CURL" },
];

const DEADLIFT = [
  { id: "BARBELL_DEADLIFT", name: "BARBELL_DEADLIFT" },
  {
    id: "BARBELL_STRAIGHT_LEG_DEADLIFT",
    name: "BARBELL_STRAIGHT_LEG_DEADLIFT",
  },
  { id: "DUMBBELL_DEADLIFT", name: "DUMBBELL_DEADLIFT" },
  {
    id: "DUMBBELL_SINGLE_LEG_DEADLIFT_TO_ROW",
    name: "DUMBBELL_SINGLE_LEG_DEADLIFT_TO_ROW",
  },
  {
    id: "DUMBBELL_STRAIGHT_LEG_DEADLIFT",
    name: "DUMBBELL_STRAIGHT_LEG_DEADLIFT",
  },
  { id: "KETTLEBELL_DEADLIFT", name: "KETTLEBELL_DEADLIFT" },
  { id: "KETTLEBELL_FLOOR_TO_SHELF", name: "KETTLEBELL_FLOOR_TO_SHELF" },
  { id: "KETTLEBELL_SUMO_DEADLIFT", name: "KETTLEBELL_SUMO_DEADLIFT" },
  { id: "ONE_ARM_ONE_LEG_DEADLIFT", name: "ONE_ARM_ONE_LEG_DEADLIFT" },
  { id: "RACK_PULL", name: "RACK_PULL" },
  { id: "ROMANIAN_DEADLIFT", name: "ROMANIAN_DEADLIFT" },
  {
    id: "ROTATIONAL_DUMBBELL_STRAIGHT_LEG_DEADLIFT",
    name: "ROTATIONAL_DUMBBELL_STRAIGHT_LEG_DEADLIFT",
  },
  { id: "SINGLE_ARM_DEADLIFT", name: "SINGLE_ARM_DEADLIFT" },
  { id: "SINGLE_LEG_BARBELL_DEADLIFT", name: "SINGLE_LEG_BARBELL_DEADLIFT" },
  {
    id: "SINGLE_LEG_BARBELL_STRAIGHT_LEG_DEADLIFT",
    name: "SINGLE_LEG_BARBELL_STRAIGHT_LEG_DEADLIFT",
  },
  {
    id: "SINGLE_LEG_DEADLIFT_WITH_BARBELL",
    name: "SINGLE_LEG_DEADLIFT_WITH_BARBELL",
  },
  { id: "SINGLE_LEG_RDL_CIRCUIT", name: "SINGLE_LEG_RDL_CIRCUIT" },
  {
    id: "SINGLE_LEG_ROMANIAN_DEADLIFT_CIRCUIT",
    name: "SINGLE_LEG_ROMANIAN_DEADLIFT_CIRCUIT",
  },
  {
    id: "SINGLE_LEG_ROMANIAN_DEADLIFT_WITH_DUMBBELL",
    name: "SINGLE_LEG_ROMANIAN_DEADLIFT_WITH_DUMBBELL",
  },
  { id: "SUMO_DEADLIFT", name: "SUMO_DEADLIFT" },
  { id: "SUMO_DEADLIFT_HIGH_PULL", name: "SUMO_DEADLIFT_HIGH_PULL" },
  { id: "TRAP_BAR_DEADLIFT", name: "TRAP_BAR_DEADLIFT" },
  { id: "WIDE_GRIP_BARBELL_DEADLIFT", name: "WIDE_GRIP_BARBELL_DEADLIFT" },
];

const ELLIPTICAL = [{ id: "ELLIPTICAL", name: "ELLIPTICAL" }];
const FLOOR_CLIMB = [{ id: "FLOOR_CLIMB", name: "FLOOR_CLIMB" }];

const FLYE = [
  { id: "CABLE_CROSSOVER", name: "CABLE_CROSSOVER" },
  { id: "DECLINE_DUMBBELL_FLYE", name: "DECLINE_DUMBBELL_FLYE" },
  { id: "DUMBBELL_FLYE", name: "DUMBBELL_FLYE" },
  { id: "INCLINE_DUMBBELL_FLYE", name: "INCLINE_DUMBBELL_FLYE" },
  { id: "INCLINE_REVERSE_FLYE", name: "INCLINE_REVERSE_FLYE" },
  { id: "KETTLEBELL_FLYE", name: "KETTLEBELL_FLYE" },
  { id: "KNEELING_REAR_FLYE", name: "KNEELING_REAR_FLYE" },
  {
    id: "SINGLE_ARM_STANDING_CABLE_REVERSE_FLYE",
    name: "SINGLE_ARM_STANDING_CABLE_REVERSE_FLYE",
  },
  { id: "SWISS_BALL_DUMBBELL_FLYE", name: "SWISS_BALL_DUMBBELL_FLYE" },
];

const HIP_RAISE = [
  { id: "HIP_RAISE", name: "HIP_RAISE" },
  { id: "BARBELL_HIP_THRUST_ON_FLOOR", name: "BARBELL_HIP_THRUST_ON_FLOOR" },
  {
    id: "BARBELL_HIP_THRUST_WITH_BENCH",
    name: "BARBELL_HIP_THRUST_WITH_BENCH",
  },
  {
    id: "BENT_KNEE_SWISS_BALL_REVERSE_HIP_RAISE",
    name: "BENT_KNEE_SWISS_BALL_REVERSE_HIP_RAISE",
  },
  { id: "BRIDGE_WITH_LEG_EXTENSION", name: "BRIDGE_WITH_LEG_EXTENSION" },
  { id: "CLAM_BRIDGE", name: "CLAM_BRIDGE" },
  { id: "FRONT_KICK_TABLETOP", name: "FRONT_KICK_TABLETOP" },
  { id: "HIP_EXTENSION_AND_CROSS", name: "HIP_EXTENSION_AND_CROSS" },
  {
    id: "HIP_RAISE_WITH_FEET_ON_SWISS_BALL",
    name: "HIP_RAISE_WITH_FEET_ON_SWISS_BALL",
  },
  {
    id: "HIP_RAISE_WITH_HEAD_ON_BOSU_BALL",
    name: "HIP_RAISE_WITH_HEAD_ON_BOSU_BALL",
  },
  {
    id: "HIP_RAISE_WITH_HEAD_ON_SWISS_BALL",
    name: "HIP_RAISE_WITH_HEAD_ON_SWISS_BALL",
  },
  { id: "HIP_RAISE_WITH_KNEE_SQUEEZE", name: "HIP_RAISE_WITH_KNEE_SQUEEZE" },
  { id: "INCLINE_REAR_LEG_EXTENSION", name: "INCLINE_REAR_LEG_EXTENSION" },
  { id: "KETTLEBELL_SWING", name: "KETTLEBELL_SWING" },
  { id: "MARCHING_HIP_RAISE", name: "MARCHING_HIP_RAISE" },
  {
    id: "MARCHING_HIP_RAISE_WITH_FEET_ON_A_SWISS_BALL",
    name: "MARCHING_HIP_RAISE_WITH_FEET_ON_A_SWISS_BALL",
  },
  { id: "REVERSE_HIP_RAISE", name: "REVERSE_HIP_RAISE" },
  { id: "SINGLE_LEG_HIP_RAISE", name: "SINGLE_LEG_HIP_RAISE" },
  {
    id: "SINGLE_LEG_HIP_RAISE_WITH_FOOT_ON_BENCH",
    name: "SINGLE_LEG_HIP_RAISE_WITH_FOOT_ON_BENCH",
  },
  {
    id: "SINGLE_LEG_HIP_RAISE_WITH_FOOT_ON_BOSU_BALL",
    name: "SINGLE_LEG_HIP_RAISE_WITH_FOOT_ON_BOSU_BALL",
  },
  {
    id: "SINGLE_LEG_HIP_RAISE_WITH_FOOT_ON_FOAM_ROLLER",
    name: "SINGLE_LEG_HIP_RAISE_WITH_FOOT_ON_FOAM_ROLLER",
  },
  {
    id: "SINGLE_LEG_HIP_RAISE_WITH_FOOT_ON_MEDICINE_BALL",
    name: "SINGLE_LEG_HIP_RAISE_WITH_FOOT_ON_MEDICINE_BALL",
  },
  {
    id: "SINGLE_LEG_HIP_RAISE_WITH_HEAD_ON_BOSU_BALL",
    name: "SINGLE_LEG_HIP_RAISE_WITH_HEAD_ON_BOSU_BALL",
  },
  {
    id: "SINGLE_LEG_SWISS_BALL_HIP_RAISE_AND_LEG_CURL",
    name: "SINGLE_LEG_SWISS_BALL_HIP_RAISE_AND_LEG_CURL",
  },
  {
    id: "WEIGHTED_BENT_KNEE_SWISS_BALL_REVERSE_HIP_RAISE",
    name: "WEIGHTED_BENT_KNEE_SWISS_BALL_REVERSE_HIP_RAISE",
  },
  {
    id: "WEIGHTED_BRIDGE_WITH_LEG_EXTENSION",
    name: "WEIGHTED_BRIDGE_WITH_LEG_EXTENSION",
  },
  { id: "WEIGHTED_CLAM_BRIDGE", name: "WEIGHTED_CLAM_BRIDGE" },
  { id: "WEIGHTED_FRONT_KICK_TABLETOP", name: "WEIGHTED_FRONT_KICK_TABLETOP" },
  {
    id: "WEIGHTED_HIP_EXTENSION_AND_CROSS",
    name: "WEIGHTED_HIP_EXTENSION_AND_CROSS",
  },
  { id: "WEIGHTED_HIP_RAISE", name: "WEIGHTED_HIP_RAISE" },
  {
    id: "WEIGHTED_HIP_RAISE_WITH_FEET_ON_SWISS_BALL",
    name: "WEIGHTED_HIP_RAISE_WITH_FEET_ON_SWISS_BALL",
  },
  {
    id: "WEIGHTED_HIP_RAISE_WITH_HEAD_ON_BOSU_BALL",
    name: "WEIGHTED_HIP_RAISE_WITH_HEAD_ON_BOSU_BALL",
  },
  {
    id: "WEIGHTED_HIP_RAISE_WITH_HEAD_ON_SWISS_BALL",
    name: "WEIGHTED_HIP_RAISE_WITH_HEAD_ON_SWISS_BALL",
  },
  {
    id: "WEIGHTED_HIP_RAISE_WITH_KNEE_SQUEEZE",
    name: "WEIGHTED_HIP_RAISE_WITH_KNEE_SQUEEZE",
  },
  {
    id: "WEIGHTED_INCLINE_REAR_LEG_EXTENSION",
    name: "WEIGHTED_INCLINE_REAR_LEG_EXTENSION",
  },
  { id: "WEIGHTED_MARCHING_HIP_RAISE", name: "WEIGHTED_MARCHING_HIP_RAISE" },
  {
    id: "WEIGHTED_MARCHING_HIP_RAISE_WITH_FEET_ON_A_SWISS_BALL",
    name: "WEIGHTED_MARCHING_HIP_RAISE_WITH_FEET_ON_A_SWISS_BALL",
  },
  { id: "WEIGHTED_REVERSE_HIP_RAISE", name: "WEIGHTED_REVERSE_HIP_RAISE" },
  {
    id: "WEIGHTED_SINGLE_LEG_HIP_RAISE",
    name: "WEIGHTED_SINGLE_LEG_HIP_RAISE",
  },
  {
    id: "WEIGHTED_SINGLE_LEG_HIP_RAISE_WITH_FOOT_ON_BENCH",
    name: "WEIGHTED_SINGLE_LEG_HIP_RAISE_WITH_FOOT_ON_BENCH",
  },
  {
    id: "WEIGHTED_SINGLE_LEG_HIP_RAISE_WITH_FOOT_ON_BOSU_BALL",
    name: "WEIGHTED_SINGLE_LEG_HIP_RAISE_WITH_FOOT_ON_BOSU_BALL",
  },
  {
    id: "WEIGHTED_SINGLE_LEG_HIP_RAISE_WITH_FOOT_ON_FOAM_ROLLER",
    name: "WEIGHTED_SINGLE_LEG_HIP_RAISE_WITH_FOOT_ON_FOAM_ROLLER",
  },
  {
    id: "WEIGHTED_SINGLE_LEG_HIP_RAISE_WITH_FOOT_ON_MEDICINE_BALL",
    name: "WEIGHTED_SINGLE_LEG_HIP_RAISE_WITH_FOOT_ON_MEDICINE_BALL",
  },
  {
    id: "WEIGHTED_SINGLE_LEG_HIP_RAISE_WITH_HEAD_ON_BOSU_BALL",
    name: "WEIGHTED_SINGLE_LEG_HIP_RAISE_WITH_HEAD_ON_BOSU_BALL",
  },
];

const HIP_STABILITY = [
  { id: "BAND_SIDE_LYING_LEG_RAISE", name: "BAND_SIDE_LYING_LEG_RAISE" },
  { id: "DEAD_BUG", name: "DEAD_BUG" },
  { id: "EXTERNAL_HIP_RAISE", name: "EXTERNAL_HIP_RAISE" },
  { id: "FIRE_HYDRANT_KICKS", name: "FIRE_HYDRANT_KICKS" },
  { id: "HIP_CIRCLES", name: "HIP_CIRCLES" },
  { id: "INNER_THIGH_LIFT", name: "INNER_THIGH_LIFT" },
  {
    id: "LATERAL_WALKS_WITH_BAND_AT_ANKLES",
    name: "LATERAL_WALKS_WITH_BAND_AT_ANKLES",
  },
  { id: "LYING_ABDUCTION_STRETCH", name: "LYING_ABDUCTION_STRETCH" },
  { id: "PRETZEL_SIDE_KICK", name: "PRETZEL_SIDE_KICK" },
  { id: "PRONE_HIP_INTERNAL_ROTATION", name: "PRONE_HIP_INTERNAL_ROTATION" },
  { id: "QUADRUPED", name: "QUADRUPED" },
  { id: "QUADRUPED_HIP_EXTENSION", name: "QUADRUPED_HIP_EXTENSION" },
  { id: "QUADRUPED_WITH_LEG_LIFT", name: "QUADRUPED_WITH_LEG_LIFT" },
  { id: "SIDE_LYING_LEG_RAISE", name: "SIDE_LYING_LEG_RAISE" },
  { id: "SLIDING_HIP_ADDUCTION", name: "SLIDING_HIP_ADDUCTION" },
  { id: "STANDING_ADDUCTION", name: "STANDING_ADDUCTION" },
  { id: "STANDING_CABLE_HIP_ABDUCTION", name: "STANDING_CABLE_HIP_ABDUCTION" },
  { id: "STANDING_HIP_ABDUCTION", name: "STANDING_HIP_ABDUCTION" },
  { id: "STANDING_REAR_LEG_RAISE", name: "STANDING_REAR_LEG_RAISE" },
  { id: "SUPINE_HIP_INTERNAL_ROTATION", name: "SUPINE_HIP_INTERNAL_ROTATION" },
  { id: "WEIGHTED_DEAD_BUG", name: "WEIGHTED_DEAD_BUG" },
  { id: "WEIGHTED_EXTERNAL_HIP_RAISE", name: "WEIGHTED_EXTERNAL_HIP_RAISE" },
  { id: "WEIGHTED_FIRE_HYDRANT_KICKS", name: "WEIGHTED_FIRE_HYDRANT_KICKS" },
  { id: "WEIGHTED_HIP_CIRCLES", name: "WEIGHTED_HIP_CIRCLES" },
  { id: "WEIGHTED_INNER_THIGH_LIFT", name: "WEIGHTED_INNER_THIGH_LIFT" },
  { id: "WEIGHTED_PRETZEL_SIDE_KICK", name: "WEIGHTED_PRETZEL_SIDE_KICK" },
  {
    id: "WEIGHTED_PRONE_HIP_INTERNAL_ROTATION",
    name: "WEIGHTED_PRONE_HIP_INTERNAL_ROTATION",
  },
  {
    id: "WEIGHTED_QUADRUPED_HIP_EXTENSION",
    name: "WEIGHTED_QUADRUPED_HIP_EXTENSION",
  },
  {
    id: "WEIGHTED_QUADRUPED_WITH_LEG_LIFT",
    name: "WEIGHTED_QUADRUPED_WITH_LEG_LIFT",
  },
  {
    id: "WEIGHTED_SIDE_LYING_LEG_RAISE",
    name: "WEIGHTED_SIDE_LYING_LEG_RAISE",
  },
  {
    id: "WEIGHTED_SLIDING_HIP_ADDUCTION",
    name: "WEIGHTED_SLIDING_HIP_ADDUCTION",
  },
  { id: "WEIGHTED_STANDING_ADDUCTION", name: "WEIGHTED_STANDING_ADDUCTION" },
  {
    id: "WEIGHTED_STANDING_HIP_ABDUCTION",
    name: "WEIGHTED_STANDING_HIP_ABDUCTION",
  },
  {
    id: "WEIGHTED_STANDING_REAR_LEG_RAISE",
    name: "WEIGHTED_STANDING_REAR_LEG_RAISE",
  },
  {
    id: "WEIGHTED_SUPINE_HIP_INTERNAL_ROTATION",
    name: "WEIGHTED_SUPINE_HIP_INTERNAL_ROTATION",
  },
];

const HIP_SWING = [
  { id: "ONE_ARM_SWING", name: "ONE_ARM_SWING" },
  { id: "SINGLE_ARM_DUMBBELL_SWING", name: "SINGLE_ARM_DUMBBELL_SWING" },
  { id: "SINGLE_ARM_KETTLEBELL_SWING", name: "SINGLE_ARM_KETTLEBELL_SWING" },
  { id: "STEP_OUT_SWING", name: "STEP_OUT_SWING" },
];

const HYPEREXTENSION = [
  {
    id: "BACK_EXTENSION_WITH_OPPOSITE_ARM_AND_LEG_REACH",
    name: "BACK_EXTENSION_WITH_OPPOSITE_ARM_AND_LEG_REACH",
  },
  { id: "BASE_ROTATIONS", name: "BASE_ROTATIONS" },
  {
    id: "BENT_KNEE_REVERSE_HYPEREXTENSION",
    name: "BENT_KNEE_REVERSE_HYPEREXTENSION",
  },
  { id: "HOLLOW_HOLD_AND_ROLL", name: "HOLLOW_HOLD_AND_ROLL" },
  { id: "KICKS", name: "KICKS" },
  { id: "KNEELING_SUPERMAN", name: "KNEELING_SUPERMAN" },
  { id: "KNEE_RAISES", name: "KNEE_RAISES" },
  { id: "LAT_PULL_DOWN_WITH_ROW", name: "LAT_PULL_DOWN_WITH_ROW" },
  {
    id: "MEDICINE_BALL_DEADLIFT_TO_REACH",
    name: "MEDICINE_BALL_DEADLIFT_TO_REACH",
  },
  { id: "ONE_ARM_ONE_LEG_ROW", name: "ONE_ARM_ONE_LEG_ROW" },
  { id: "ONE_ARM_ROW_WITH_BAND", name: "ONE_ARM_ROW_WITH_BAND" },
  {
    id: "OVERHEAD_LUNGE_WITH_MEDICINE_BALL",
    name: "OVERHEAD_LUNGE_WITH_MEDICINE_BALL",
  },
  { id: "PLANK_KNEE_TUCKS", name: "PLANK_KNEE_TUCKS" },
  { id: "SIDE_STEP", name: "SIDE_STEP" },
  { id: "SINGLE_LEG_BACK_EXTENSION", name: "SINGLE_LEG_BACK_EXTENSION" },
  { id: "SPINE_EXTENSION", name: "SPINE_EXTENSION" },
  { id: "STATIC_BACK_EXTENSION", name: "STATIC_BACK_EXTENSION" },
  { id: "SUPERMAN_FROM_FLOOR", name: "SUPERMAN_FROM_FLOOR" },
  { id: "SUPERMAN_ON_SWISS_BALL", name: "SUPERMAN_ON_SWISS_BALL" },
  { id: "SWISS_BALL_BACK_EXTENSION", name: "SWISS_BALL_BACK_EXTENSION" },
  { id: "SWISS_BALL_HYPEREXTENSION", name: "SWISS_BALL_HYPEREXTENSION" },
  {
    id: "SWISS_BALL_OPPOSITE_ARM_AND_LEG_LIFT",
    name: "SWISS_BALL_OPPOSITE_ARM_AND_LEG_LIFT",
  },
  {
    id: "WEIGHTED_BACK_EXTENSION_WITH_OPPOSITE_ARM_AND_LEG_REACH",
    name: "WEIGHTED_BACK_EXTENSION_WITH_OPPOSITE_ARM_AND_LEG_REACH",
  },
  { id: "WEIGHTED_BASE_ROTATIONS", name: "WEIGHTED_BASE_ROTATIONS" },
  {
    id: "WEIGHTED_BENT_KNEE_REVERSE_HYPEREXTENSION",
    name: "WEIGHTED_BENT_KNEE_REVERSE_HYPEREXTENSION",
  },
  {
    id: "WEIGHTED_HOLLOW_HOLD_AND_ROLL",
    name: "WEIGHTED_HOLLOW_HOLD_AND_ROLL",
  },
  { id: "WEIGHTED_KICKS", name: "WEIGHTED_KICKS" },
  { id: "WEIGHTED_KNEELING_SUPERMAN", name: "WEIGHTED_KNEELING_SUPERMAN" },
  { id: "WEIGHTED_KNEE_RAISES", name: "WEIGHTED_KNEE_RAISES" },
  { id: "WEIGHTED_PLANK_KNEE_TUCKS", name: "WEIGHTED_PLANK_KNEE_TUCKS" },
  { id: "WEIGHTED_SIDE_STEP", name: "WEIGHTED_SIDE_STEP" },
  {
    id: "WEIGHTED_SINGLE_LEG_BACK_EXTENSION",
    name: "WEIGHTED_SINGLE_LEG_BACK_EXTENSION",
  },
  { id: "WEIGHTED_SPINE_EXTENSION", name: "WEIGHTED_SPINE_EXTENSION" },
  {
    id: "WEIGHTED_STATIC_BACK_EXTENSION",
    name: "WEIGHTED_STATIC_BACK_EXTENSION",
  },
  { id: "WEIGHTED_SUPERMAN_FROM_FLOOR", name: "WEIGHTED_SUPERMAN_FROM_FLOOR" },
  {
    id: "WEIGHTED_SWISS_BALL_BACK_EXTENSION",
    name: "WEIGHTED_SWISS_BALL_BACK_EXTENSION",
  },
  {
    id: "WEIGHTED_SWISS_BALL_HYPEREXTENSION",
    name: "WEIGHTED_SWISS_BALL_HYPEREXTENSION",
  },
  {
    id: "WEIGHTED_SWISS_BALL_OPPOSITE_ARM_AND_LEG_LIFT",
    name: "WEIGHTED_SWISS_BALL_OPPOSITE_ARM_AND_LEG_LIFT",
  },
];

const INDOOR_BIKE = [
  { id: "AIR_BIKE", name: "AIR_BIKE" },
  { id: "ASSAULT_BIKE", name: "ASSAULT_BIKE" },
  { id: "STATIONARY_BIKE", name: "STATIONARY_BIKE" },
];

const LADDER = [
  { id: "AGILITY", name: "AGILITY" },
  { id: "SPEED", name: "SPEED" },
];

const LATERAL_RAISE = [
  {
    id: "ALTERNATING_LATERAL_RAISE_WITH_STATIC_HOLD",
    name: "ALTERNATING_LATERAL_RAISE_WITH_STATIC_HOLD",
  },
  { id: "BAR_MUSCLE_UP", name: "BAR_MUSCLE_UP" },
  { id: "BENT_OVER_LATERAL_RAISE", name: "BENT_OVER_LATERAL_RAISE" },
  { id: "CABLE_DIAGONAL_RAISE", name: "CABLE_DIAGONAL_RAISE" },
  { id: "CABLE_FRONT_RAISE", name: "CABLE_FRONT_RAISE" },
  { id: "CALORIE_ROW", name: "CALORIE_ROW" },
  { id: "COMBO_SHOULDER_RAISE", name: "COMBO_SHOULDER_RAISE" },
  { id: "DUMBBELL_DIAGONAL_RAISE", name: "DUMBBELL_DIAGONAL_RAISE" },
  { id: "DUMBBELL_LATERAL_RAISE", name: "DUMBBELL_LATERAL_RAISE" },
  { id: "DUMBBELL_V_RAISE", name: "DUMBBELL_V_RAISE" },
  { id: "FRONT_RAISE", name: "FRONT_RAISE" },
  {
    id: "LEANING_DUMBBELL_LATERAL_RAISE",
    name: "LEANING_DUMBBELL_LATERAL_RAISE",
  },
  { id: "LYING_DUMBBELL_RAISE", name: "LYING_DUMBBELL_RAISE" },
  { id: "MUSCLE_UP", name: "MUSCLE_UP" },
  { id: "ONE_ARM_CABLE_LATERAL_RAISE", name: "ONE_ARM_CABLE_LATERAL_RAISE" },
  {
    id: "OVERHAND_GRIP_REAR_LATERAL_RAISE",
    name: "OVERHAND_GRIP_REAR_LATERAL_RAISE",
  },
  { id: "PLATE_RAISES", name: "PLATE_RAISES" },
  { id: "RING_DIP", name: "RING_DIP" },
  { id: "RING_DIP_KIPPING", name: "RING_DIP_KIPPING" },
  { id: "RING_MUSCLE_UP", name: "RING_MUSCLE_UP" },
  { id: "ROPE_CLIMB", name: "ROPE_CLIMB" },
  { id: "SCAPTION", name: "SCAPTION" },
  { id: "SEATED_LATERAL_RAISE", name: "SEATED_LATERAL_RAISE" },
  { id: "SEATED_REAR_LATERAL_RAISE", name: "SEATED_REAR_LATERAL_RAISE" },
  { id: "SIDE_LYING_LATERAL_RAISE", name: "SIDE_LYING_LATERAL_RAISE" },
  { id: "STANDING_LIFT", name: "STANDING_LIFT" },
  { id: "SUSPENDED_ROW", name: "SUSPENDED_ROW" },
  {
    id: "UNDERHAND_GRIP_REAR_LATERAL_RAISE",
    name: "UNDERHAND_GRIP_REAR_LATERAL_RAISE",
  },
  { id: "WALL_SLIDE", name: "WALL_SLIDE" },
  { id: "WALL_WALK", name: "WALL_WALK" },
  { id: "WEIGHTED_RING_DIP", name: "WEIGHTED_RING_DIP" },
  { id: "WEIGHTED_RING_MUSCLE_UP", name: "WEIGHTED_RING_MUSCLE_UP" },
  { id: "WEIGHTED_ROPE_CLIMB", name: "WEIGHTED_ROPE_CLIMB" },
  { id: "WEIGHTED_WALL_SLIDE", name: "WEIGHTED_WALL_SLIDE" },
  {
    id: "_45_DEGREE_CABLE_EXTERNAL_ROTATION",
    name: "_45_DEGREE_CABLE_EXTERNAL_ROTATION",
  },
];

const LEG_CURL = [
  { id: "LEG_CURL", name: "LEG_CURL" },
  { id: "BAND_GOOD_MORNING", name: "BAND_GOOD_MORNING" },
  { id: "BAR_GOOD_MORNING", name: "BAR_GOOD_MORNING" },
  { id: "GOOD_MORNING", name: "GOOD_MORNING" },
  { id: "SEATED_BARBELL_GOOD_MORNING", name: "SEATED_BARBELL_GOOD_MORNING" },
  {
    id: "SINGLE_LEG_BARBELL_GOOD_MORNING",
    name: "SINGLE_LEG_BARBELL_GOOD_MORNING",
  },
  { id: "SINGLE_LEG_SLIDING_LEG_CURL", name: "SINGLE_LEG_SLIDING_LEG_CURL" },
  { id: "SLIDING_LEG_CURL", name: "SLIDING_LEG_CURL" },
  { id: "SPLIT_BARBELL_GOOD_MORNING", name: "SPLIT_BARBELL_GOOD_MORNING" },
  { id: "SPLIT_STANCE_EXTENSION", name: "SPLIT_STANCE_EXTENSION" },
  {
    id: "STAGGERED_STANCE_GOOD_MORNING",
    name: "STAGGERED_STANCE_GOOD_MORNING",
  },
  {
    id: "SWISS_BALL_HIP_RAISE_AND_LEG_CURL",
    name: "SWISS_BALL_HIP_RAISE_AND_LEG_CURL",
  },
  { id: "WEIGHTED_LEG_CURL", name: "WEIGHTED_LEG_CURL" },
  { id: "ZERCHER_GOOD_MORNING", name: "ZERCHER_GOOD_MORNING" },
];

const LEG_RAISE = [
  { id: "HANGING_KNEE_RAISE", name: "HANGING_KNEE_RAISE" },
  { id: "HANGING_LEG_RAISE", name: "HANGING_LEG_RAISE" },
  { id: "HANGING_SINGLE_LEG_RAISE", name: "HANGING_SINGLE_LEG_RAISE" },
  { id: "KETTLEBELL_LEG_RAISES", name: "KETTLEBELL_LEG_RAISES" },
  { id: "LATERAL_STEPOVER", name: "LATERAL_STEPOVER" },
  { id: "LEG_LOWERING_DRILL", name: "LEG_LOWERING_DRILL" },
  { id: "LYING_STRAIGHT_LEG_RAISE", name: "LYING_STRAIGHT_LEG_RAISE" },
  { id: "MEDICINE_BALL_LEG_DROPS", name: "MEDICINE_BALL_LEG_DROPS" },
  { id: "QUADRUPED_LEG_RAISE", name: "QUADRUPED_LEG_RAISE" },
  { id: "REVERSE_LEG_RAISE", name: "REVERSE_LEG_RAISE" },
  {
    id: "REVERSE_LEG_RAISE_ON_SWISS_BALL",
    name: "REVERSE_LEG_RAISE_ON_SWISS_BALL",
  },
  { id: "SINGLE_LEG_LOWERING_DRILL", name: "SINGLE_LEG_LOWERING_DRILL" },
  { id: "WEIGHTED_HANGING_KNEE_RAISE", name: "WEIGHTED_HANGING_KNEE_RAISE" },
  { id: "WEIGHTED_HANGING_LEG_RAISE", name: "WEIGHTED_HANGING_LEG_RAISE" },
  {
    id: "WEIGHTED_HANGING_SINGLE_LEG_RAISE",
    name: "WEIGHTED_HANGING_SINGLE_LEG_RAISE",
  },
  { id: "WEIGHTED_LATERAL_STEPOVER", name: "WEIGHTED_LATERAL_STEPOVER" },
  { id: "WEIGHTED_LEG_LOWERING_DRILL", name: "WEIGHTED_LEG_LOWERING_DRILL" },
  {
    id: "WEIGHTED_LYING_STRAIGHT_LEG_RAISE",
    name: "WEIGHTED_LYING_STRAIGHT_LEG_RAISE",
  },
  { id: "WEIGHTED_QUADRUPED_LEG_RAISE", name: "WEIGHTED_QUADRUPED_LEG_RAISE" },
  { id: "WEIGHTED_REVERSE_LEG_RAISE", name: "WEIGHTED_REVERSE_LEG_RAISE" },
  {
    id: "WEIGHTED_REVERSE_LEG_RAISE_ON_SWISS_BALL",
    name: "WEIGHTED_REVERSE_LEG_RAISE_ON_SWISS_BALL",
  },
  {
    id: "WEIGHTED_SINGLE_LEG_LOWERING_DRILL",
    name: "WEIGHTED_SINGLE_LEG_LOWERING_DRILL",
  },
];

const LUNGE = [
  { id: "LUNGE", name: "LUNGE" },
  {
    id: "ALTERNATING_BARBELL_FORWARD_LUNGE",
    name: "ALTERNATING_BARBELL_FORWARD_LUNGE",
  },
  { id: "ALTERNATING_DUMBBELL_LUNGE", name: "ALTERNATING_DUMBBELL_LUNGE" },
  {
    id: "ALTERNATING_DUMBBELL_LUNGE_WITH_REACH",
    name: "ALTERNATING_DUMBBELL_LUNGE_WITH_REACH",
  },
  {
    id: "BACK_FOOT_ELEVATED_DUMBBELL_SPLIT_SQUAT",
    name: "BACK_FOOT_ELEVATED_DUMBBELL_SPLIT_SQUAT",
  },
  { id: "BARBELL_BOX_LUNGE", name: "BARBELL_BOX_LUNGE" },
  {
    id: "BARBELL_BULGARIAN_SPLIT_SQUAT",
    name: "BARBELL_BULGARIAN_SPLIT_SQUAT",
  },
  { id: "BARBELL_CROSSOVER_LUNGE", name: "BARBELL_CROSSOVER_LUNGE" },
  { id: "BARBELL_FRONT_SPLIT_SQUAT", name: "BARBELL_FRONT_SPLIT_SQUAT" },
  { id: "BARBELL_LUNGE", name: "BARBELL_LUNGE" },
  { id: "BARBELL_REVERSE_LUNGE", name: "BARBELL_REVERSE_LUNGE" },
  { id: "BARBELL_SIDE_LUNGE", name: "BARBELL_SIDE_LUNGE" },
  { id: "BARBELL_SPLIT_SQUAT", name: "BARBELL_SPLIT_SQUAT" },
  { id: "CORE_CONTROL_REAR_LUNGE", name: "CORE_CONTROL_REAR_LUNGE" },
  { id: "CURTSY_LUNGE", name: "CURTSY_LUNGE" },
  { id: "DIAGONAL_LUNGE", name: "DIAGONAL_LUNGE" },
  { id: "DROP_LUNGE", name: "DROP_LUNGE" },
  { id: "DUMBBELL_BOX_LUNGE", name: "DUMBBELL_BOX_LUNGE" },
  {
    id: "DUMBBELL_BULGARIAN_SPLIT_SQUAT",
    name: "DUMBBELL_BULGARIAN_SPLIT_SQUAT",
  },
  { id: "DUMBBELL_CROSSOVER_LUNGE", name: "DUMBBELL_CROSSOVER_LUNGE" },
  { id: "DUMBBELL_DIAGONAL_LUNGE", name: "DUMBBELL_DIAGONAL_LUNGE" },
  { id: "DUMBBELL_LUNGE", name: "DUMBBELL_LUNGE" },
  { id: "DUMBBELL_LUNGE_AND_ROTATION", name: "DUMBBELL_LUNGE_AND_ROTATION" },
  {
    id: "DUMBBELL_OVERHEAD_BULGARIAN_SPLIT_SQUAT",
    name: "DUMBBELL_OVERHEAD_BULGARIAN_SPLIT_SQUAT",
  },
  {
    id: "DUMBBELL_OVERHEAD_WALKING_LUNGE",
    name: "DUMBBELL_OVERHEAD_WALKING_LUNGE",
  },
  { id: "DUMBBELL_REVERSE_LUNGE", name: "DUMBBELL_REVERSE_LUNGE" },
  {
    id: "DUMBBELL_REVERSE_LUNGE_TO_HIGH_KNEE_AND_PRESS",
    name: "DUMBBELL_REVERSE_LUNGE_TO_HIGH_KNEE_AND_PRESS",
  },
  { id: "DUMBBELL_SIDE_LUNGE", name: "DUMBBELL_SIDE_LUNGE" },
  {
    id: "ELEVATED_FRONT_FOOT_BARBELL_SPLIT_SQUAT",
    name: "ELEVATED_FRONT_FOOT_BARBELL_SPLIT_SQUAT",
  },
  {
    id: "FRONT_FOOT_ELEVATED_DUMBBELL_SPLIT_SQUAT",
    name: "FRONT_FOOT_ELEVATED_DUMBBELL_SPLIT_SQUAT",
  },
  { id: "GUNSLINGER_LUNGE", name: "GUNSLINGER_LUNGE" },
  { id: "LAWNMOWER_LUNGE", name: "LAWNMOWER_LUNGE" },
  {
    id: "LOW_LUNGE_WITH_ISOMETRIC_ADDUCTION",
    name: "LOW_LUNGE_WITH_ISOMETRIC_ADDUCTION",
  },
  { id: "LOW_SIDE_TO_SIDE_LUNGE", name: "LOW_SIDE_TO_SIDE_LUNGE" },
  { id: "LUNGE_MATRIX", name: "LUNGE_MATRIX" },
  { id: "LUNGE_WITH_ARM_REACH", name: "LUNGE_WITH_ARM_REACH" },
  { id: "LUNGE_WITH_DIAGONAL_REACH", name: "LUNGE_WITH_DIAGONAL_REACH" },
  { id: "LUNGE_WITH_SIDE_BEND", name: "LUNGE_WITH_SIDE_BEND" },
  { id: "OFFSET_DUMBBELL_LUNGE", name: "OFFSET_DUMBBELL_LUNGE" },
  {
    id: "OFFSET_DUMBBELL_REVERSE_LUNGE",
    name: "OFFSET_DUMBBELL_REVERSE_LUNGE",
  },
  {
    id: "OVERHEAD_BULGARIAN_SPLIT_SQUAT",
    name: "OVERHEAD_BULGARIAN_SPLIT_SQUAT",
  },
  { id: "OVERHEAD_DUMBBELL_LUNGE", name: "OVERHEAD_DUMBBELL_LUNGE" },
  {
    id: "OVERHEAD_DUMBBELL_REVERSE_LUNGE",
    name: "OVERHEAD_DUMBBELL_REVERSE_LUNGE",
  },
  {
    id: "OVERHEAD_DUMBBELL_SPLIT_SQUAT",
    name: "OVERHEAD_DUMBBELL_SPLIT_SQUAT",
  },
  { id: "OVERHEAD_LUNGE", name: "OVERHEAD_LUNGE" },
  { id: "OVERHEAD_LUNGE_WITH_ROTATION", name: "OVERHEAD_LUNGE_WITH_ROTATION" },
  { id: "REVERSE_BARBELL_BOX_LUNGE", name: "REVERSE_BARBELL_BOX_LUNGE" },
  { id: "REVERSE_BOX_LUNGE", name: "REVERSE_BOX_LUNGE" },
  { id: "REVERSE_DUMBBELL_BOX_LUNGE", name: "REVERSE_DUMBBELL_BOX_LUNGE" },
  {
    id: "REVERSE_DUMBBELL_CROSSOVER_LUNGE",
    name: "REVERSE_DUMBBELL_CROSSOVER_LUNGE",
  },
  {
    id: "REVERSE_DUMBBELL_DIAGONAL_LUNGE",
    name: "REVERSE_DUMBBELL_DIAGONAL_LUNGE",
  },
  {
    id: "REVERSE_LUNGE_WITH_REACH_BACK",
    name: "REVERSE_LUNGE_WITH_REACH_BACK",
  },
  {
    id: "REVERSE_LUNGE_WITH_TWIST_AND_OVERHEAD_REACH",
    name: "REVERSE_LUNGE_WITH_TWIST_AND_OVERHEAD_REACH",
  },
  { id: "REVERSE_SLIDING_BOX_LUNGE", name: "REVERSE_SLIDING_BOX_LUNGE" },
  { id: "REVERSE_SLIDING_LUNGE", name: "REVERSE_SLIDING_LUNGE" },
  { id: "RUNNERS_LUNGE_TO_BALANCE", name: "RUNNERS_LUNGE_TO_BALANCE" },
  { id: "SCISSOR_POWER_SWITCH", name: "SCISSOR_POWER_SWITCH" },
  { id: "SHIFTING_SIDE_LUNGE", name: "SHIFTING_SIDE_LUNGE" },
  { id: "SIDE_AND_CROSSOVER_LUNGE", name: "SIDE_AND_CROSSOVER_LUNGE" },
  { id: "SIDE_LUNGE", name: "SIDE_LUNGE" },
  { id: "SIDE_LUNGE_AND_PRESS", name: "SIDE_LUNGE_AND_PRESS" },
  { id: "SIDE_LUNGE_JUMP_OFF", name: "SIDE_LUNGE_JUMP_OFF" },
  { id: "SIDE_LUNGE_SWEEP", name: "SIDE_LUNGE_SWEEP" },
  { id: "SIDE_LUNGE_TO_CROSSOVER_TAP", name: "SIDE_LUNGE_TO_CROSSOVER_TAP" },
  { id: "SIDE_TO_SIDE_LUNGE_CHOPS", name: "SIDE_TO_SIDE_LUNGE_CHOPS" },
  { id: "SIFF_JUMP_LUNGE", name: "SIFF_JUMP_LUNGE" },
  {
    id: "SINGLE_ARM_REVERSE_LUNGE_AND_PRESS",
    name: "SINGLE_ARM_REVERSE_LUNGE_AND_PRESS",
  },
  { id: "SLIDING_LATERAL_LUNGE", name: "SLIDING_LATERAL_LUNGE" },
  { id: "WALKING_BARBELL_LUNGE", name: "WALKING_BARBELL_LUNGE" },
  { id: "WALKING_DUMBBELL_LUNGE", name: "WALKING_DUMBBELL_LUNGE" },
  { id: "WALKING_LUNGE", name: "WALKING_LUNGE" },
  { id: "WEIGHTED_CURTSY_LUNGE", name: "WEIGHTED_CURTSY_LUNGE" },
  { id: "WEIGHTED_LUNGE", name: "WEIGHTED_LUNGE" },
  { id: "WEIGHTED_LUNGE_MATRIX", name: "WEIGHTED_LUNGE_MATRIX" },
  {
    id: "WEIGHTED_REVERSE_LUNGE_WITH_REACH_BACK",
    name: "WEIGHTED_REVERSE_LUNGE_WITH_REACH_BACK",
  },
  {
    id: "WEIGHTED_REVERSE_LUNGE_WITH_TWIST_AND_OVERHEAD_REACH",
    name: "WEIGHTED_REVERSE_LUNGE_WITH_TWIST_AND_OVERHEAD_REACH",
  },
  {
    id: "WEIGHTED_REVERSE_SLIDING_BOX_LUNGE",
    name: "WEIGHTED_REVERSE_SLIDING_BOX_LUNGE",
  },
  {
    id: "WEIGHTED_REVERSE_SLIDING_LUNGE",
    name: "WEIGHTED_REVERSE_SLIDING_LUNGE",
  },
  {
    id: "WEIGHTED_RUNNERS_LUNGE_TO_BALANCE",
    name: "WEIGHTED_RUNNERS_LUNGE_TO_BALANCE",
  },
  { id: "WEIGHTED_SHIFTING_SIDE_LUNGE", name: "WEIGHTED_SHIFTING_SIDE_LUNGE" },
  {
    id: "WEIGHTED_SIDE_AND_CROSSOVER_LUNGE",
    name: "WEIGHTED_SIDE_AND_CROSSOVER_LUNGE",
  },
  { id: "WEIGHTED_SIDE_LUNGE", name: "WEIGHTED_SIDE_LUNGE" },
  {
    id: "WEIGHTED_SIDE_LUNGE_AND_PRESS",
    name: "WEIGHTED_SIDE_LUNGE_AND_PRESS",
  },
  { id: "WEIGHTED_SIDE_LUNGE_JUMP_OFF", name: "WEIGHTED_SIDE_LUNGE_JUMP_OFF" },
  { id: "WEIGHTED_SIDE_LUNGE_SWEEP", name: "WEIGHTED_SIDE_LUNGE_SWEEP" },
  {
    id: "WEIGHTED_SIDE_LUNGE_TO_CROSSOVER_TAP",
    name: "WEIGHTED_SIDE_LUNGE_TO_CROSSOVER_TAP",
  },
  {
    id: "WEIGHTED_SIDE_TO_SIDE_LUNGE_CHOPS",
    name: "WEIGHTED_SIDE_TO_SIDE_LUNGE_CHOPS",
  },
  { id: "WEIGHTED_SIFF_JUMP_LUNGE", name: "WEIGHTED_SIFF_JUMP_LUNGE" },
  {
    id: "WEIGHTED_SLIDING_LATERAL_LUNGE",
    name: "WEIGHTED_SLIDING_LATERAL_LUNGE",
  },
  { id: "WEIGHTED_WALKING_LUNGE", name: "WEIGHTED_WALKING_LUNGE" },
  {
    id: "WIDE_GRIP_OVERHEAD_BARBELL_SPLIT_SQUAT",
    name: "WIDE_GRIP_OVERHEAD_BARBELL_SPLIT_SQUAT",
  },
];

const OLYMPIC_LIFT = [
  { id: "BARBELL_HANG_POWER_CLEAN", name: "BARBELL_HANG_POWER_CLEAN" },
  { id: "BARBELL_HANG_POWER_SNATCH", name: "BARBELL_HANG_POWER_SNATCH" },
  { id: "BARBELL_HANG_PULL", name: "BARBELL_HANG_PULL" },
  { id: "BARBELL_HANG_SQUAT_CLEAN", name: "BARBELL_HANG_SQUAT_CLEAN" },
  { id: "BARBELL_HIGH_PULL", name: "BARBELL_HIGH_PULL" },
  { id: "BARBELL_POWER_CLEAN", name: "BARBELL_POWER_CLEAN" },
  { id: "BARBELL_POWER_SNATCH", name: "BARBELL_POWER_SNATCH" },
  { id: "BARBELL_SNATCH", name: "BARBELL_SNATCH" },
  { id: "BARBELL_SPLIT_JERK", name: "BARBELL_SPLIT_JERK" },
  { id: "BARBELL_SQUAT_CLEAN", name: "BARBELL_SQUAT_CLEAN" },
  { id: "CLEAN", name: "CLEAN" },
  { id: "CLEAN_AND_JERK", name: "CLEAN_AND_JERK" },
  { id: "CLEAN_AND_PRESS", name: "CLEAN_AND_PRESS" },
  { id: "DUMBBELL_CLEAN", name: "DUMBBELL_CLEAN" },
  { id: "DUMBBELL_HANG_PULL", name: "DUMBBELL_HANG_PULL" },
  { id: "DUMBBELL_HANG_SNATCH", name: "DUMBBELL_HANG_SNATCH" },
  {
    id: "DUMBBELL_POWER_CLEAN_AND_JERK",
    name: "DUMBBELL_POWER_CLEAN_AND_JERK",
  },
  {
    id: "DUMBBELL_POWER_CLEAN_AND_PUSH_PRESS",
    name: "DUMBBELL_POWER_CLEAN_AND_PUSH_PRESS",
  },
  {
    id: "DUMBBELL_POWER_CLEAN_AND_STRICT_PRESS",
    name: "DUMBBELL_POWER_CLEAN_AND_STRICT_PRESS",
  },
  { id: "DUMBBELL_SNATCH", name: "DUMBBELL_SNATCH" },
  { id: "MEDICINE_BALL_CLEAN", name: "MEDICINE_BALL_CLEAN" },
  {
    id: "ONE_HAND_DUMBBELL_SPLIT_SNATCH",
    name: "ONE_HAND_DUMBBELL_SPLIT_SNATCH",
  },
  { id: "PUSH_JERK", name: "PUSH_JERK" },
  { id: "SINGLE_ARM_DUMBBELL_SNATCH", name: "SINGLE_ARM_DUMBBELL_SNATCH" },
  { id: "SINGLE_ARM_HANG_SNATCH", name: "SINGLE_ARM_HANG_SNATCH" },
  { id: "SINGLE_ARM_KETTLEBELL_SNATCH", name: "SINGLE_ARM_KETTLEBELL_SNATCH" },
  { id: "SPLIT_JERK", name: "SPLIT_JERK" },
  { id: "SQUAT_CLEAN_AND_JERK", name: "SQUAT_CLEAN_AND_JERK" },
];

const PLANK = [
  { id: "PLANK", name: "PLANK" },
  { id: "BEAR_CRAWL", name: "BEAR_CRAWL" },
  { id: "CROSS_BODY_MOUNTAIN_CLIMBER", name: "CROSS_BODY_MOUNTAIN_CLIMBER" },
  { id: "ELBOW_PLANK_PIKE_JACKS", name: "ELBOW_PLANK_PIKE_JACKS" },
  { id: "ELEVATED_FEET_PLANK", name: "ELEVATED_FEET_PLANK" },
  { id: "ELEVATOR_ABS", name: "ELEVATOR_ABS" },
  { id: "EXTENDED_PLANK", name: "EXTENDED_PLANK" },
  { id: "FULL_PLANK_PASSE_TWIST", name: "FULL_PLANK_PASSE_TWIST" },
  { id: "INCHING_ELBOW_PLANK", name: "INCHING_ELBOW_PLANK" },
  { id: "INCHWORM_TO_SIDE_PLANK", name: "INCHWORM_TO_SIDE_PLANK" },
  { id: "KNEELING_PLANK", name: "KNEELING_PLANK" },
  {
    id: "KNEELING_SIDE_PLANK_WITH_LEG_LIFT",
    name: "KNEELING_SIDE_PLANK_WITH_LEG_LIFT",
  },
  { id: "LATERAL_ROLL", name: "LATERAL_ROLL" },
  { id: "LYING_REVERSE_PLANK", name: "LYING_REVERSE_PLANK" },
  {
    id: "MEDICINE_BALL_MOUNTAIN_CLIMBER",
    name: "MEDICINE_BALL_MOUNTAIN_CLIMBER",
  },
  {
    id: "MODIFIED_MOUNTAIN_CLIMBER_AND_EXTENSION",
    name: "MODIFIED_MOUNTAIN_CLIMBER_AND_EXTENSION",
  },
  { id: "MOUNTAIN_CLIMBER", name: "MOUNTAIN_CLIMBER" },
  {
    id: "MOUNTAIN_CLIMBER_ON_SLIDING_DISCS",
    name: "MOUNTAIN_CLIMBER_ON_SLIDING_DISCS",
  },
  {
    id: "MOUNTAIN_CLIMBER_WITH_FEET_ON_BOSU_BALL",
    name: "MOUNTAIN_CLIMBER_WITH_FEET_ON_BOSU_BALL",
  },
  {
    id: "MOUNTAIN_CLIMBER_WITH_HANDS_ON_BENCH",
    name: "MOUNTAIN_CLIMBER_WITH_HANDS_ON_BENCH",
  },
  {
    id: "MOUNTAIN_CLIMBER_WITH_HANDS_ON_SWISS_BALL",
    name: "MOUNTAIN_CLIMBER_WITH_HANDS_ON_SWISS_BALL",
  },
  {
    id: "PLANK_JACKS_WITH_FEET_ON_SLIDING_DISCS",
    name: "PLANK_JACKS_WITH_FEET_ON_SLIDING_DISCS",
  },
  { id: "PLANK_KNEE_TWIST", name: "PLANK_KNEE_TWIST" },
  { id: "PLANK_PIKES", name: "PLANK_PIKES" },
  { id: "PLANK_PIKE_JUMPS", name: "PLANK_PIKE_JUMPS" },
  { id: "PLANK_TO_STAND_UP", name: "PLANK_TO_STAND_UP" },
  { id: "PLANK_WITH_ARM_RAISE", name: "PLANK_WITH_ARM_RAISE" },
  {
    id: "PLANK_WITH_FEET_ON_SWISS_BALL",
    name: "PLANK_WITH_FEET_ON_SWISS_BALL",
  },
  { id: "PLANK_WITH_KNEE_TO_ELBOW", name: "PLANK_WITH_KNEE_TO_ELBOW" },
  { id: "PLANK_WITH_LEG_LIFT", name: "PLANK_WITH_LEG_LIFT" },
  { id: "PLANK_WITH_OBLIQUE_CRUNCH", name: "PLANK_WITH_OBLIQUE_CRUNCH" },
  { id: "PLYOMETRIC_SIDE_PLANK", name: "PLYOMETRIC_SIDE_PLANK" },
  { id: "RING_PLANK_SPRAWLS", name: "RING_PLANK_SPRAWLS" },
  { id: "ROLLING_SIDE_PLANK", name: "ROLLING_SIDE_PLANK" },
  { id: "SIDE_KICK_PLANK", name: "SIDE_KICK_PLANK" },
  { id: "SIDE_PLANK", name: "SIDE_PLANK" },
  { id: "SIDE_PLANK_AND_ROW", name: "SIDE_PLANK_AND_ROW" },
  { id: "SIDE_PLANK_LIFT", name: "SIDE_PLANK_LIFT" },
  {
    id: "SIDE_PLANK_TO_PLANK_WITH_REACH_UNDER",
    name: "SIDE_PLANK_TO_PLANK_WITH_REACH_UNDER",
  },
  {
    id: "SIDE_PLANK_WITH_ELBOW_ON_BOSU_BALL",
    name: "SIDE_PLANK_WITH_ELBOW_ON_BOSU_BALL",
  },
  {
    id: "SIDE_PLANK_WITH_FEET_ON_BENCH",
    name: "SIDE_PLANK_WITH_FEET_ON_BENCH",
  },
  { id: "SIDE_PLANK_WITH_KNEE_CIRCLE", name: "SIDE_PLANK_WITH_KNEE_CIRCLE" },
  { id: "SIDE_PLANK_WITH_KNEE_TUCK", name: "SIDE_PLANK_WITH_KNEE_TUCK" },
  { id: "SIDE_PLANK_WITH_LEG_LIFT", name: "SIDE_PLANK_WITH_LEG_LIFT" },
  { id: "SIDE_PLANK_WITH_REACH_UNDER", name: "SIDE_PLANK_WITH_REACH_UNDER" },
  {
    id: "SINGLE_LEG_ELEVATED_FEET_PLANK",
    name: "SINGLE_LEG_ELEVATED_FEET_PLANK",
  },
  { id: "SINGLE_LEG_FLEX_AND_EXTEND", name: "SINGLE_LEG_FLEX_AND_EXTEND" },
  { id: "SINGLE_LEG_SIDE_PLANK", name: "SINGLE_LEG_SIDE_PLANK" },
  { id: "SPIDERMAN_PLANK", name: "SPIDERMAN_PLANK" },
  { id: "STRAIGHT_ARM_PLANK", name: "STRAIGHT_ARM_PLANK" },
  {
    id: "STRAIGHT_ARM_PLANK_WITH_SHOULDER_TOUCH",
    name: "STRAIGHT_ARM_PLANK_WITH_SHOULDER_TOUCH",
  },
  { id: "SWISS_BALL_PLANK", name: "SWISS_BALL_PLANK" },
  { id: "SWISS_BALL_PLANK_LEG_LIFT", name: "SWISS_BALL_PLANK_LEG_LIFT" },
  {
    id: "SWISS_BALL_PLANK_LEG_LIFT_AND_HOLD",
    name: "SWISS_BALL_PLANK_LEG_LIFT_AND_HOLD",
  },
  {
    id: "SWISS_BALL_PLANK_WITH_FEET_ON_BENCH",
    name: "SWISS_BALL_PLANK_WITH_FEET_ON_BENCH",
  },
  { id: "SWISS_BALL_PRONE_JACKKNIFE", name: "SWISS_BALL_PRONE_JACKKNIFE" },
  { id: "SWISS_BALL_SIDE_PLANK", name: "SWISS_BALL_SIDE_PLANK" },
  { id: "THREE_WAY_PLANK", name: "THREE_WAY_PLANK" },
  { id: "TOWEL_PLANK_AND_KNEE_IN", name: "TOWEL_PLANK_AND_KNEE_IN" },
  { id: "TURKISH_GET_UP_TO_SIDE_PLANK", name: "TURKISH_GET_UP_TO_SIDE_PLANK" },
  { id: "TWO_POINT_PLANK", name: "TWO_POINT_PLANK" },
  { id: "T_STABILIZATION", name: "T_STABILIZATION" },
  { id: "WEIGHTED_45_DEGREE_PLANK", name: "WEIGHTED_45_DEGREE_PLANK" },
  {
    id: "WEIGHTED_90_DEGREE_STATIC_HOLD",
    name: "WEIGHTED_90_DEGREE_STATIC_HOLD",
  },
  { id: "WEIGHTED_BEAR_CRAWL", name: "WEIGHTED_BEAR_CRAWL" },
  {
    id: "WEIGHTED_CROSS_BODY_MOUNTAIN_CLIMBER",
    name: "WEIGHTED_CROSS_BODY_MOUNTAIN_CLIMBER",
  },
  {
    id: "WEIGHTED_ELBOW_PLANK_PIKE_JACKS",
    name: "WEIGHTED_ELBOW_PLANK_PIKE_JACKS",
  },
  { id: "WEIGHTED_ELEVATED_FEET_PLANK", name: "WEIGHTED_ELEVATED_FEET_PLANK" },
  { id: "WEIGHTED_ELEVATOR_ABS", name: "WEIGHTED_ELEVATOR_ABS" },
  { id: "WEIGHTED_EXTENDED_PLANK", name: "WEIGHTED_EXTENDED_PLANK" },
  {
    id: "WEIGHTED_FULL_PLANK_PASSE_TWIST",
    name: "WEIGHTED_FULL_PLANK_PASSE_TWIST",
  },
  { id: "WEIGHTED_INCHING_ELBOW_PLANK", name: "WEIGHTED_INCHING_ELBOW_PLANK" },
  {
    id: "WEIGHTED_INCHWORM_TO_SIDE_PLANK",
    name: "WEIGHTED_INCHWORM_TO_SIDE_PLANK",
  },
  { id: "WEIGHTED_KNEELING_PLANK", name: "WEIGHTED_KNEELING_PLANK" },
  {
    id: "WEIGHTED_KNEELING_SIDE_PLANK_WITH_LEG_LIFT",
    name: "WEIGHTED_KNEELING_SIDE_PLANK_WITH_LEG_LIFT",
  },
  { id: "WEIGHTED_LATERAL_ROLL", name: "WEIGHTED_LATERAL_ROLL" },
  { id: "WEIGHTED_LYING_REVERSE_PLANK", name: "WEIGHTED_LYING_REVERSE_PLANK" },
  {
    id: "WEIGHTED_MEDICINE_BALL_MOUNTAIN_CLIMBER",
    name: "WEIGHTED_MEDICINE_BALL_MOUNTAIN_CLIMBER",
  },
  {
    id: "WEIGHTED_MODIFIED_MOUNTAIN_CLIMBER_AND_EXTENSION",
    name: "WEIGHTED_MODIFIED_MOUNTAIN_CLIMBER_AND_EXTENSION",
  },
  { id: "WEIGHTED_MOUNTAIN_CLIMBER", name: "WEIGHTED_MOUNTAIN_CLIMBER" },
  {
    id: "WEIGHTED_MOUNTAIN_CLIMBER_ON_SLIDING_DISCS",
    name: "WEIGHTED_MOUNTAIN_CLIMBER_ON_SLIDING_DISCS",
  },
  {
    id: "WEIGHTED_MOUNTAIN_CLIMBER_WITH_FEET_ON_BOSU_BALL",
    name: "WEIGHTED_MOUNTAIN_CLIMBER_WITH_FEET_ON_BOSU_BALL",
  },
  {
    id: "WEIGHTED_MOUNTAIN_CLIMBER_WITH_HANDS_ON_BENCH",
    name: "WEIGHTED_MOUNTAIN_CLIMBER_WITH_HANDS_ON_BENCH",
  },
  {
    id: "WEIGHTED_MOUNTAIN_CLIMBER_WITH_HANDS_ON_SWISS_BALL",
    name: "WEIGHTED_MOUNTAIN_CLIMBER_WITH_HANDS_ON_SWISS_BALL",
  },
  { id: "WEIGHTED_PLANK", name: "WEIGHTED_PLANK" },
  {
    id: "WEIGHTED_PLANK_JACKS_WITH_FEET_ON_SLIDING_DISCS",
    name: "WEIGHTED_PLANK_JACKS_WITH_FEET_ON_SLIDING_DISCS",
  },
  { id: "WEIGHTED_PLANK_KNEE_TWIST", name: "WEIGHTED_PLANK_KNEE_TWIST" },
  { id: "WEIGHTED_PLANK_PIKES", name: "WEIGHTED_PLANK_PIKES" },
  { id: "WEIGHTED_PLANK_PIKE_JUMPS", name: "WEIGHTED_PLANK_PIKE_JUMPS" },
  { id: "WEIGHTED_PLANK_TO_STAND_UP", name: "WEIGHTED_PLANK_TO_STAND_UP" },
  {
    id: "WEIGHTED_PLANK_WITH_ARM_RAISE",
    name: "WEIGHTED_PLANK_WITH_ARM_RAISE",
  },
  {
    id: "WEIGHTED_PLANK_WITH_KNEE_TO_ELBOW",
    name: "WEIGHTED_PLANK_WITH_KNEE_TO_ELBOW",
  },
  {
    id: "WEIGHTED_PLANK_WITH_OBLIQUE_CRUNCH",
    name: "WEIGHTED_PLANK_WITH_OBLIQUE_CRUNCH",
  },
  {
    id: "WEIGHTED_PLYOMETRIC_SIDE_PLANK",
    name: "WEIGHTED_PLYOMETRIC_SIDE_PLANK",
  },
  { id: "WEIGHTED_ROLLING_SIDE_PLANK", name: "WEIGHTED_ROLLING_SIDE_PLANK" },
  { id: "WEIGHTED_SIDE_KICK_PLANK", name: "WEIGHTED_SIDE_KICK_PLANK" },
  { id: "WEIGHTED_SIDE_PLANK", name: "WEIGHTED_SIDE_PLANK" },
  { id: "WEIGHTED_SIDE_PLANK_AND_ROW", name: "WEIGHTED_SIDE_PLANK_AND_ROW" },
  { id: "WEIGHTED_SIDE_PLANK_LIFT", name: "WEIGHTED_SIDE_PLANK_LIFT" },
  {
    id: "WEIGHTED_SIDE_PLANK_WITH_ELBOW_ON_BOSU_BALL",
    name: "WEIGHTED_SIDE_PLANK_WITH_ELBOW_ON_BOSU_BALL",
  },
  {
    id: "WEIGHTED_SIDE_PLANK_WITH_FEET_ON_BENCH",
    name: "WEIGHTED_SIDE_PLANK_WITH_FEET_ON_BENCH",
  },
  {
    id: "WEIGHTED_SIDE_PLANK_WITH_KNEE_CIRCLE",
    name: "WEIGHTED_SIDE_PLANK_WITH_KNEE_CIRCLE",
  },
  {
    id: "WEIGHTED_SIDE_PLANK_WITH_KNEE_TUCK",
    name: "WEIGHTED_SIDE_PLANK_WITH_KNEE_TUCK",
  },
  {
    id: "WEIGHTED_SIDE_PLANK_WITH_LEG_LIFT",
    name: "WEIGHTED_SIDE_PLANK_WITH_LEG_LIFT",
  },
  {
    id: "WEIGHTED_SIDE_PLANK_WITH_REACH_UNDER",
    name: "WEIGHTED_SIDE_PLANK_WITH_REACH_UNDER",
  },
  {
    id: "WEIGHTED_SINGLE_LEG_ELEVATED_FEET_PLANK",
    name: "WEIGHTED_SINGLE_LEG_ELEVATED_FEET_PLANK",
  },
  {
    id: "WEIGHTED_SINGLE_LEG_FLEX_AND_EXTEND",
    name: "WEIGHTED_SINGLE_LEG_FLEX_AND_EXTEND",
  },
  {
    id: "WEIGHTED_SINGLE_LEG_SIDE_PLANK",
    name: "WEIGHTED_SINGLE_LEG_SIDE_PLANK",
  },
  { id: "WEIGHTED_SPIDERMAN_PLANK", name: "WEIGHTED_SPIDERMAN_PLANK" },
  { id: "WEIGHTED_STRAIGHT_ARM_PLANK", name: "WEIGHTED_STRAIGHT_ARM_PLANK" },
  {
    id: "WEIGHTED_STRAIGHT_ARM_PLANK_WITH_SHOULDER_TOUCH",
    name: "WEIGHTED_STRAIGHT_ARM_PLANK_WITH_SHOULDER_TOUCH",
  },
  { id: "WEIGHTED_SWISS_BALL_PLANK", name: "WEIGHTED_SWISS_BALL_PLANK" },
  {
    id: "WEIGHTED_SWISS_BALL_PLANK_LEG_LIFT",
    name: "WEIGHTED_SWISS_BALL_PLANK_LEG_LIFT",
  },
  {
    id: "WEIGHTED_SWISS_BALL_PLANK_LEG_LIFT_AND_HOLD",
    name: "WEIGHTED_SWISS_BALL_PLANK_LEG_LIFT_AND_HOLD",
  },
  {
    id: "WEIGHTED_SWISS_BALL_PLANK_WITH_FEET_ON_BENCH",
    name: "WEIGHTED_SWISS_BALL_PLANK_WITH_FEET_ON_BENCH",
  },
  {
    id: "WEIGHTED_SWISS_BALL_PRONE_JACKKNIFE",
    name: "WEIGHTED_SWISS_BALL_PRONE_JACKKNIFE",
  },
  {
    id: "WEIGHTED_SWISS_BALL_SIDE_PLANK",
    name: "WEIGHTED_SWISS_BALL_SIDE_PLANK",
  },
  { id: "WEIGHTED_THREE_WAY_PLANK", name: "WEIGHTED_THREE_WAY_PLANK" },
  {
    id: "WEIGHTED_TOWEL_PLANK_AND_KNEE_IN",
    name: "WEIGHTED_TOWEL_PLANK_AND_KNEE_IN",
  },
  {
    id: "WEIGHTED_TURKISH_GET_UP_TO_SIDE_PLANK",
    name: "WEIGHTED_TURKISH_GET_UP_TO_SIDE_PLANK",
  },
  { id: "WEIGHTED_TWO_POINT_PLANK", name: "WEIGHTED_TWO_POINT_PLANK" },
  { id: "WEIGHTED_T_STABILIZATION", name: "WEIGHTED_T_STABILIZATION" },
  {
    id: "WEIGHTED_WIDE_STANCE_PLANK_WITH_DIAGONAL_ARM_LIFT",
    name: "WEIGHTED_WIDE_STANCE_PLANK_WITH_DIAGONAL_ARM_LIFT",
  },
  {
    id: "WEIGHTED_WIDE_STANCE_PLANK_WITH_DIAGONAL_LEG_LIFT",
    name: "WEIGHTED_WIDE_STANCE_PLANK_WITH_DIAGONAL_LEG_LIFT",
  },
  {
    id: "WEIGHTED_WIDE_STANCE_PLANK_WITH_LEG_LIFT",
    name: "WEIGHTED_WIDE_STANCE_PLANK_WITH_LEG_LIFT",
  },
  {
    id: "WIDE_STANCE_PLANK_WITH_DIAGONAL_ARM_LIFT",
    name: "WIDE_STANCE_PLANK_WITH_DIAGONAL_ARM_LIFT",
  },
  {
    id: "WIDE_STANCE_PLANK_WITH_DIAGONAL_LEG_LIFT",
    name: "WIDE_STANCE_PLANK_WITH_DIAGONAL_LEG_LIFT",
  },
  {
    id: "WIDE_STANCE_PLANK_WITH_LEG_LIFT",
    name: "WIDE_STANCE_PLANK_WITH_LEG_LIFT",
  },
  {
    id: "WIDE_STANCE_PLANK_WITH_OPPOSITE_ARM_AND_LEG_LIFT",
    name: "WIDE_STANCE_PLANK_WITH_OPPOSITE_ARM_AND_LEG_LIFT",
  },
  { id: "_45_DEGREE_PLANK", name: "_45_DEGREE_PLANK" },
  { id: "_90_DEGREE_STATIC_HOLD", name: "_90_DEGREE_STATIC_HOLD" },
];

const PLYO = [
  { id: "ALTERNATING_JUMP_LUNGE", name: "ALTERNATING_JUMP_LUNGE" },
  { id: "BARBELL_JUMP_SQUAT", name: "BARBELL_JUMP_SQUAT" },
  { id: "BODY_WEIGHT_JUMP_SQUAT", name: "BODY_WEIGHT_JUMP_SQUAT" },
  { id: "BOX_JUMP", name: "BOX_JUMP" },
  { id: "BOX_JUMP_OVERS", name: "BOX_JUMP_OVERS" },
  { id: "BOX_JUMP_OVERS_OVER_THE_BOX", name: "BOX_JUMP_OVERS_OVER_THE_BOX" },
  { id: "CROSS_KNEE_STRIKE", name: "CROSS_KNEE_STRIKE" },
  { id: "DEPTH_JUMP", name: "DEPTH_JUMP" },
  { id: "DUMBBELL_JUMP_SQUAT", name: "DUMBBELL_JUMP_SQUAT" },
  { id: "DUMBBELL_SPLIT_JUMP", name: "DUMBBELL_SPLIT_JUMP" },
  { id: "FRONT_KNEE_STRIKE", name: "FRONT_KNEE_STRIKE" },
  { id: "HIGH_BOX_JUMP", name: "HIGH_BOX_JUMP" },
  {
    id: "ISOMETRIC_EXPLOSIVE_BODY_WEIGHT_JUMP_SQUAT",
    name: "ISOMETRIC_EXPLOSIVE_BODY_WEIGHT_JUMP_SQUAT",
  },
  { id: "JUMP_SQUAT", name: "JUMP_SQUAT" },
  { id: "LATERAL_LEAP_AND_HOP", name: "LATERAL_LEAP_AND_HOP" },
  { id: "LATERAL_PLYO_SQUATS", name: "LATERAL_PLYO_SQUATS" },
  { id: "LATERAL_SLIDE", name: "LATERAL_SLIDE" },
  {
    id: "MEDICINE_BALL_OVERHEAD_THROWS",
    name: "MEDICINE_BALL_OVERHEAD_THROWS",
  },
  { id: "MEDICINE_BALL_SIDE_THROW", name: "MEDICINE_BALL_SIDE_THROW" },
  { id: "MEDICINE_BALL_SLAM", name: "MEDICINE_BALL_SLAM" },
  {
    id: "SIDE_TO_SIDE_MEDICINE_BALL_THROWS",
    name: "SIDE_TO_SIDE_MEDICINE_BALL_THROWS",
  },
  { id: "SIDE_TO_SIDE_SHUFFLE_JUMP", name: "SIDE_TO_SIDE_SHUFFLE_JUMP" },
  { id: "SQUAT_JUMPS_IN_AND_OUT", name: "SQUAT_JUMPS_IN_AND_OUT" },
  { id: "SQUAT_JUMP_ONTO_BOX", name: "SQUAT_JUMP_ONTO_BOX" },
  { id: "STAR_JUMP_SQUATS", name: "STAR_JUMP_SQUATS" },
  {
    id: "WEIGHTED_ALTERNATING_JUMP_LUNGE",
    name: "WEIGHTED_ALTERNATING_JUMP_LUNGE",
  },
  { id: "WEIGHTED_CROSS_KNEE_STRIKE", name: "WEIGHTED_CROSS_KNEE_STRIKE" },
  { id: "WEIGHTED_DEPTH_JUMP", name: "WEIGHTED_DEPTH_JUMP" },
  { id: "WEIGHTED_FRONT_KNEE_STRIKE", name: "WEIGHTED_FRONT_KNEE_STRIKE" },
  { id: "WEIGHTED_HIGH_BOX_JUMP", name: "WEIGHTED_HIGH_BOX_JUMP" },
  {
    id: "WEIGHTED_ISOMETRIC_EXPLOSIVE_JUMP_SQUAT",
    name: "WEIGHTED_ISOMETRIC_EXPLOSIVE_JUMP_SQUAT",
  },
  { id: "WEIGHTED_JUMP_SQUAT", name: "WEIGHTED_JUMP_SQUAT" },
  {
    id: "WEIGHTED_LATERAL_LEAP_AND_HOP",
    name: "WEIGHTED_LATERAL_LEAP_AND_HOP",
  },
  { id: "WEIGHTED_LATERAL_PLYO_SQUATS", name: "WEIGHTED_LATERAL_PLYO_SQUATS" },
  { id: "WEIGHTED_LATERAL_SLIDE", name: "WEIGHTED_LATERAL_SLIDE" },
  {
    id: "WEIGHTED_SIDE_TO_SIDE_SHUFFLE_JUMP",
    name: "WEIGHTED_SIDE_TO_SIDE_SHUFFLE_JUMP",
  },
  {
    id: "WEIGHTED_SQUAT_JUMPS_IN_AND_OUT",
    name: "WEIGHTED_SQUAT_JUMPS_IN_AND_OUT",
  },
  { id: "WEIGHTED_SQUAT_JUMP_ONTO_BOX", name: "WEIGHTED_SQUAT_JUMP_ONTO_BOX" },
];

const PULL_UP = [
  { id: "PULL_UP", name: "PULL_UP" },
  { id: "BANDED_PULL_UPS", name: "BANDED_PULL_UPS" },
  { id: "BAND_ASSISTED_CHIN_UP", name: "BAND_ASSISTED_CHIN_UP" },
  { id: "BAND_ASSISTED_PULL_UP", name: "BAND_ASSISTED_PULL_UP" },
  { id: "BURPEE_PULL_UP", name: "BURPEE_PULL_UP" },
  { id: "CHIN_UP", name: "CHIN_UP" },
  { id: "CLOSE_GRIP_CHIN_UP", name: "CLOSE_GRIP_CHIN_UP" },
  { id: "CLOSE_GRIP_LAT_PULLDOWN", name: "CLOSE_GRIP_LAT_PULLDOWN" },
  { id: "CROSSOVER_CHIN_UP", name: "CROSSOVER_CHIN_UP" },
  { id: "EZ_BAR_PULLOVER", name: "EZ_BAR_PULLOVER" },
  { id: "HANGING_HURDLE", name: "HANGING_HURDLE" },
  { id: "JUMPING_PULL_UPS", name: "JUMPING_PULL_UPS" },
  { id: "KIPPING_PULL_UP", name: "KIPPING_PULL_UP" },
  { id: "KNEELING_LAT_PULLDOWN", name: "KNEELING_LAT_PULLDOWN" },
  {
    id: "KNEELING_UNDERHAND_GRIP_LAT_PULLDOWN",
    name: "KNEELING_UNDERHAND_GRIP_LAT_PULLDOWN",
  },
  { id: "LAT_PULLDOWN", name: "LAT_PULLDOWN" },
  { id: "L_PULL_UP", name: "L_PULL_UP" },
  { id: "MIXED_GRIP_CHIN_UP", name: "MIXED_GRIP_CHIN_UP" },
  { id: "MIXED_GRIP_PULL_UP", name: "MIXED_GRIP_PULL_UP" },
  { id: "NEUTRAL_GRIP_CHIN_UP", name: "NEUTRAL_GRIP_CHIN_UP" },
  { id: "NEUTRAL_GRIP_PULL_UP", name: "NEUTRAL_GRIP_PULL_UP" },
  { id: "REVERSE_GRIP_PULLDOWN", name: "REVERSE_GRIP_PULLDOWN" },
  { id: "STANDING_CABLE_PULLOVER", name: "STANDING_CABLE_PULLOVER" },
  { id: "STRAIGHT_ARM_PULLDOWN", name: "STRAIGHT_ARM_PULLDOWN" },
  { id: "SUSPENDED_CHIN_UP", name: "SUSPENDED_CHIN_UP" },
  { id: "SWISS_BALL_EZ_BAR_PULLOVER", name: "SWISS_BALL_EZ_BAR_PULLOVER" },
  { id: "TOWEL_PULL_UP", name: "TOWEL_PULL_UP" },
  { id: "WEIGHTED_BURPEE_PULL_UP", name: "WEIGHTED_BURPEE_PULL_UP" },
  { id: "WEIGHTED_CHIN_UP", name: "WEIGHTED_CHIN_UP" },
  { id: "WEIGHTED_CLOSE_GRIP_CHIN_UP", name: "WEIGHTED_CLOSE_GRIP_CHIN_UP" },
  { id: "WEIGHTED_CROSSOVER_CHIN_UP", name: "WEIGHTED_CROSSOVER_CHIN_UP" },
  { id: "WEIGHTED_HANGING_HURDLE", name: "WEIGHTED_HANGING_HURDLE" },
  { id: "WEIGHTED_JUMPING_PULL_UPS", name: "WEIGHTED_JUMPING_PULL_UPS" },
  { id: "WEIGHTED_KIPPING_PULL_UP", name: "WEIGHTED_KIPPING_PULL_UP" },
  { id: "WEIGHTED_L_PULL_UP", name: "WEIGHTED_L_PULL_UP" },
  { id: "WEIGHTED_MIXED_GRIP_CHIN_UP", name: "WEIGHTED_MIXED_GRIP_CHIN_UP" },
  { id: "WEIGHTED_MIXED_GRIP_PULL_UP", name: "WEIGHTED_MIXED_GRIP_PULL_UP" },
  {
    id: "WEIGHTED_NEUTRAL_GRIP_CHIN_UP",
    name: "WEIGHTED_NEUTRAL_GRIP_CHIN_UP",
  },
  {
    id: "WEIGHTED_NEUTRAL_GRIP_PULL_UP",
    name: "WEIGHTED_NEUTRAL_GRIP_PULL_UP",
  },
  { id: "WEIGHTED_PULL_UP", name: "WEIGHTED_PULL_UP" },
  { id: "WEIGHTED_SUSPENDED_CHIN_UP", name: "WEIGHTED_SUSPENDED_CHIN_UP" },
  { id: "WEIGHTED_TOWEL_PULL_UP", name: "WEIGHTED_TOWEL_PULL_UP" },
  { id: "WEIGHTED_WIDE_GRIP_PULL_UP", name: "WEIGHTED_WIDE_GRIP_PULL_UP" },
  { id: "WIDE_GRIP_LAT_PULLDOWN", name: "WIDE_GRIP_LAT_PULLDOWN" },
  { id: "WIDE_GRIP_PULL_UP", name: "WIDE_GRIP_PULL_UP" },
  { id: "_30_DEGREE_LAT_PULLDOWN", name: "_30_DEGREE_LAT_PULLDOWN" },
];

const PUSH_UP = [
  { id: "PUSH_UP", name: "PUSH_UP" },
  {
    id: "ALTERNATING_HANDS_MEDICINE_BALL_PUSH_UP",
    name: "ALTERNATING_HANDS_MEDICINE_BALL_PUSH_UP",
  },
  {
    id: "ALTERNATING_STAGGERED_PUSH_UP",
    name: "ALTERNATING_STAGGERED_PUSH_UP",
  },
  { id: "BICEPS_PUSH_UP", name: "BICEPS_PUSH_UP" },
  { id: "BOSU_BALL_PUSH_UP", name: "BOSU_BALL_PUSH_UP" },
  { id: "CHEST_PRESS_WITH_BAND", name: "CHEST_PRESS_WITH_BAND" },
  { id: "CLAPPING_PUSH_UP", name: "CLAPPING_PUSH_UP" },
  {
    id: "CLOSE_GRIP_MEDICINE_BALL_PUSH_UP",
    name: "CLOSE_GRIP_MEDICINE_BALL_PUSH_UP",
  },
  { id: "CLOSE_HANDS_PUSH_UP", name: "CLOSE_HANDS_PUSH_UP" },
  { id: "DECLINE_PUSH_UP", name: "DECLINE_PUSH_UP" },
  { id: "DIAMOND_PUSH_UP", name: "DIAMOND_PUSH_UP" },
  { id: "DYNAMIC_PUSH_UP", name: "DYNAMIC_PUSH_UP" },
  { id: "EXPLOSIVE_CROSSOVER_PUSH_UP", name: "EXPLOSIVE_CROSSOVER_PUSH_UP" },
  { id: "EXPLOSIVE_PUSH_UP", name: "EXPLOSIVE_PUSH_UP" },
  {
    id: "FEET_ELEVATED_SIDE_TO_SIDE_PUSH_UP",
    name: "FEET_ELEVATED_SIDE_TO_SIDE_PUSH_UP",
  },
  { id: "HANDSTAND_PUSH_UP", name: "HANDSTAND_PUSH_UP" },
  { id: "HAND_RELEASE_PUSH_UP", name: "HAND_RELEASE_PUSH_UP" },
  { id: "HINDU_PUSH_UP", name: "HINDU_PUSH_UP" },
  { id: "INCLINE_PUSH_UP", name: "INCLINE_PUSH_UP" },
  { id: "ISOMETRIC_EXPLOSIVE_PUSH_UP", name: "ISOMETRIC_EXPLOSIVE_PUSH_UP" },
  { id: "JUDO_PUSH_UP", name: "JUDO_PUSH_UP" },
  { id: "KIPPING_HANDSTAND_PUSH_UP", name: "KIPPING_HANDSTAND_PUSH_UP" },
  {
    id: "KIPPING_PARALLETTE_HANDSTAND_PUSH_UP",
    name: "KIPPING_PARALLETTE_HANDSTAND_PUSH_UP",
  },
  { id: "KNEELING_PUSH_UP", name: "KNEELING_PUSH_UP" },
  { id: "MEDICINE_BALL_CHEST_PASS", name: "MEDICINE_BALL_CHEST_PASS" },
  { id: "MEDICINE_BALL_PUSH_UP", name: "MEDICINE_BALL_PUSH_UP" },
  { id: "ONE_ARM_PUSH_UP", name: "ONE_ARM_PUSH_UP" },
  { id: "PARALLETTE_HANDSTAND_PUSH_UP", name: "PARALLETTE_HANDSTAND_PUSH_UP" },
  { id: "PIKE_PUSH_UP", name: "PIKE_PUSH_UP" },
  { id: "PUSH_UP_AND_ROW", name: "PUSH_UP_AND_ROW" },
  { id: "PUSH_UP_PLUS", name: "PUSH_UP_PLUS" },
  {
    id: "PUSH_UP_WITH_FEET_ON_SWISS_BALL",
    name: "PUSH_UP_WITH_FEET_ON_SWISS_BALL",
  },
  {
    id: "PUSH_UP_WITH_ONE_HAND_ON_MEDICINE_BALL",
    name: "PUSH_UP_WITH_ONE_HAND_ON_MEDICINE_BALL",
  },
  { id: "RING_HANDSTAND_PUSH_UP", name: "RING_HANDSTAND_PUSH_UP" },
  { id: "RING_PUSH_UP", name: "RING_PUSH_UP" },
  { id: "SHOULDER_PUSH_UP", name: "SHOULDER_PUSH_UP" },
  { id: "SHOULDER_TAPPING_PUSH_UP", name: "SHOULDER_TAPPING_PUSH_UP" },
  {
    id: "SINGLE_ARM_MEDICINE_BALL_PUSH_UP",
    name: "SINGLE_ARM_MEDICINE_BALL_PUSH_UP",
  },
  { id: "SPIDERMAN_PUSH_UP", name: "SPIDERMAN_PUSH_UP" },
  { id: "STACKED_FEET_PUSH_UP", name: "STACKED_FEET_PUSH_UP" },
  { id: "STAGGERED_HANDS_PUSH_UP", name: "STAGGERED_HANDS_PUSH_UP" },
  { id: "SUSPENDED_PUSH_UP", name: "SUSPENDED_PUSH_UP" },
  { id: "SWISS_BALL_PUSH_UP", name: "SWISS_BALL_PUSH_UP" },
  { id: "SWISS_BALL_PUSH_UP_PLUS", name: "SWISS_BALL_PUSH_UP_PLUS" },
  { id: "TRIPLE_STOP_PUSH_UP", name: "TRIPLE_STOP_PUSH_UP" },
  { id: "T_PUSH_UP", name: "T_PUSH_UP" },
  {
    id: "WEIGHTED_ALTERNATING_HANDS_MEDICINE_BALL_PUSH_UP",
    name: "WEIGHTED_ALTERNATING_HANDS_MEDICINE_BALL_PUSH_UP",
  },
  {
    id: "WEIGHTED_ALTERNATING_STAGGERED_PUSH_UP",
    name: "WEIGHTED_ALTERNATING_STAGGERED_PUSH_UP",
  },
  { id: "WEIGHTED_BICEPS_PUSH_UP", name: "WEIGHTED_BICEPS_PUSH_UP" },
  { id: "WEIGHTED_BOSU_BALL_PUSH_UP", name: "WEIGHTED_BOSU_BALL_PUSH_UP" },
  { id: "WEIGHTED_CLAPPING_PUSH_UP", name: "WEIGHTED_CLAPPING_PUSH_UP" },
  {
    id: "WEIGHTED_CLOSE_GRIP_MEDICINE_BALL_PUSH_UP",
    name: "WEIGHTED_CLOSE_GRIP_MEDICINE_BALL_PUSH_UP",
  },
  { id: "WEIGHTED_CLOSE_HANDS_PUSH_UP", name: "WEIGHTED_CLOSE_HANDS_PUSH_UP" },
  { id: "WEIGHTED_DECLINE_PUSH_UP", name: "WEIGHTED_DECLINE_PUSH_UP" },
  { id: "WEIGHTED_DIAMOND_PUSH_UP", name: "WEIGHTED_DIAMOND_PUSH_UP" },
  {
    id: "WEIGHTED_EXPLOSIVE_CROSSOVER_PUSH_UP",
    name: "WEIGHTED_EXPLOSIVE_CROSSOVER_PUSH_UP",
  },
  { id: "WEIGHTED_EXPLOSIVE_PUSH_UP", name: "WEIGHTED_EXPLOSIVE_PUSH_UP" },
  {
    id: "WEIGHTED_FEET_ELEVATED_SIDE_TO_SIDE_PUSH_UP",
    name: "WEIGHTED_FEET_ELEVATED_SIDE_TO_SIDE_PUSH_UP",
  },
  { id: "WEIGHTED_HANDSTAND_PUSH_UP", name: "WEIGHTED_HANDSTAND_PUSH_UP" },
  {
    id: "WEIGHTED_HAND_RELEASE_PUSH_UP",
    name: "WEIGHTED_HAND_RELEASE_PUSH_UP",
  },
  { id: "WEIGHTED_HINDU_PUSH_UP", name: "WEIGHTED_HINDU_PUSH_UP" },
  { id: "WEIGHTED_INCLINE_PUSH_UP", name: "WEIGHTED_INCLINE_PUSH_UP" },
  {
    id: "WEIGHTED_ISOMETRIC_EXPLOSIVE_PUSH_UP",
    name: "WEIGHTED_ISOMETRIC_EXPLOSIVE_PUSH_UP",
  },
  { id: "WEIGHTED_JUDO_PUSH_UP", name: "WEIGHTED_JUDO_PUSH_UP" },
  { id: "WEIGHTED_KNEELING_PUSH_UP", name: "WEIGHTED_KNEELING_PUSH_UP" },
  {
    id: "WEIGHTED_MEDICINE_BALL_PUSH_UP",
    name: "WEIGHTED_MEDICINE_BALL_PUSH_UP",
  },
  { id: "WEIGHTED_ONE_ARM_PUSH_UP", name: "WEIGHTED_ONE_ARM_PUSH_UP" },
  {
    id: "WEIGHTED_PARALLETTE_HANDSTAND_PUSH_UP",
    name: "WEIGHTED_PARALLETTE_HANDSTAND_PUSH_UP",
  },
  { id: "WEIGHTED_PIKE_PUSH_UP", name: "WEIGHTED_PIKE_PUSH_UP" },
  { id: "WEIGHTED_PUSH_UP", name: "WEIGHTED_PUSH_UP" },
  { id: "WEIGHTED_PUSH_UP_AND_ROW", name: "WEIGHTED_PUSH_UP_AND_ROW" },
  { id: "WEIGHTED_PUSH_UP_PLUS", name: "WEIGHTED_PUSH_UP_PLUS" },
  {
    id: "WEIGHTED_PUSH_UP_WITH_FEET_ON_SWISS_BALL",
    name: "WEIGHTED_PUSH_UP_WITH_FEET_ON_SWISS_BALL",
  },
  {
    id: "WEIGHTED_PUSH_UP_WITH_ONE_HAND_ON_MEDICINE_BALL",
    name: "WEIGHTED_PUSH_UP_WITH_ONE_HAND_ON_MEDICINE_BALL",
  },
  {
    id: "WEIGHTED_RING_HANDSTAND_PUSH_UP",
    name: "WEIGHTED_RING_HANDSTAND_PUSH_UP",
  },
  { id: "WEIGHTED_RING_PUSH_UP", name: "WEIGHTED_RING_PUSH_UP" },
  { id: "WEIGHTED_SHOULDER_PUSH_UP", name: "WEIGHTED_SHOULDER_PUSH_UP" },
  {
    id: "WEIGHTED_SINGLE_ARM_MEDICINE_BALL_PUSH_UP",
    name: "WEIGHTED_SINGLE_ARM_MEDICINE_BALL_PUSH_UP",
  },
  { id: "WEIGHTED_SPIDERMAN_PUSH_UP", name: "WEIGHTED_SPIDERMAN_PUSH_UP" },
  {
    id: "WEIGHTED_STACKED_FEET_PUSH_UP",
    name: "WEIGHTED_STACKED_FEET_PUSH_UP",
  },
  {
    id: "WEIGHTED_STAGGERED_HANDS_PUSH_UP",
    name: "WEIGHTED_STAGGERED_HANDS_PUSH_UP",
  },
  { id: "WEIGHTED_SUSPENDED_PUSH_UP", name: "WEIGHTED_SUSPENDED_PUSH_UP" },
  { id: "WEIGHTED_SWISS_BALL_PUSH_UP", name: "WEIGHTED_SWISS_BALL_PUSH_UP" },
  {
    id: "WEIGHTED_SWISS_BALL_PUSH_UP_PLUS",
    name: "WEIGHTED_SWISS_BALL_PUSH_UP_PLUS",
  },
  { id: "WEIGHTED_TRIPLE_STOP_PUSH_UP", name: "WEIGHTED_TRIPLE_STOP_PUSH_UP" },
  { id: "WEIGHTED_T_PUSH_UP", name: "WEIGHTED_T_PUSH_UP" },
  { id: "WEIGHTED_WIDE_HANDS_PUSH_UP", name: "WEIGHTED_WIDE_HANDS_PUSH_UP" },
  { id: "WIDE_GRIP_PUSH_UP", name: "WIDE_GRIP_PUSH_UP" },
  { id: "WIDE_HANDS_PUSH_UP", name: "WIDE_HANDS_PUSH_UP" },
];

const ROW = [
  { id: "ROW", name: "ROW" },
  { id: "ALTERNATING_DUMBBELL_ROW", name: "ALTERNATING_DUMBBELL_ROW" },
  { id: "BANDED_FACE_PULLS", name: "BANDED_FACE_PULLS" },
  { id: "BARBELL_ROW", name: "BARBELL_ROW" },
  {
    id: "BARBELL_STRAIGHT_LEG_DEADLIFT_TO_ROW",
    name: "BARBELL_STRAIGHT_LEG_DEADLIFT_TO_ROW",
  },
  { id: "BENT_OVER_ROW_WITH_BARBELL", name: "BENT_OVER_ROW_WITH_BARBELL" },
  { id: "BENT_OVER_ROW_WITH_DUMBELL", name: "BENT_OVER_ROW_WITH_DUMBELL" },
  { id: "CABLE_ROW_STANDING", name: "CABLE_ROW_STANDING" },
  { id: "CHEST_SUPPORTED_DUMBBELL_ROW", name: "CHEST_SUPPORTED_DUMBBELL_ROW" },
  { id: "DECLINE_RING_ROW", name: "DECLINE_RING_ROW" },
  { id: "DUMBBELL_ROW", name: "DUMBBELL_ROW" },
  { id: "ELEVATED_FEET_INVERTED_ROW", name: "ELEVATED_FEET_INVERTED_ROW" },
  { id: "ELEVATED_RING_ROW", name: "ELEVATED_RING_ROW" },
  { id: "FACE_PULL", name: "FACE_PULL" },
  {
    id: "FACE_PULL_WITH_EXTERNAL_ROTATION",
    name: "FACE_PULL_WITH_EXTERNAL_ROTATION",
  },
  { id: "INDOOR_ROW", name: "INDOOR_ROW" },
  { id: "INVERTED_ROW", name: "INVERTED_ROW" },
  {
    id: "INVERTED_ROW_WITH_FEET_ON_SWISS_BALL",
    name: "INVERTED_ROW_WITH_FEET_ON_SWISS_BALL",
  },
  { id: "KETTLEBELL_ROW", name: "KETTLEBELL_ROW" },
  { id: "MODIFIED_INVERTED_ROW", name: "MODIFIED_INVERTED_ROW" },
  {
    id: "NEUTRAL_GRIP_ALTERNATING_DUMBBELL_ROW",
    name: "NEUTRAL_GRIP_ALTERNATING_DUMBBELL_ROW",
  },
  { id: "ONE_ARM_BENT_OVER_ROW", name: "ONE_ARM_BENT_OVER_ROW" },
  { id: "ONE_LEGGED_DUMBBELL_ROW", name: "ONE_LEGGED_DUMBBELL_ROW" },
  { id: "RENEGADE_ROW", name: "RENEGADE_ROW" },
  { id: "REVERSE_GRIP_BARBELL_ROW", name: "REVERSE_GRIP_BARBELL_ROW" },
  { id: "RING_ROW", name: "RING_ROW" },
  { id: "ROPE_HANDLE_CABLE_ROW", name: "ROPE_HANDLE_CABLE_ROW" },
  { id: "SEATED_CABLE_ROW", name: "SEATED_CABLE_ROW" },
  { id: "SEATED_DUMBBELL_ROW", name: "SEATED_DUMBBELL_ROW" },
  {
    id: "SEATED_UNDERHAND_GRIP_CABLE_ROW",
    name: "SEATED_UNDERHAND_GRIP_CABLE_ROW",
  },
  { id: "SINGLE_ARM_CABLE_ROW", name: "SINGLE_ARM_CABLE_ROW" },
  {
    id: "SINGLE_ARM_CABLE_ROW_AND_ROTATION",
    name: "SINGLE_ARM_CABLE_ROW_AND_ROTATION",
  },
  { id: "SINGLE_ARM_INVERTED_ROW", name: "SINGLE_ARM_INVERTED_ROW" },
  {
    id: "SINGLE_ARM_NEUTRAL_GRIP_DUMBBELL_ROW",
    name: "SINGLE_ARM_NEUTRAL_GRIP_DUMBBELL_ROW",
  },
  {
    id: "SINGLE_ARM_NEUTRAL_GRIP_DUMBBELL_ROW_AND_ROTATION",
    name: "SINGLE_ARM_NEUTRAL_GRIP_DUMBBELL_ROW_AND_ROTATION",
  },
  { id: "SUSPENDED_INVERTED_ROW", name: "SUSPENDED_INVERTED_ROW" },
  { id: "TOWEL_GRIP_INVERTED_ROW", name: "TOWEL_GRIP_INVERTED_ROW" },
  { id: "TRX_INVERTED_ROW", name: "TRX_INVERTED_ROW" },
  { id: "T_BAR_ROW", name: "T_BAR_ROW" },
  { id: "UNDERHAND_GRIP_CABLE_ROW", name: "UNDERHAND_GRIP_CABLE_ROW" },
  { id: "V_GRIP_CABLE_ROW", name: "V_GRIP_CABLE_ROW" },
  {
    id: "WEIGHTED_ELEVATED_FEET_INVERTED_ROW",
    name: "WEIGHTED_ELEVATED_FEET_INVERTED_ROW",
  },
  { id: "WEIGHTED_INVERTED_ROW", name: "WEIGHTED_INVERTED_ROW" },
  {
    id: "WEIGHTED_INVERTED_ROW_WITH_FEET_ON_SWISS_BALL",
    name: "WEIGHTED_INVERTED_ROW_WITH_FEET_ON_SWISS_BALL",
  },
  {
    id: "WEIGHTED_MODIFIED_INVERTED_ROW",
    name: "WEIGHTED_MODIFIED_INVERTED_ROW",
  },
  { id: "WEIGHTED_ROW", name: "WEIGHTED_ROW" },
  {
    id: "WEIGHTED_SINGLE_ARM_INVERTED_ROW",
    name: "WEIGHTED_SINGLE_ARM_INVERTED_ROW",
  },
  {
    id: "WEIGHTED_SUSPENDED_INVERTED_ROW",
    name: "WEIGHTED_SUSPENDED_INVERTED_ROW",
  },
  {
    id: "WEIGHTED_TOWEL_GRIP_INVERTED_ROW",
    name: "WEIGHTED_TOWEL_GRIP_INVERTED_ROW",
  },
  { id: "WEIGHTED_TRX_INVERTED_ROW", name: "WEIGHTED_TRX_INVERTED_ROW" },
  { id: "WIDE_GRIP_SEATED_CABLE_ROW", name: "WIDE_GRIP_SEATED_CABLE_ROW" },
];

const RUN_INDOOR = [
  { id: "INDOOR_TRACK_RUN", name: "INDOOR_TRACK_RUN" },
  { id: "TREADMILL", name: "TREADMILL" },
];

const RUN = [
  { id: "RUN", name: "RUN" },
  { id: "JOG", name: "JOG" },
  { id: "RUN_OR_WALK", name: "RUN_OR_WALK" },
  { id: "SPRINT", name: "SPRINT" },
  { id: "WALK", name: "WALK" },
];

const SANDBAG = [
  { id: "AROUND_THE_WORLD", name: "AROUND_THE_WORLD" },
  { id: "BACK_SQUAT", name: "BACK_SQUAT" },
  { id: "BEAR_CRAWL_PULL_THROUGH", name: "BEAR_CRAWL_PULL_THROUGH" },
  { id: "BEAR_HUG_SQUAT", name: "BEAR_HUG_SQUAT" },
  { id: "CLEAN", name: "CLEAN" },
  { id: "CLEAN_AND_PRESS", name: "CLEAN_AND_PRESS" },
  { id: "CURL", name: "CURL" },
  { id: "FRONT_CARRY", name: "FRONT_CARRY" },
  { id: "FRONT_SQUAT", name: "FRONT_SQUAT" },
  { id: "LUNGE", name: "LUNGE" },
  { id: "OVERHEAD_PRESS", name: "OVERHEAD_PRESS" },
  { id: "PLANK_PULL_THROUGH", name: "PLANK_PULL_THROUGH" },
  { id: "ROTATIONAL_LUNGE", name: "ROTATIONAL_LUNGE" },
  { id: "ROW", name: "ROW" },
  { id: "RUSSIAN_TWIST", name: "RUSSIAN_TWIST" },
  { id: "SHOULDERING", name: "SHOULDERING" },
  { id: "SHOVELING", name: "SHOVELING" },
  { id: "SIDE_LUNGE", name: "SIDE_LUNGE" },
  { id: "SPRINT", name: "SPRINT" },
  { id: "ZERCHER_SQUAT", name: "ZERCHER_SQUAT" },
];

const SHOULDER_PRESS = [
  {
    id: "ALTERNATING_DUMBBELL_SHOULDER_PRESS",
    name: "ALTERNATING_DUMBBELL_SHOULDER_PRESS",
  },
  { id: "ARNOLD_PRESS", name: "ARNOLD_PRESS" },
  {
    id: "BARBELL_FRONT_SQUAT_TO_PUSH_PRESS",
    name: "BARBELL_FRONT_SQUAT_TO_PUSH_PRESS",
  },
  { id: "BARBELL_PUSH_PRESS", name: "BARBELL_PUSH_PRESS" },
  { id: "BARBELL_SHOULDER_PRESS", name: "BARBELL_SHOULDER_PRESS" },
  { id: "DEAD_CURL_PRESS", name: "DEAD_CURL_PRESS" },
  {
    id: "DUMBBELL_ALTERNATING_SHOULDER_PRESS_AND_TWIST",
    name: "DUMBBELL_ALTERNATING_SHOULDER_PRESS_AND_TWIST",
  },
  { id: "DUMBBELL_FRONT_RAISE", name: "DUMBBELL_FRONT_RAISE" },
  {
    id: "DUMBBELL_HAMMER_CURL_TO_LUNGE_TO_PRESS",
    name: "DUMBBELL_HAMMER_CURL_TO_LUNGE_TO_PRESS",
  },
  { id: "DUMBBELL_PUSH_PRESS", name: "DUMBBELL_PUSH_PRESS" },
  { id: "DUMBBELL_SHOULDER_PRESS", name: "DUMBBELL_SHOULDER_PRESS" },
  {
    id: "FLOOR_INVERTED_SHOULDER_PRESS",
    name: "FLOOR_INVERTED_SHOULDER_PRESS",
  },
  { id: "INVERTED_SHOULDER_PRESS", name: "INVERTED_SHOULDER_PRESS" },
  { id: "MILITARY_PRESS", name: "MILITARY_PRESS" },
  { id: "ONE_ARM_PUSH_PRESS", name: "ONE_ARM_PUSH_PRESS" },
  { id: "OVERHEAD_BARBELL_PRESS", name: "OVERHEAD_BARBELL_PRESS" },
  { id: "OVERHEAD_DUMBBELL_PRESS", name: "OVERHEAD_DUMBBELL_PRESS" },
  {
    id: "SEATED_BARBELL_SHOULDER_PRESS",
    name: "SEATED_BARBELL_SHOULDER_PRESS",
  },
  {
    id: "SEATED_DUMBBELL_SHOULDER_PRESS",
    name: "SEATED_DUMBBELL_SHOULDER_PRESS",
  },
  {
    id: "SINGLE_ARM_DUMBBELL_SHOULDER_PRESS",
    name: "SINGLE_ARM_DUMBBELL_SHOULDER_PRESS",
  },
  { id: "SINGLE_ARM_STEP_UP_AND_PRESS", name: "SINGLE_ARM_STEP_UP_AND_PRESS" },
  { id: "SMITH_MACHINE_OVERHEAD_PRESS", name: "SMITH_MACHINE_OVERHEAD_PRESS" },
  {
    id: "SPLIT_STANCE_HAMMER_CURL_TO_PRESS",
    name: "SPLIT_STANCE_HAMMER_CURL_TO_PRESS",
  },
  { id: "STRICT_PRESS", name: "STRICT_PRESS" },
  {
    id: "SWISS_BALL_DUMBBELL_SHOULDER_PRESS",
    name: "SWISS_BALL_DUMBBELL_SHOULDER_PRESS",
  },
  {
    id: "WEIGHTED_FLOOR_INVERTED_SHOULDER_PRESS",
    name: "WEIGHTED_FLOOR_INVERTED_SHOULDER_PRESS",
  },
  {
    id: "WEIGHTED_INVERTED_SHOULDER_PRESS",
    name: "WEIGHTED_INVERTED_SHOULDER_PRESS",
  },
  { id: "WEIGHT_PLATE_FRONT_RAISE", name: "WEIGHT_PLATE_FRONT_RAISE" },
];

const SHOULDER_STABILITY = [
  { id: "BAND_EXTERNAL_ROTATION", name: "BAND_EXTERNAL_ROTATION" },
  { id: "BAND_INTERNAL_ROTATION", name: "BAND_INTERNAL_ROTATION" },
  {
    id: "BENT_ARM_LATERAL_RAISE_AND_EXTERNAL_ROTATION",
    name: "BENT_ARM_LATERAL_RAISE_AND_EXTERNAL_ROTATION",
  },
  { id: "CABLE_EXTERNAL_ROTATION", name: "CABLE_EXTERNAL_ROTATION" },
  { id: "CABLE_INTERNAL_ROTATION", name: "CABLE_INTERNAL_ROTATION" },
  {
    id: "DUMBBELL_FACE_PULL_WITH_EXTERNAL_ROTATION",
    name: "DUMBBELL_FACE_PULL_WITH_EXTERNAL_ROTATION",
  },
  { id: "FLOOR_I_RAISE", name: "FLOOR_I_RAISE" },
  { id: "FLOOR_T_RAISE", name: "FLOOR_T_RAISE" },
  { id: "FLOOR_Y_RAISE", name: "FLOOR_Y_RAISE" },
  { id: "INCLINE_I_RAISE", name: "INCLINE_I_RAISE" },
  { id: "INCLINE_L_RAISE", name: "INCLINE_L_RAISE" },
  { id: "INCLINE_T_RAISE", name: "INCLINE_T_RAISE" },
  { id: "INCLINE_W_RAISE", name: "INCLINE_W_RAISE" },
  { id: "INCLINE_Y_RAISE", name: "INCLINE_Y_RAISE" },
  { id: "LYING_EXTERNAL_ROTATION", name: "LYING_EXTERNAL_ROTATION" },
  { id: "LYING_INTERNAL_ROTATION", name: "LYING_INTERNAL_ROTATION" },
  {
    id: "SEATED_DUMBBELL_EXTERNAL_ROTATION",
    name: "SEATED_DUMBBELL_EXTERNAL_ROTATION",
  },
  {
    id: "SEATED_DUMBBELL_INTERNAL_ROTATION",
    name: "SEATED_DUMBBELL_INTERNAL_ROTATION",
  },
  { id: "STANDING_L_RAISE", name: "STANDING_L_RAISE" },
  { id: "SWISS_BALL_I_RAISE", name: "SWISS_BALL_I_RAISE" },
  { id: "SWISS_BALL_T_RAISE", name: "SWISS_BALL_T_RAISE" },
  { id: "SWISS_BALL_W_RAISE", name: "SWISS_BALL_W_RAISE" },
  { id: "SWISS_BALL_Y_RAISE", name: "SWISS_BALL_Y_RAISE" },
  { id: "WEIGHTED_FLOOR_I_RAISE", name: "WEIGHTED_FLOOR_I_RAISE" },
  { id: "WEIGHTED_FLOOR_T_RAISE", name: "WEIGHTED_FLOOR_T_RAISE" },
  { id: "WEIGHTED_FLOOR_Y_RAISE", name: "WEIGHTED_FLOOR_Y_RAISE" },
  { id: "WEIGHTED_INCLINE_I_RAISE", name: "WEIGHTED_INCLINE_I_RAISE" },
  { id: "WEIGHTED_INCLINE_L_RAISE", name: "WEIGHTED_INCLINE_L_RAISE" },
  { id: "WEIGHTED_INCLINE_T_RAISE", name: "WEIGHTED_INCLINE_T_RAISE" },
  { id: "WEIGHTED_INCLINE_W_RAISE", name: "WEIGHTED_INCLINE_W_RAISE" },
  { id: "WEIGHTED_INCLINE_Y_RAISE", name: "WEIGHTED_INCLINE_Y_RAISE" },
  { id: "WEIGHTED_SWISS_BALL_I_RAISE", name: "WEIGHTED_SWISS_BALL_I_RAISE" },
  { id: "WEIGHTED_SWISS_BALL_T_RAISE", name: "WEIGHTED_SWISS_BALL_T_RAISE" },
  { id: "WEIGHTED_SWISS_BALL_W_RAISE", name: "WEIGHTED_SWISS_BALL_W_RAISE" },
  { id: "WEIGHTED_SWISS_BALL_Y_RAISE", name: "WEIGHTED_SWISS_BALL_Y_RAISE" },
  {
    id: "_90_DEGREE_CABLE_EXTERNAL_ROTATION",
    name: "_90_DEGREE_CABLE_EXTERNAL_ROTATION",
  },
];

const SHRUG = [
  { id: "BARBELL_JUMP_SHRUG", name: "BARBELL_JUMP_SHRUG" },
  { id: "BARBELL_SHRUG", name: "BARBELL_SHRUG" },
  { id: "BARBELL_UPRIGHT_ROW", name: "BARBELL_UPRIGHT_ROW" },
  { id: "BEHIND_THE_BACK_SHRUG", name: "BEHIND_THE_BACK_SHRUG" },
  {
    id: "BEHIND_THE_BACK_SMITH_MACHINE_SHRUG",
    name: "BEHIND_THE_BACK_SMITH_MACHINE_SHRUG",
  },
  { id: "DUMBBELL_JUMP_SHRUG", name: "DUMBBELL_JUMP_SHRUG" },
  { id: "DUMBBELL_SHRUG", name: "DUMBBELL_SHRUG" },
  { id: "DUMBBELL_UPRIGHT_ROW", name: "DUMBBELL_UPRIGHT_ROW" },
  { id: "INCLINE_DUMBBELL_SHRUG", name: "INCLINE_DUMBBELL_SHRUG" },
  { id: "OVERHEAD_BARBELL_SHRUG", name: "OVERHEAD_BARBELL_SHRUG" },
  { id: "OVERHEAD_DUMBBELL_SHRUG", name: "OVERHEAD_DUMBBELL_SHRUG" },
  { id: "SCAPTION_AND_SHRUG", name: "SCAPTION_AND_SHRUG" },
  { id: "SCAPULAR_RETRACTION", name: "SCAPULAR_RETRACTION" },
  { id: "SERRATUS_CHAIR_SHRUG", name: "SERRATUS_CHAIR_SHRUG" },
  { id: "SERRATUS_SHRUG", name: "SERRATUS_SHRUG" },
  {
    id: "WEIGHTED_SERRATUS_CHAIR_SHRUG",
    name: "WEIGHTED_SERRATUS_CHAIR_SHRUG",
  },
  { id: "WEIGHTED_SERRATUS_SHRUG", name: "WEIGHTED_SERRATUS_SHRUG" },
  { id: "WIDE_GRIP_BARBELL_SHRUG", name: "WIDE_GRIP_BARBELL_SHRUG" },
  { id: "WIDE_GRIP_JUMP_SHRUG", name: "WIDE_GRIP_JUMP_SHRUG" },
];

const SIT_UP = [
  { id: "SIT_UP", name: "SIT_UP" },
  { id: "ALTERNATING_SIT_UP", name: "ALTERNATING_SIT_UP" },
  { id: "BENT_KNEE_V_UP", name: "BENT_KNEE_V_UP" },
  { id: "BUTTERFLY_SIT_UP", name: "BUTTERFLY_SIT_UP" },
  { id: "CROSSED_ARMS_SIT_UP", name: "CROSSED_ARMS_SIT_UP" },
  { id: "CROSS_PUNCH_ROLL_UP", name: "CROSS_PUNCH_ROLL_UP" },
  { id: "GET_UP_SIT_UP", name: "GET_UP_SIT_UP" },
  { id: "GHD_SIT_UPS", name: "GHD_SIT_UPS" },
  { id: "HOVERING_SIT_UP", name: "HOVERING_SIT_UP" },
  { id: "KETTLEBELL_SIT_UP", name: "KETTLEBELL_SIT_UP" },
  {
    id: "MEDICINE_BALL_ALTERNATING_V_UP",
    name: "MEDICINE_BALL_ALTERNATING_V_UP",
  },
  { id: "MEDICINE_BALL_SIT_UP", name: "MEDICINE_BALL_SIT_UP" },
  { id: "MEDICINE_BALL_V_UP", name: "MEDICINE_BALL_V_UP" },
  { id: "MODIFIED_SIT_UP", name: "MODIFIED_SIT_UP" },
  { id: "NEGATIVE_SIT_UP", name: "NEGATIVE_SIT_UP" },
  { id: "ONE_ARM_FULL_SIT_UP", name: "ONE_ARM_FULL_SIT_UP" },
  { id: "RECLINING_CIRCLE", name: "RECLINING_CIRCLE" },
  { id: "REVERSE_CURL_UP", name: "REVERSE_CURL_UP" },
  { id: "RUSSIAN_TWIST_ON_SWISS_BALL", name: "RUSSIAN_TWIST_ON_SWISS_BALL" },
  {
    id: "SINGLE_LEG_SWISS_BALL_JACKKNIFE",
    name: "SINGLE_LEG_SWISS_BALL_JACKKNIFE",
  },
  { id: "SIT_UP_TURKISH_GET_UP", name: "SIT_UP_TURKISH_GET_UP" },
  { id: "THE_TEASER", name: "THE_TEASER" },
  { id: "THE_TEASER_WEIGHTED", name: "THE_TEASER_WEIGHTED" },
  { id: "THREE_PART_ROLL_DOWN", name: "THREE_PART_ROLL_DOWN" },
  { id: "V_UP", name: "V_UP" },
  { id: "WEIGHTED_ALTERNATING_SIT_UP", name: "WEIGHTED_ALTERNATING_SIT_UP" },
  { id: "WEIGHTED_BENT_KNEE_V_UP", name: "WEIGHTED_BENT_KNEE_V_UP" },
  { id: "WEIGHTED_BUTTERFLY_SITUP", name: "WEIGHTED_BUTTERFLY_SITUP" },
  { id: "WEIGHTED_CROSSED_ARMS_SIT_UP", name: "WEIGHTED_CROSSED_ARMS_SIT_UP" },
  { id: "WEIGHTED_CROSS_PUNCH_ROLL_UP", name: "WEIGHTED_CROSS_PUNCH_ROLL_UP" },
  { id: "WEIGHTED_GET_UP_SIT_UP", name: "WEIGHTED_GET_UP_SIT_UP" },
  { id: "WEIGHTED_HOVERING_SIT_UP", name: "WEIGHTED_HOVERING_SIT_UP" },
  { id: "WEIGHTED_RECLINING_CIRCLE", name: "WEIGHTED_RECLINING_CIRCLE" },
  { id: "WEIGHTED_REVERSE_CURL_UP", name: "WEIGHTED_REVERSE_CURL_UP" },
  {
    id: "WEIGHTED_RUSSIAN_TWIST_ON_SWISS_BALL",
    name: "WEIGHTED_RUSSIAN_TWIST_ON_SWISS_BALL",
  },
  {
    id: "WEIGHTED_SINGLE_LEG_SWISS_BALL_JACKKNIFE",
    name: "WEIGHTED_SINGLE_LEG_SWISS_BALL_JACKKNIFE",
  },
  { id: "WEIGHTED_SIT_UP", name: "WEIGHTED_SIT_UP" },
  {
    id: "WEIGHTED_THREE_PART_ROLL_DOWN",
    name: "WEIGHTED_THREE_PART_ROLL_DOWN",
  },
  { id: "WEIGHTED_V_UP", name: "WEIGHTED_V_UP" },
  { id: "WEIGHTED_X_ABS", name: "WEIGHTED_X_ABS" },
  { id: "X_ABS", name: "X_ABS" },
];

const SLEDGE_HAMMER = [
  { id: "0", name: "L" },
  { id: "1", name: "A" },
  { id: "2", name: "T" },
  { id: "3", name: "E" },
  { id: "4", name: "R" },
  { id: "5", name: "A" },
  { id: "6", name: "L" },
  { id: "7", name: "_" },
  { id: "8", name: "S" },
  { id: "9", name: "W" },
  { id: "10", name: "I" },
  { id: "11", name: "N" },
  { id: "12", name: "G" },
];

const SLED = [
  { id: "BACKWARD_DRAG", name: "BACKWARD_DRAG" },
  { id: "CHEST_PRESS", name: "CHEST_PRESS" },
  { id: "FORWARD_DRAG", name: "FORWARD_DRAG" },
  { id: "LOW_PUSH", name: "LOW_PUSH" },
  { id: "PUSH", name: "PUSH" },
  { id: "ROW", name: "ROW" },
];

const SQUAT = [
  { id: "SQUAT", name: "SQUAT" },
  { id: "AIR_SQUAT", name: "AIR_SQUAT" },
  {
    id: "ALTERNATING_BOX_DUMBBELL_STEP_UPS",
    name: "ALTERNATING_BOX_DUMBBELL_STEP_UPS",
  },
  { id: "BACK_SQUATS", name: "BACK_SQUATS" },
  { id: "BACK_SQUAT_WITH_BODY_BAR", name: "BACK_SQUAT_WITH_BODY_BAR" },
  { id: "BALANCING_SQUAT", name: "BALANCING_SQUAT" },
  { id: "BARBELL_BACK_SQUAT", name: "BARBELL_BACK_SQUAT" },
  { id: "BARBELL_BOX_SQUAT", name: "BARBELL_BOX_SQUAT" },
  { id: "BARBELL_FRONT_SQUAT", name: "BARBELL_FRONT_SQUAT" },
  { id: "BARBELL_HACK_SQUAT", name: "BARBELL_HACK_SQUAT" },
  { id: "BARBELL_HANG_SQUAT_SNATCH", name: "BARBELL_HANG_SQUAT_SNATCH" },
  { id: "BARBELL_LATERAL_STEP_UP", name: "BARBELL_LATERAL_STEP_UP" },
  { id: "BARBELL_QUARTER_SQUAT", name: "BARBELL_QUARTER_SQUAT" },
  { id: "BARBELL_SIFF_SQUAT", name: "BARBELL_SIFF_SQUAT" },
  { id: "BARBELL_SQUAT_SNATCH", name: "BARBELL_SQUAT_SNATCH" },
  {
    id: "BARBELL_SQUAT_WITH_HEELS_RAISED",
    name: "BARBELL_SQUAT_WITH_HEELS_RAISED",
  },
  { id: "BARBELL_STEPOVER", name: "BARBELL_STEPOVER" },
  { id: "BARBELL_STEP_UP", name: "BARBELL_STEP_UP" },
  {
    id: "BENCH_SQUAT_WITH_ROTATIONAL_CHOP",
    name: "BENCH_SQUAT_WITH_ROTATIONAL_CHOP",
  },
  { id: "BODY_WEIGHT_WALL_SQUAT", name: "BODY_WEIGHT_WALL_SQUAT" },
  { id: "BOX_STEP_SQUAT", name: "BOX_STEP_SQUAT" },
  { id: "BRACED_SQUAT", name: "BRACED_SQUAT" },
  {
    id: "CROSSED_ARM_BARBELL_FRONT_SQUAT",
    name: "CROSSED_ARM_BARBELL_FRONT_SQUAT",
  },
  { id: "CROSSOVER_DUMBBELL_STEP_UP", name: "CROSSOVER_DUMBBELL_STEP_UP" },
  { id: "DUMBBELL_FRONT_SQUAT", name: "DUMBBELL_FRONT_SQUAT" },
  {
    id: "DUMBBELL_OVERHEAD_SQUAT_SINGLE_ARM",
    name: "DUMBBELL_OVERHEAD_SQUAT_SINGLE_ARM",
  },
  { id: "DUMBBELL_SPLIT_SQUAT", name: "DUMBBELL_SPLIT_SQUAT" },
  { id: "DUMBBELL_SQUAT", name: "DUMBBELL_SQUAT" },
  { id: "DUMBBELL_SQUAT_CLEAN", name: "DUMBBELL_SQUAT_CLEAN" },
  { id: "DUMBBELL_SQUAT_SNATCH", name: "DUMBBELL_SQUAT_SNATCH" },
  { id: "DUMBBELL_STEPOVER", name: "DUMBBELL_STEPOVER" },
  { id: "DUMBBELL_STEP_UP", name: "DUMBBELL_STEP_UP" },
  { id: "DUMBBELL_THRUSTERS", name: "DUMBBELL_THRUSTERS" },
  { id: "ELEVATED_SINGLE_LEG_SQUAT", name: "ELEVATED_SINGLE_LEG_SQUAT" },
  { id: "FIGURE_FOUR_SQUATS", name: "FIGURE_FOUR_SQUATS" },
  { id: "GOBLET_SQUAT", name: "GOBLET_SQUAT" },
  { id: "KETTLEBELL_SQUAT", name: "KETTLEBELL_SQUAT" },
  { id: "KETTLEBELL_SWING_OVERHEAD", name: "KETTLEBELL_SWING_OVERHEAD" },
  {
    id: "KETTLEBELL_SWING_WITH_FLIP_TO_SQUAT",
    name: "KETTLEBELL_SWING_WITH_FLIP_TO_SQUAT",
  },
  { id: "LATERAL_DUMBBELL_STEP_UP", name: "LATERAL_DUMBBELL_STEP_UP" },
  { id: "LEG_PRESS", name: "LEG_PRESS" },
  { id: "MEDICINE_BALL_SQUAT", name: "MEDICINE_BALL_SQUAT" },
  { id: "ONE_LEGGED_SQUAT", name: "ONE_LEGGED_SQUAT" },
  { id: "OVERHEAD_BARBELL_SQUAT", name: "OVERHEAD_BARBELL_SQUAT" },
  { id: "OVERHEAD_DUMBBELL_SQUAT", name: "OVERHEAD_DUMBBELL_SQUAT" },
  { id: "OVERHEAD_SQUAT", name: "OVERHEAD_SQUAT" },
  { id: "PARTIAL_SINGLE_LEG_SQUAT", name: "PARTIAL_SINGLE_LEG_SQUAT" },
  { id: "PISTOL_SQUAT", name: "PISTOL_SQUAT" },
  { id: "PLIE_SLIDES", name: "PLIE_SLIDES" },
  { id: "PLIE_SQUAT", name: "PLIE_SQUAT" },
  { id: "PRISONER_SQUAT", name: "PRISONER_SQUAT" },
  { id: "SINGLE_LEG_BENCH_GET_UP", name: "SINGLE_LEG_BENCH_GET_UP" },
  { id: "SINGLE_LEG_BENCH_SQUAT", name: "SINGLE_LEG_BENCH_SQUAT" },
  {
    id: "SINGLE_LEG_SQUAT_ON_SWISS_BALL",
    name: "SINGLE_LEG_SQUAT_ON_SWISS_BALL",
  },
  { id: "SQUATS_WITH_BAND", name: "SQUATS_WITH_BAND" },
  { id: "SQUAT_AMERICAN_SWING", name: "SQUAT_AMERICAN_SWING" },
  { id: "SQUAT_AND_SIDE_KICK", name: "SQUAT_AND_SIDE_KICK" },
  { id: "SQUAT_JUMPS_IN_N_OUT", name: "SQUAT_JUMPS_IN_N_OUT" },
  { id: "STAGGERED_SQUAT", name: "STAGGERED_SQUAT" },
  { id: "STEP_UP", name: "STEP_UP" },
  { id: "SUITCASE_SQUATS", name: "SUITCASE_SQUATS" },
  { id: "SUMO_SQUAT", name: "SUMO_SQUAT" },
  { id: "SUMO_SQUAT_SLIDE_IN", name: "SUMO_SQUAT_SLIDE_IN" },
  { id: "SUMO_SQUAT_TO_HIGH_PULL", name: "SUMO_SQUAT_TO_HIGH_PULL" },
  { id: "SUMO_SQUAT_TO_STAND", name: "SUMO_SQUAT_TO_STAND" },
  { id: "SUMO_SQUAT_WITH_ROTATION", name: "SUMO_SQUAT_WITH_ROTATION" },
  {
    id: "SWISS_BALL_BODY_WEIGHT_WALL_SQUAT",
    name: "SWISS_BALL_BODY_WEIGHT_WALL_SQUAT",
  },
  { id: "THRUSTERS", name: "THRUSTERS" },
  { id: "UNEVEN_SQUAT", name: "UNEVEN_SQUAT" },
  { id: "WAIST_SLIMMING_SQUAT", name: "WAIST_SLIMMING_SQUAT" },
  { id: "WALL_BALL", name: "WALL_BALL" },
  { id: "WALL_BALL_SQUAT_AND_PRESS", name: "WALL_BALL_SQUAT_AND_PRESS" },
  { id: "WEIGHTED_BACK_SQUATS", name: "WEIGHTED_BACK_SQUATS" },
  { id: "WEIGHTED_BALANCING_SQUAT", name: "WEIGHTED_BALANCING_SQUAT" },
  {
    id: "WEIGHTED_BENCH_SQUAT_WITH_ROTATIONAL_CHOP",
    name: "WEIGHTED_BENCH_SQUAT_WITH_ROTATIONAL_CHOP",
  },
  { id: "WEIGHTED_BOX_STEP_SQUAT", name: "WEIGHTED_BOX_STEP_SQUAT" },
  {
    id: "WEIGHTED_ELEVATED_SINGLE_LEG_SQUAT",
    name: "WEIGHTED_ELEVATED_SINGLE_LEG_SQUAT",
  },
  { id: "WEIGHTED_FIGURE_FOUR_SQUATS", name: "WEIGHTED_FIGURE_FOUR_SQUATS" },
  {
    id: "WEIGHTED_PARTIAL_SINGLE_LEG_SQUAT",
    name: "WEIGHTED_PARTIAL_SINGLE_LEG_SQUAT",
  },
  { id: "WEIGHTED_PISTOL_SQUAT", name: "WEIGHTED_PISTOL_SQUAT" },
  { id: "WEIGHTED_PLIE_SLIDES", name: "WEIGHTED_PLIE_SLIDES" },
  { id: "WEIGHTED_PLIE_SQUAT", name: "WEIGHTED_PLIE_SQUAT" },
  { id: "WEIGHTED_PRISONER_SQUAT", name: "WEIGHTED_PRISONER_SQUAT" },
  {
    id: "WEIGHTED_SINGLE_LEG_BENCH_GET_UP",
    name: "WEIGHTED_SINGLE_LEG_BENCH_GET_UP",
  },
  {
    id: "WEIGHTED_SINGLE_LEG_BENCH_SQUAT",
    name: "WEIGHTED_SINGLE_LEG_BENCH_SQUAT",
  },
  {
    id: "WEIGHTED_SINGLE_LEG_SQUAT_ON_SWISS_BALL",
    name: "WEIGHTED_SINGLE_LEG_SQUAT_ON_SWISS_BALL",
  },
  { id: "WEIGHTED_SQUAT", name: "WEIGHTED_SQUAT" },
  { id: "WEIGHTED_STAGGERED_SQUAT", name: "WEIGHTED_STAGGERED_SQUAT" },
  { id: "WEIGHTED_STEP_UP", name: "WEIGHTED_STEP_UP" },
  { id: "WEIGHTED_SUMO_SQUAT_SLIDE_IN", name: "WEIGHTED_SUMO_SQUAT_SLIDE_IN" },
  { id: "WEIGHTED_SUMO_SQUAT_TO_STAND", name: "WEIGHTED_SUMO_SQUAT_TO_STAND" },
  {
    id: "WEIGHTED_SUMO_SQUAT_WITH_ROTATION",
    name: "WEIGHTED_SUMO_SQUAT_WITH_ROTATION",
  },
  {
    id: "WEIGHTED_SWISS_BALL_WALL_SQUAT",
    name: "WEIGHTED_SWISS_BALL_WALL_SQUAT",
  },
  { id: "WEIGHTED_UNEVEN_SQUAT", name: "WEIGHTED_UNEVEN_SQUAT" },
  { id: "WEIGHTED_WALL_SQUAT", name: "WEIGHTED_WALL_SQUAT" },
  { id: "WIDE_STANCE_BARBELL_SQUAT", name: "WIDE_STANCE_BARBELL_SQUAT" },
  { id: "WIDE_STANCE_GOBLET_SQUAT", name: "WIDE_STANCE_GOBLET_SQUAT" },
  { id: "ZERCHER_SQUAT", name: "ZERCHER_SQUAT" },
];

const STAIR_STEPPER = [
  { id: "0", name: "S" },
  { id: "1", name: "T" },
  { id: "2", name: "A" },
  { id: "3", name: "I" },
  { id: "4", name: "R" },
  { id: "5", name: "_" },
  { id: "6", name: "S" },
  { id: "7", name: "T" },
  { id: "8", name: "E" },
  { id: "9", name: "P" },
  { id: "10", name: "P" },
  { id: "11", name: "E" },
  { id: "12", name: "R" },
];

const SUSPENSION = [
  { id: "CHEST_FLY", name: "CHEST_FLY" },
  { id: "CHEST_PRESS", name: "CHEST_PRESS" },
  { id: "CRUNCH", name: "CRUNCH" },
  { id: "CURL", name: "CURL" },
  { id: "DIP", name: "DIP" },
  { id: "FACE_PULL", name: "FACE_PULL" },
  { id: "GLUTE_BRIDGE", name: "GLUTE_BRIDGE" },
  { id: "HAMSTRING_CURL", name: "HAMSTRING_CURL" },
  { id: "HIP_DROP", name: "HIP_DROP" },
  { id: "KNEE_DRIVE_JUMP", name: "KNEE_DRIVE_JUMP" },
  { id: "KNEE_TO_CHEST", name: "KNEE_TO_CHEST" },
  { id: "LAT_PULLOVER", name: "LAT_PULLOVER" },
  { id: "LUNGE", name: "LUNGE" },
  { id: "MOUNTAIN_CLIMBER", name: "MOUNTAIN_CLIMBER" },
  { id: "PENDULUM", name: "PENDULUM" },
  { id: "PIKE", name: "PIKE" },
  { id: "PLANK", name: "PLANK" },
  { id: "POWER_PULL", name: "POWER_PULL" },
  { id: "PULL_UP", name: "PULL_UP" },
  { id: "PUSH_UP", name: "PUSH_UP" },
  { id: "REVERSE_MOUNTAIN_CLIMBER", name: "REVERSE_MOUNTAIN_CLIMBER" },
  { id: "REVERSE_PLANK", name: "REVERSE_PLANK" },
  { id: "ROLLOUT", name: "ROLLOUT" },
  { id: "ROW", name: "ROW" },
  { id: "SIDE_LUNGE", name: "SIDE_LUNGE" },
  { id: "SIDE_PLANK", name: "SIDE_PLANK" },
  { id: "SINGLE_LEG_DEADLIFT", name: "SINGLE_LEG_DEADLIFT" },
  { id: "SINGLE_LEG_SQUAT", name: "SINGLE_LEG_SQUAT" },
  { id: "SIT_UP", name: "SIT_UP" },
  { id: "SQUAT", name: "SQUAT" },
  { id: "SQUAT_JUMP", name: "SQUAT_JUMP" },
  { id: "TRICEP_PRESS", name: "TRICEP_PRESS" },
  { id: "Y_FLY", name: "Y_FLY" },
];

const TIRE = [
  { id: "0", name: "F" },
  { id: "1", name: "L" },
  { id: "2", name: "I" },
  { id: "3", name: "P" },
];

const TOTAL_BODY = [
  { id: "BARBELL_BURPEE", name: "BARBELL_BURPEE" },
  { id: "BURPEE", name: "BURPEE" },
  { id: "BURPEE_BOX_JUMP", name: "BURPEE_BOX_JUMP" },
  { id: "BURPEE_BOX_JUMP_OVER", name: "BURPEE_BOX_JUMP_OVER" },
  { id: "BURPEE_BOX_JUMP_STEP_UP_OVER", name: "BURPEE_BOX_JUMP_STEP_UP_OVER" },
  { id: "HIGH_PULL_BURPEE", name: "HIGH_PULL_BURPEE" },
  { id: "LATERAL_BARBELL_BURPEE", name: "LATERAL_BARBELL_BURPEE" },
  { id: "MAN_MAKERS", name: "MAN_MAKERS" },
  { id: "ONE_ARM_BURPEE", name: "ONE_ARM_BURPEE" },
  { id: "SQUAT_PLANK_PUSH_UP", name: "SQUAT_PLANK_PUSH_UP" },
  { id: "SQUAT_THRUSTS", name: "SQUAT_THRUSTS" },
  { id: "STANDING_T_ROTATION_BALANCE", name: "STANDING_T_ROTATION_BALANCE" },
  { id: "TOTAL_BODY_BURPEE_OVER_BAR", name: "TOTAL_BODY_BURPEE_OVER_BAR" },
  { id: "WEIGHTED_BURPEE", name: "WEIGHTED_BURPEE" },
  { id: "WEIGHTED_BURPEE_BOX_JUMP", name: "WEIGHTED_BURPEE_BOX_JUMP" },
  { id: "WEIGHTED_SQUAT_PLANK_PUSH_UP", name: "WEIGHTED_SQUAT_PLANK_PUSH_UP" },
  { id: "WEIGHTED_SQUAT_THRUSTS", name: "WEIGHTED_SQUAT_THRUSTS" },
  {
    id: "WEIGHTED_STANDING_T_ROTATION_BALANCE",
    name: "WEIGHTED_STANDING_T_ROTATION_BALANCE",
  },
];

const TRICEPS_EXTENSION = [
  {
    id: "ALTERNATING_DUMBBELL_LYING_TRICEPS_EXTENSION",
    name: "ALTERNATING_DUMBBELL_LYING_TRICEPS_EXTENSION",
  },
  { id: "BENCH_DIP", name: "BENCH_DIP" },
  { id: "BODY_WEIGHT_DIP", name: "BODY_WEIGHT_DIP" },
  { id: "CABLE_KICKBACK", name: "CABLE_KICKBACK" },
  {
    id: "CABLE_LYING_TRICEPS_EXTENSION",
    name: "CABLE_LYING_TRICEPS_EXTENSION",
  },
  {
    id: "CABLE_OVERHEAD_TRICEPS_EXTENSION",
    name: "CABLE_OVERHEAD_TRICEPS_EXTENSION",
  },
  { id: "DUMBBELL_KICKBACK", name: "DUMBBELL_KICKBACK" },
  {
    id: "DUMBBELL_LYING_TRICEPS_EXTENSION",
    name: "DUMBBELL_LYING_TRICEPS_EXTENSION",
  },
  {
    id: "EZ_BAR_OVERHEAD_TRICEPS_EXTENSION",
    name: "EZ_BAR_OVERHEAD_TRICEPS_EXTENSION",
  },
  { id: "INCLINE_DIP", name: "INCLINE_DIP" },
  {
    id: "INCLINE_EZ_BAR_LYING_TRICEPS_EXTENSION",
    name: "INCLINE_EZ_BAR_LYING_TRICEPS_EXTENSION",
  },
  {
    id: "LYING_DUMBBELL_PULLOVER_TO_EXTENSION",
    name: "LYING_DUMBBELL_PULLOVER_TO_EXTENSION",
  },
  {
    id: "LYING_EZ_BAR_TRICEPS_EXTENSION",
    name: "LYING_EZ_BAR_TRICEPS_EXTENSION",
  },
  {
    id: "LYING_TRICEPS_EXTENSION_TO_CLOSE_GRIP_BENCH_PRESS",
    name: "LYING_TRICEPS_EXTENSION_TO_CLOSE_GRIP_BENCH_PRESS",
  },
  {
    id: "OVERHEAD_DUMBBELL_TRICEPS_EXTENSION",
    name: "OVERHEAD_DUMBBELL_TRICEPS_EXTENSION",
  },
  { id: "RECLINING_TRICEPS_PRESS", name: "RECLINING_TRICEPS_PRESS" },
  { id: "REVERSE_GRIP_PRESSDOWN", name: "REVERSE_GRIP_PRESSDOWN" },
  {
    id: "REVERSE_GRIP_TRICEPS_PRESSDOWN",
    name: "REVERSE_GRIP_TRICEPS_PRESSDOWN",
  },
  { id: "ROPE_PRESSDOWN", name: "ROPE_PRESSDOWN" },
  {
    id: "SEATED_BARBELL_OVERHEAD_TRICEPS_EXTENSION",
    name: "SEATED_BARBELL_OVERHEAD_TRICEPS_EXTENSION",
  },
  {
    id: "SEATED_DUMBBELL_OVERHEAD_TRICEPS_EXTENSION",
    name: "SEATED_DUMBBELL_OVERHEAD_TRICEPS_EXTENSION",
  },
  {
    id: "SEATED_EZ_BAR_OVERHEAD_TRICEPS_EXTENSION",
    name: "SEATED_EZ_BAR_OVERHEAD_TRICEPS_EXTENSION",
  },
  {
    id: "SEATED_SINGLE_ARM_OVERHEAD_DUMBBELL_EXTENSION",
    name: "SEATED_SINGLE_ARM_OVERHEAD_DUMBBELL_EXTENSION",
  },
  {
    id: "SINGLE_ARM_DUMBBELL_OVERHEAD_TRICEPS_EXTENSION",
    name: "SINGLE_ARM_DUMBBELL_OVERHEAD_TRICEPS_EXTENSION",
  },
  {
    id: "SINGLE_DUMBBELL_SEATED_OVERHEAD_TRICEPS_EXTENSION",
    name: "SINGLE_DUMBBELL_SEATED_OVERHEAD_TRICEPS_EXTENSION",
  },
  {
    id: "SINGLE_LEG_BENCH_DIP_AND_KICK",
    name: "SINGLE_LEG_BENCH_DIP_AND_KICK",
  },
  { id: "SINGLE_LEG_DIP", name: "SINGLE_LEG_DIP" },
  {
    id: "STATIC_LYING_TRICEPS_EXTENSION",
    name: "STATIC_LYING_TRICEPS_EXTENSION",
  },
  { id: "SUSPENDED_DIP", name: "SUSPENDED_DIP" },
  {
    id: "SWISS_BALL_DUMBBELL_LYING_TRICEPS_EXTENSION",
    name: "SWISS_BALL_DUMBBELL_LYING_TRICEPS_EXTENSION",
  },
  {
    id: "SWISS_BALL_EZ_BAR_LYING_TRICEPS_EXTENSION",
    name: "SWISS_BALL_EZ_BAR_LYING_TRICEPS_EXTENSION",
  },
  {
    id: "SWISS_BALL_EZ_BAR_OVERHEAD_TRICEPS_EXTENSION",
    name: "SWISS_BALL_EZ_BAR_OVERHEAD_TRICEPS_EXTENSION",
  },
  { id: "TABLETOP_DIP", name: "TABLETOP_DIP" },
  { id: "TRICEPS_EXTENSION_ON_FLOOR", name: "TRICEPS_EXTENSION_ON_FLOOR" },
  { id: "TRICEPS_PRESS", name: "TRICEPS_PRESS" },
  { id: "TRICEPS_PRESSDOWN", name: "TRICEPS_PRESSDOWN" },
  { id: "WEIGHTED_BENCH_DIP", name: "WEIGHTED_BENCH_DIP" },
  { id: "WEIGHTED_DIP", name: "WEIGHTED_DIP" },
  { id: "WEIGHTED_INCLINE_DIP", name: "WEIGHTED_INCLINE_DIP" },
  {
    id: "WEIGHTED_SINGLE_LEG_BENCH_DIP_AND_KICK",
    name: "WEIGHTED_SINGLE_LEG_BENCH_DIP_AND_KICK",
  },
  { id: "WEIGHTED_SINGLE_LEG_DIP", name: "WEIGHTED_SINGLE_LEG_DIP" },
  { id: "WEIGHTED_SUSPENDED_DIP", name: "WEIGHTED_SUSPENDED_DIP" },
  { id: "WEIGHTED_TABLETOP_DIP", name: "WEIGHTED_TABLETOP_DIP" },
];

const WARM_UP = [
  { id: "NECK_ROTATIONS", name: "NECK_ROTATIONS" },
  { id: "STANDING_HAMSTRING_STRETCH", name: "STANDING_HAMSTRING_STRETCH" },
  { id: "STRETCH_90_90", name: "STRETCH_90_90" },
  { id: "STRETCH_ABS", name: "STRETCH_ABS" },
  { id: "STRETCH_BUTTERFLY", name: "STRETCH_BUTTERFLY" },
  { id: "STRETCH_CALF", name: "STRETCH_CALF" },
  { id: "STRETCH_CAT_COW", name: "STRETCH_CAT_COW" },
  { id: "STRETCH_CHILDS_POSE", name: "STRETCH_CHILDS_POSE" },
  { id: "STRETCH_COBRA", name: "STRETCH_COBRA" },
  { id: "STRETCH_FOREARMS", name: "STRETCH_FOREARMS" },
  { id: "STRETCH_FORWARD_GLUTES", name: "STRETCH_FORWARD_GLUTES" },
  { id: "STRETCH_FRONT_SPLIT", name: "STRETCH_FRONT_SPLIT" },
  { id: "STRETCH_HAMSTRING", name: "STRETCH_HAMSTRING" },
  { id: "STRETCH_HIP_FLEXOR_AND_QUAD", name: "STRETCH_HIP_FLEXOR_AND_QUAD" },
  { id: "STRETCH_LAT", name: "STRETCH_LAT" },
  { id: "STRETCH_LEVATOR_SCAPULAE", name: "STRETCH_LEVATOR_SCAPULAE" },
  {
    id: "STRETCH_LUNGE_WITH_SPINAL_TWIST",
    name: "STRETCH_LUNGE_WITH_SPINAL_TWIST",
  },
  { id: "STRETCH_LUNGING_HIP_FLEXOR", name: "STRETCH_LUNGING_HIP_FLEXOR" },
  { id: "STRETCH_LYING_ABDUCTION", name: "STRETCH_LYING_ABDUCTION" },
  { id: "STRETCH_LYING_IT_BAND", name: "STRETCH_LYING_IT_BAND" },
  { id: "STRETCH_LYING_KNEE_TO_CHEST", name: "STRETCH_LYING_KNEE_TO_CHEST" },
  { id: "STRETCH_LYING_PIRIFORMIS", name: "STRETCH_LYING_PIRIFORMIS" },
  { id: "STRETCH_LYING_SPINAL_TWIST", name: "STRETCH_LYING_SPINAL_TWIST" },
  { id: "STRETCH_NECK", name: "STRETCH_NECK" },
  { id: "STRETCH_OBLIQUES", name: "STRETCH_OBLIQUES" },
  { id: "STRETCH_OVER_UNDER_SHOULDER", name: "STRETCH_OVER_UNDER_SHOULDER" },
  { id: "STRETCH_PECTORAL", name: "STRETCH_PECTORAL" },
  { id: "STRETCH_PIGEON_POSE", name: "STRETCH_PIGEON_POSE" },
  { id: "STRETCH_PIRIFORMIS", name: "STRETCH_PIRIFORMIS" },
  { id: "STRETCH_QUAD", name: "STRETCH_QUAD" },
  { id: "STRETCH_SCORPION", name: "STRETCH_SCORPION" },
  { id: "STRETCH_SHOULDER", name: "STRETCH_SHOULDER" },
  { id: "STRETCH_SIDE", name: "STRETCH_SIDE" },
  { id: "STRETCH_SIDE_LUNGE", name: "STRETCH_SIDE_LUNGE" },
  { id: "STRETCH_SIDE_SPLIT", name: "STRETCH_SIDE_SPLIT" },
  { id: "STRETCH_STANDING_IT_BAND", name: "STRETCH_STANDING_IT_BAND" },
  { id: "STRETCH_STRADDLE", name: "STRETCH_STRADDLE" },
  { id: "STRETCH_TRICEPS", name: "STRETCH_TRICEPS" },
  {
    id: "STRETCH_WALL_CHEST_AND_SHOULDER",
    name: "STRETCH_WALL_CHEST_AND_SHOULDER",
  },
];

export const exercises = {
  BANDED_EXERCISES,
  BATTLE_ROPE,
  BENCH_PRESS,
  BIKE_OUTDOOR,
  CALF_RAISE,
  CARDIO,
  CARRY,
  CHOP,
  CORE,
  CRUNCH,
  CURL,
  DEADLIFT,
  ELLIPTICAL,
  FLOOR_CLIMB,
  FLYE,
  HIP_RAISE,
  HIP_STABILITY,
  HIP_SWING,
  HYPEREXTENSION,
  INDOOR_BIKE,
  LADDER,
  LATERAL_RAISE,
  LEG_CURL,
  LEG_RAISE,
  LUNGE,
  OLYMPIC_LIFT,
  PLANK,
  PLYO,
  PULL_UP,
  PUSH_UP,
  ROW,
  RUN_INDOOR,
  RUN,
  SANDBAG,
  SHOULDER_PRESS,
  SHOULDER_STABILITY,
  SHRUG,
  SIT_UP,
  SLEDGE_HAMMER,
  SLED,
  SQUAT,
  STAIR_STEPPER,
  SUSPENSION,
  TIRE,
  TOTAL_BODY,
  TRICEPS_EXTENSION,
  WARM_UP,
};
