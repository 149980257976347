import * as React from "react";
import {
  required,
  Edit,
  TextInput,
  SimpleForm,
  RadioButtonGroupInput,
} from "react-admin";

import { notificationGroupOptions } from "../../assets/options";

const NotificationEdit = (props) => (
  <Edit {...props} undoable={false} redirect="show">
    <SimpleForm>
      <TextInput
        validate={required()}
        fullWidth
        helpertext="Título"
        source="title"
      />
      <TextInput fullWidth helpertext="Conteúdo" source="body" />
      <RadioButtonGroupInput
        source="group"
        choices={notificationGroupOptions}
        validate={required()}
      />
    </SimpleForm>
  </Edit>
);

export default NotificationEdit;
