export const targetAreas = [
  { id: "Full body", name: "Full body" },
  { id: "Core", name: "Core" },
  { id: "Superior", name: "Superior" },
  { id: "Bíceps", name: "Bíceps" },
  { id: "Ombro", name: "Ombro" },
  { id: "Tríceps", name: "Tríceps" },
  { id: "Peito", name: "Peito" },
  { id: "Costas", name: "Costas" },
  { id: "Inferior", name: "Inferior" },
  { id: "Quadríceps", name: "Quadríceps" },
  { id: "Posterior de coxa", name: "Posterior de coxa" },
  { id: "Adutor", name: "Adutor" },
  { id: "Abdutor", name: "Abdutor" },
  { id: "Panturrilha", name: "Panturrilha" },
  { id: "Glúteos", name: "Glúteos" },
  { id: "Antebraço", name: "Antebraço" },
  { id: "Tornozelo", name: "Tornozelo" },
];

export const exerciseTypes = [
  { id: "Mobilidade", name: "Mobilidade" },
  { id: "Exercício", name: "Exercício" },
  { id: "Aquecimento", name: "Aquecimento" },
  { id: "Alongamento", name: "Alongamento" },
  { id: "Liberação", name: "Liberação" },
  { id: "Corrida", name: "Corrida" },
];

export const genderChoices = [
  { id: "female", name: "Feminino" },
  { id: "male", name: "Masculino" },
  { id: "nonBinary", name: "Não Binário" },
  { id: "ratherNotSay", name: "Prefiro não dizer" },
];

export const focusOptions = [
  { id: "Running", name: "Running" },
  { id: "Workout", name: "Workout" },
  { id: "Swimming", name: "Swimming" },
  { id: "Home Workout", name: "Home Workout" },
  { id: "Bike", name: "Bike" },
];


export const evaluationChoices = [
  { id: "Anamnese", name: "Anamnese" },
  { id: "Avaliação Física", name: "Avaliação Física" },
  { id: "Autoavaliação", name: "Autoavaliação" },
];

export const notificationGroupOptions = [
  { id: "ALL", name: "Todos" },
  { id: "PREMIUM", name: "Apenas premium" },
  { id: "NON_PREMIUM", name: "Apenas não-premium" },
];
