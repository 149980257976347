import * as React from "react";
import {
  Create,
  TextInput,
  NumberInput,
  SimpleForm,
  ReferenceInput,
  BooleanInput,
  RadioButtonGroupInput,
  required,
} from "react-admin";

import { evaluationChoices } from "../../assets/options";

const EvaluationsCreate = (props) => (
  <Create {...props} undoable={false}>
    <SimpleForm>
      <ReferenceInput source="user" reference="users" validate={required()} />

      <RadioButtonGroupInput
        source="type"
        choices={evaluationChoices}
        validate={required()}
      />

      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <h4>Anamnese</h4>
        <TextInput source="objective" label="Objetivo principal" fullWidth />
        <NumberInput source="weight" label="Peso" fullWidth />
        <NumberInput source="height" label="Altura" fullWidth />
        <TextInput
          source="movementRestrictions"
          label="Possui alguma restrição de movimento?"
        />
        <TextInput
          source="diseases"
          label="Adquiriu alguma doença nos ultimos anos?"
        />
        <NumberInput source="bodyFat" label="Gordura corporal" />
        <TextInput source="meds" label="Toma medicamentos?" />
        <TextInput
          source="otherActivities"
          label="Pratica atividade física (quais/quanto tempo/quantas x semanas)?"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <BooleanInput source="diabetes" label="Tem diabetes?" />
          <BooleanInput source="HAS" label="HAS?" />
          <BooleanInput
            source="cardiacAlteration"
            label="Alteração cardiada?"
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <h4>Avaliação de corridas</h4>
        <NumberInput
          source="thirtyMetersSprint"
          label="10x de 30 m (em segundos)"
          fullWidth
        />
        <NumberInput
          source="fourHundredsMetersSprint"
          label="400 m (em segundos)"
          fullWidth
        />
        <NumberInput
          source="eightHundredsMetersSprint"
          label="800 m (em segundos)"
          fullWidth
        />
        <NumberInput
          source="oneAndHalfKilometerRun"
          label="1.5 km (em segundos)"
          fullWidth
        />
        <NumberInput
          source="fiveKilometersRun"
          label="5 km (em segundos)"
          fullWidth
        />
        <NumberInput
          source="tenKilometersRun"
          label="10 km (em segundos)"
          fullWidth
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <h4>Avaliação de cargas</h4>
        <NumberInput source="jumpHeight" label="Medida de salto" fullWidth />
        <NumberInput
          source="squatsWeightForFourReps"
          label="agachamento (carga para 4 repetições) (em kg)"
          fullWidth
        />
        <NumberInput
          source="deadliftWeightForFourReps"
          label="terra (carga para 4 repetições) (em kg)"
          fullWidth
        />
        <NumberInput
          source="pullUpsReps"
          label="barra fixa (maior quantidade de repetições - até 6) (em quantidade)"
          fullWidth
        />
        <NumberInput
          source="pullUpsWeightForOverSixReps"
          label="barra fixa (acima de 6 repetições, qual a carga) (em kg)"
          fullWidth
        />
        <NumberInput
          source="benchPressWeightForFourReps"
          label="supino (carga para 4 repetições) (em kg)"
          fullWidth
        />
        <NumberInput
          source="backRowsWeightForFourReps"
          label="remada (carga para 4 repetições) (em kg)"
          fullWidth
        />
        <NumberInput
          source="pushUpsMaxReps"
          label="flexão de braço (maior n de repetição) (em quantidade)"
          fullWidth
        />
        <NumberInput
          source="pullUpIsometricHold"
          label="barra fixa isometria (maior tempo) (em segundos)"
          fullWidth
        />
        <NumberInput
          source="stiffLeggedDeadliftWeightForFourReps"
          label="stiff (carga para 4 repetições) (em kg)"
          fullWidth
        />
      </div>
    </SimpleForm>
  </Create>
);

export default EvaluationsCreate;
