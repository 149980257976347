import List from "./list";
import Edit from "./edit";
import Create from "./create";
import Show from "./show";

const WorkoutsPages = {
  edit: Edit,
  create: Create,
  show: Show,
  list: List
}

export default WorkoutsPages;