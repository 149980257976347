import * as React from "react";
import {
  Layout,
  MenuItemLink,
  useResourceDefinitions,
  Menu as RAMenu,
} from "react-admin";
import DefaultIcon from "@material-ui/icons/ViewList";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DashboardIcon from '@mui/icons-material/Dashboard';

const Menu = ({ onMenuClick, logout }) => {
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map(
    (name) => resourcesDefinitions[name]
  );

  return (
    <RAMenu>
      <RAMenu.Item to="/" primaryText="Dashboard" leftIcon={<DashboardIcon />} />
      {resources.map((resource) => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && resource.options.label) || resource.name
          }
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
          onClick={onMenuClick}
        />
      ))}
      <RAMenu.Item
        to="/copyWorkouts"
        primaryText="Copiar treinos"
        leftIcon={<FileCopyIcon />}
      />
    </RAMenu>
  );
};

export default function CustomLayout(props) {
  return <Layout {...props} menu={Menu} />;
}
