import Cookies from 'universal-cookie'
import config from './config'
import api from './api'

let user
const Cookie = new Cookies()

const authProvider = {
  login: async ({ email, password }) => {
    const response = await api().auth.signin({ email, password }).catch(console.error)

    user = response.data.manager

    Cookie.set(config.cookieKey, response.data.token, { path: '/' })

    return Promise.resolve()
  },

  logout: () => {
    user = null
    Cookie.remove(config.cookieKey)

    return Promise.resolve()
  },

  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      Cookie.set(config.cookieKey, null, { path: '/' })
      return Promise.reject()
    }
    return Promise.resolve()
  },

  // called when the user navigates to a new location, to check for authentication
  checkAuth: async () => {
    let success = false
    let error
    const jwt = Cookie.get(config.cookieKey)

    if (user) success = true
    else if (jwt) {

      try {
        const response = await api().managers.current()

        success = true
        user = response.data
      } catch (err) {
        error = err;
      }

    }

    if (success) {
      document.dispatchEvent(new Event('check_auth_success'))
      return true
    }

    document.dispatchEvent(new Event('check_auth_failure'))
    if (error) throw error
    throw new Error('Unauthenticated')
  },

  getIdentity: () => {
    if (user) {
      return Promise.resolve({
        id: user.id,
        fullName: user.name,
      })
    }
    return Promise.resolve({})
  },

  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
}


export default authProvider;