import { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";

import LoadingIndicator from "../../components/LoadingIndicator";

import api from "../../api";

const Pagination = ({ currentPage, setCurrentPage }) => (
  <div
    style={{
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      margin: "10px",
    }}
  >
    <button onClick={() => setCurrentPage(currentPage - 1)}>{"<"}</button>
    {currentPage}
    <button onClick={() => setCurrentPage(currentPage + 1)}>{">"}</button>
  </div>
);

const WorkoutButton = ({ workout, index, handleSelectWorkout }) => (
  <button
    onClick={() => handleSelectWorkout(workout.id, "add")}
    style={{
      backgroundColor: "grey",
      border: "none",
      color: "white",
      cursor: "pointer",
      margin: "5px 0",
      padding: 5,
    }}
  >
    <p>
      {workout.name} - {workout.userName} - {workout.active ? "Ativo" : "Inativo"}
    </p>
  </button>
);

const AvailableWorkouts = ({ availableWorkouts, handleSelectWorkout }) => (
  <div
    style={{
      minHeight: 400,
      maxHeight: 400,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "white",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: 10,
    }}
  >
    {availableWorkouts.length > 0 &&
      availableWorkouts.map((workout, index) => (
        <WorkoutButton
          key={`${workout.id}-available-${index}`}
          workout={workout}
          index={index}
          handleSelectWorkout={handleSelectWorkout}
        />
      ))}
  </div>
);

const SelectedWorkouts = ({ selectedWorkouts, handleSelectWorkout }) => (
  <div
    style={{
      minHeight: 400,
      maxHeight: 400,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "white",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      padding: 10,
      width: "50%",
    }}
  >
    {selectedWorkouts.length > 0 &&
      selectedWorkouts.map((workout, index) => (
        <WorkoutButton
          key={`${workout.id}-selected-${index}`}
          workout={workout}
          index={index}
          handleSelectWorkout={handleSelectWorkout}
        />
      ))}
  </div>
);

const Users = ({ allUsers, setTargetUser, targetUser }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      height: "auto",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      gap: 20,
    }}
  >
    {allUsers && (
      <select
        name="userList"
        id="userList"
        onChange={(e) => {
          const value = e.target.value;
          setTargetUser(value === "NONE" ? null : value);
        }}
        value={targetUser}
      >
        <option value="NONE">Select a user</option> {/* Blank option */}
        {allUsers.map((user) => (
          <option key={`${user.id}-target`} value={user.id}>
            {user.email}
          </option>
        ))}
      </select>
    )}
  </div>
);

const CopyWorkouts = () => {
  const dataProvider = useDataProvider();

  const [allUsers, setAllUsers] = useState(null);
  const [targetUser, setTargetUser] = useState(null);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);

  const [filterByUser, setFilterByUser] = useState(null);

  const [selectedWorkouts, setSelectedWorkouts] = useState([]);
  const [availableWorkouts, setAvailableWorkouts] = useState([]);
  const [isLoadingAvailableWorkouts, setIsLoadingAvailableWorkouts] =
    useState(true);

  const [currentPage, setCurrentPage] = useState(0);
  const [isCopying, setIsCopying] = useState(false);

  useEffect(() => {
    dataProvider
      .getList("users")
      .then(({ data }) => {
        const mappedUsers = data.map((user) => ({
          id: user._id,
          email: user.email,
        }));

        setAllUsers(mappedUsers);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoadingUsers(false);
      });
  }, [dataProvider]);

  useEffect(() => {
    const updateWorkoutsList = () => {
      const filterObj = filterByUser ? { userId: filterByUser } : null;
      dataProvider
        .getList("workoutsToCopy", {
          pagination: { perPage: 20, page: currentPage },
          filter: filterObj || null,
        })
        .then(({ data }) => {
          const mappedWorkouts = data.map((workout) => ({
            id: workout._id,
            name: workout.name,
            userName: `${workout.user.name} ${workout.user.surname}`,
          }));

          setAvailableWorkouts(mappedWorkouts);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoadingAvailableWorkouts(false);
        });
    };

    setIsLoadingAvailableWorkouts(true);
    updateWorkoutsList();
  }, [dataProvider, currentPage, filterByUser]);

  const handleSelectWorkout = (id, action) => {
    let selectedWorkout;
    let newAvailableWorkouts;
    let newSelectedWorkouts;

    if (action === "add") {
      selectedWorkout = availableWorkouts.filter(
        (workout) => workout.id === id
      )[0];
      newAvailableWorkouts = availableWorkouts.filter(
        (workout) => workout.id !== id
      );
      newSelectedWorkouts = [...selectedWorkouts, selectedWorkout];
    } else {
      selectedWorkout = selectedWorkouts.filter(
        (workout) => workout.id === id
      )[0];
      // if remove
      newAvailableWorkouts = [...availableWorkouts, selectedWorkout];
      newSelectedWorkouts = selectedWorkouts.filter(
        (workout) => workout.id !== id
      );
    }

    setAvailableWorkouts(newAvailableWorkouts);
    setSelectedWorkouts(newSelectedWorkouts);
  };

  const handleCopyWorkouts = async () => {
    if (!targetUser) return alert("Selecione um usuario!");
    if (!selectedWorkouts.length)
      return alert("Selecione pelo menos um treino a ser copiado");

    await api().workouts.copyMultiple({
      workouts: selectedWorkouts.map((workout) => workout.id),
      user: targetUser,
    });

    setCurrentPage(0);
    setSelectedWorkouts([]);
    setFilterByUser(null);
    setIsCopying(false);
    return alert("Treino copiado!");
  };

  return (
    <div style={{ margin: "1rem 0" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          gap: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Users
            allUsers={allUsers}
            setTargetUser={setFilterByUser}
            loading={isLoadingUsers}
            value={targetUser}
          />

          {isLoadingAvailableWorkouts ? (
            <LoadingIndicator />
          ) : (
            <>
              {/* <SearchUser
                value={filterByUser}
                onFinishTyping={(value) => handleFilterByUser(value)}
                updateValue={setFilterByUser}
              /> */}

              <AvailableWorkouts
                availableWorkouts={availableWorkouts}
                handleSelectWorkout={handleSelectWorkout}
              />
            </>
          )}
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>

        <SelectedWorkouts
          selectedWorkouts={selectedWorkouts}
          handleSelectWorkout={handleSelectWorkout}
        />
      </div>

      <div
        style={{
          marginTop: 40,
          display: "flex",
          flexDirection: "column",
          gap: 40,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isLoadingUsers ? (
          <LoadingIndicator />
        ) : (
          <Users
            allUsers={allUsers}
            setTargetUser={setTargetUser}
            loading={isLoadingUsers}
          />
        )}

        {isCopying ? (
          <LoadingIndicator />
        ) : (
          <button
            onClick={() => {
              setIsCopying(true);
              handleCopyWorkouts();
            }}
          >
            Copiar treinos
          </button>
        )}
      </div>
    </div>
  );
};

export default CopyWorkouts;

// {
//   allUsers && (
//     <select
//       name="userList"
//       id="userList"
//       onChange={(e) => {
//         setFilterByUser(e.target.value);
//       }}
//     >
//       <option value="">Select a user</option> {/*Blank option */}
//       {allUsers.map((user) => (
//         <option key={`${user.id}-filter`} value={user.id}>
//           {user.email}
//         </option>
//       ))}
//     </select>
//   );
// }
