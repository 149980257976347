const styles = {
  twoColumnsStyling: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "10px",
    "@media (maxWidth: 700px)": {
      gridTemplateColumns: "1fr",
    },
  },
  threeColumnsStyling: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: "10px",
    "@media (maxWidth: 700px)": {
      gridTemplateColumns: "1fr",
    },
  },
};

const TwoColumns = ({ children }) => (
  <div style={styles.twoColumnsStyling}>{children}</div>
);

const ThreeColumns = ({ children }) => (
  <div style={styles.threeColumnsStyling}>{children}</div>
);

const Div = {
  TwoColumns,
  ThreeColumns,
};

export default Div;
