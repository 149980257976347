import PeopleIcon from "@material-ui/icons/People";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import LockIcon from "@material-ui/icons/Lock";
import NotificationsIcon from "@mui/icons-material/Notifications";

export const Lock = LockIcon;

const Icons = {
  People: PeopleIcon,
  Assignment: AssignmentIcon,
  AssignmentInd: AssignmentIndIcon,
  FitnessCenter: FitnessCenterIcon,
  Lock: LockIcon,
  Notifications: NotificationsIcon,
};

export default Icons;
