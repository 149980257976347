import axios from 'axios';
import Cookies from 'universal-cookie'
import config from '../config'

const Cookie = new Cookies()

const configAxios = () => {
  const jwt = Cookie.get(config.cookieKey);
  const options = {
    baseURL: config.apiUrl,
    headers: {
      authorization: `Bearer ${jwt}`
    }
  }

  return axios.create(options);
};

export default configAxios;