import * as React from "react";
import { required, Edit, TextInput, SimpleForm } from "react-admin";

const ManagersEdit = (props) => (
  <Edit {...props} undoable={false} redirect="show">
    <SimpleForm>
      <TextInput
        validate={required()}
        fullWidth
        helpertext="Name"
        source="name"
      />
      <TextInput
        fullWidth
        helpertext="email"
        source="email"
        validate={required()}
      />
    </SimpleForm>
  </Edit>
);

export default ManagersEdit;
