const evaluations = (axios) => ({
  create: (params) => axios.post("/admin/evaluations", params),
  list: (params) => axios.get("/admin/evaluations/list", { params }),
  many: (ids) => axios.post("/admin/evaluations/many", { ids }),
  
  show: (evaluationID) => axios.get(`/admin/evaluations/${evaluationID}`),
  delete: (evaluationID) => axios.delete(`/admin/evaluations/${evaluationID}`),
  update: (params) => axios.patch(`/admin/evaluations/${params.id}`, params),
});

export default evaluations;
