import { useEffect, useState } from "react";
import {
  required,
  Edit,
  TextInput,
  SimpleFormIterator,
  BooleanInput,
  ArrayInput,
  RadioButtonGroupInput,
  ReferenceInput,
  ReferenceField,
  DateField,
  useDataProvider,
  NumberInput,
  TabbedForm,
  FormTab,
  DateInput,
  useRedirect,
} from "react-admin";

import api from "../../api";

import { focusOptions } from "../../assets/options";
import CopyButton from "../../components/CopyWorkoutButton";

const WorkoutsEdit = (props) => {
  const dataProvider = useDataProvider();
  const [selectedUser, setSelectedUser] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const redirect = useRedirect();

  useEffect(() => {
    dataProvider
      .getList("users", { filter: { premium: true } })
      .then((res) => {
        const parsedUsers = res.data.map((user) => ({
          id: user._id,
          email: user.email,
        }));
        setUsersList(parsedUsers);
      })
      .catch((err) => {
        console.log(err);
        alert("Erro ao carregar usuário");
      });
    // eslint-disable-next-line
  }, []);

  const handleCopyWorkout = async (prop) => {
    if (!selectedUser) {
      return alert("Selecione um usuário para copiar o treino!");
    }

    await api().workouts.copy({
      workout: prop._id,
      user: selectedUser,
    });

    return alert("Treino copiado!");
  };

  const getBasicInfo = () => (
    <FormTab label="Informações Básicas">
      <ReferenceField
        source="user._id"
        reference="users"
        validate={required()}
      />

      <TextInput
        validate={required()}
        fullWidth
        helpertext="Name"
        source="name"
      />
      <BooleanInput label="Active" source="active" />

      <RadioButtonGroupInput
        source="type"
        choices={focusOptions}
        validate={required()}
      />

      <TextInput
        fullWidth
        helpertext="Playlist para o exercício"
        source="playlist"
      />
      <TextInput fullWidth label="Comentarios do treino" source="comment" />
      <span>Criado em</span>
      <DateField
        label="Criado em"
        source="createdAt"
        locales="pt-BR"
        options={{ timeZone: "UTC" }}
      />

      <div>
        <h4>Copiar este treino para outro usuario</h4>
        <select
          name="userList"
          id="userList"
          onChange={(e) => {
            setSelectedUser(e.target.value);
          }}
        >
          <option value="">Select a user</option> {/* Blank option */}
          {usersList.map((user) => (
            <option key={user.id} value={user.id}>
              {user.email}
            </option>
          ))}
        </select>
        <CopyButton
          onClick={(record) => {
            handleCopyWorkout(record);
          }}
        />
      </div>
    </FormTab>
  );

  const getWarmUp = () => (
    <FormTab label="Aquecimento">
      <ArrayInput source="warmUp" fullWidth>
        <SimpleFormIterator fullWidth>
          <ReferenceInput source="exercise._id" reference="exercises" />

          <TextInput label="Repetições" source="reps" fullWidth />
          <TextInput label="Series" source="series" fullWidth />
          <TextInput label="Descanso" source="rest" fullWidth />
          <TextInput
            label="Carga recomendada"
            source="proposedLoad"
            fullWidth
          />
          <TextInput label="Carga do usuário" source="realLoad" fullWidth />
          <NumberInput
            label="Tempo para o exercício (segundos)"
            source="timer"
          />
          <TextInput helpertext="Música para o exercício" source="music" />
          <TextInput label="Comentarios" source="comment" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
    </FormTab>
  );

  const getContent = () => (
    <FormTab label="Conteúdo">
      <ArrayInput source="content" fullWidth>
        <SimpleFormIterator fullWidth>
          <ReferenceInput source="exercise._id" reference="exercises" />
          <TextInput label="Repetições" source="reps" fullWidth />
          <TextInput label="Series" source="series" fullWidth />
          <TextInput label="Descanso" source="rest" fullWidth />
          <TextInput
            label="Carga recomendada"
            source="proposedLoad"
            fullWidth
          />
          <TextInput label="Carga do usuário" source="realLoad" fullWidth />
          <NumberInput
            label="Tempo para o exercício (segundos)"
            source="timer"
          />
          <TextInput helpertext="Música para o exercício" source="music" />
          <TextInput label="Comentarios" source="comment" fullWidth />
          <BooleanInput label="Set?" source="isSet" fullWidth />
          <ArrayInput source="setExercises" fullWidth>
            <SimpleFormIterator fullWidth>
              <ReferenceInput source="exercise._id" reference="exercises" />

              <TextInput label="Repetições" source="reps" fullWidth />
              <TextInput label="Series" source="series" fullWidth />
              <TextInput label="Descanso" source="rest" fullWidth />
              <TextInput
                label="Carga recomendada"
                source="proposedLoad"
                fullWidth
              />
              <TextInput label="Carga do usuário" source="realLoad" fullWidth />
              <NumberInput
                label="Tempo para o exercício (segundos)"
                source="timer"
              />
              <TextInput helpertext="Música para o exercício" source="music" />
              <TextInput label="Comentarios" source="comment" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
    </FormTab>
  );

  const getSchedules = () => (
    <FormTab label="Agendamentos">
      <ArrayInput source="schedules" fullWidth>
        <SimpleFormIterator fullWidth>
          <DateInput
            fullWidth
            source="scheduledDate"
            label="Data agendada [As datas mostram sempre um dia antes (bug do framework)]"
            locales="pt-BR"
            options={{ timeZone: "UTC" }}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </FormTab>
  );

  const handleRedirect = (resource, id, data) => {
    const userId = data.user._id;
    redirect(`/users/${userId}/show/3`);
  };

  return (
    <Edit
      {...props}
      undoable={false}
      redirect={(resource, id, data) => handleRedirect(resource, id, data)}
    >
      <TabbedForm>
        {getBasicInfo()}
        {getWarmUp()}
        {getContent()}
        {getSchedules()}
      </TabbedForm>
    </Edit>
  );
};

export default WorkoutsEdit;
