import SendToGarminButton from "components/SendToGarminButton";
import {
  Datagrid,
  TextField,
  ReferenceField,
  BooleanField,
  DateField,
  Show,
  ArrayField,
  NumberField,
  TabbedShowLayout,
} from "react-admin";

import api from "../../api";

const sendToGarmin = async (prop) => {
  try {
    await api().workouts.sendToGarmin({
      workoutId: prop._id,
    });
    return alert("Treino enviado!");
  } catch (error) {
    return alert(
      `Não foi possível enviar o treino pra garmin:\n ${JSON.stringify(error)}`
    );
  }
};

const getBasicInfo = () => (
  <TabbedShowLayout.Tab label="Informações Básicas">
    <ReferenceField source="user._id" reference="users" />

    <TextField source="name" />
    <BooleanField label="Active" source="active" />
    <BooleanField label="Treinado" source="beenTrained" />
    <TextField source="type" />
    <TextField source="playlist" />
    <TextField label="Comentarios do treino" source="comment" />

    <DateField
      label="Criado em"
      source="createdAt"
      locales="pt-BR"
      options={{ timeZone: "UTC" }}
    />
    <DateField
      label="Ultima vez realizado"
      source="lastTrained"
      locales="pt-BR"
      options={{ timeZone: "UTC" }}
    />
    <SendToGarminButton
      onClick={(record) => {
        sendToGarmin(record);
      }}
    />
  </TabbedShowLayout.Tab>
);

const getWarmUp = () => (
  <TabbedShowLayout.Tab label="Aquecimento">
    <ArrayField source="warmUp">
      <Datagrid bulkActionButtons={false}>
        <ReferenceField source="exercise._id" reference="exercises" />

        <TextField label="Repetições" source="reps" />
        <TextField label="Series" source="series" />
        <TextField label="Descanso" source="rest" />
        <TextField label="Carga recomendada" source="proposedLoad" />
        <TextField label="Carga atual" source="realLoad" />
        <NumberField label="Tempo para o exercício (segundos)" source="timer" />
        <TextField helpertext="Música do exercício" source="music" />
        <TextField label="Comentarios" source="comment" />
      </Datagrid>
    </ArrayField>
  </TabbedShowLayout.Tab>
);

const getContent = () => (
  <TabbedShowLayout.Tab label="Conteúdo">
    <ArrayField source="content">
      <Datagrid bulkActionButtons={false}>
        <ReferenceField source="exercise._id" reference="exercises" />

        <TextField label="Repetições" source="reps" />
        <TextField label="Series" source="series" />
        <TextField label="Descanso" source="rest" />
        <TextField label="Carga recomendada" source="proposedLoad" />
        <TextField label="Carga atual" source="realLoad" />
        <NumberField label="Tempo para o exercício (segundos)" source="timer" />
        <TextField helpertext="Música do exercício" source="music" />
        <TextField label="Comentarios" source="comment" />

        <BooleanField label="Set?" source="isSet" />

        <ArrayField source="setExercises">
          <Datagrid bulkActionButtons={false}>
            <ReferenceField source="exercise._id" reference="exercises" />

            <TextField label="Repetições" source="reps" />
            <TextField label="Series" source="series" />
            <TextField label="Descanso" source="rest" />
            <TextField label="Carga recomendada" source="proposedLoad" />
            <TextField label="Carga atual" source="realLoad" />
            <NumberField
              label="Tempo para o exercício (segundos)"
              source="timer"
            />
            <TextField helpertext="Música do exercício" source="music" />
            <TextField label="Comentarios" source="comment" />
          </Datagrid>
        </ArrayField>
      </Datagrid>
    </ArrayField>
  </TabbedShowLayout.Tab>
);

const getScores = () => (
  <TabbedShowLayout.Tab label="Notas">
    <ArrayField source="scores">
      <Datagrid bulkActionButtons={false}>
        <NumberField label="Nota" source="score" />
        <TextField label="Comentario" source="comment" />
        <DateField
          label="Criado em"
          source="createdAt"
          locales="pt-BR"
          options={{ timeZone: "UTC" }}
        />
      </Datagrid>
    </ArrayField>
  </TabbedShowLayout.Tab>
);

const getSchedules = () => (
  <TabbedShowLayout.Tab label="Agendamentos">
    <ArrayField source="schedules">
      <Datagrid bulkActionButtons={false}>
        <DateField
          label="Data"
          source="scheduledDate"
          locales="pt-BR"
          options={{ timeZone: "UTC" }}
        />
        <BooleanField label="Feito?" source="done" />
      </Datagrid>
    </ArrayField>
  </TabbedShowLayout.Tab>
);

const WorkoutsShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      {getBasicInfo()}
      {getWarmUp()}
      {getContent()}
      {getScores()}
      {getSchedules()}
    </TabbedShowLayout>
  </Show>
);

export default WorkoutsShow;
