import * as React from 'react'
import {
  List,
  EditButton,
  Datagrid,
  TextField,
} from 'react-admin'

export const ManagersList = props => (
  <List {...props}>
    <Datagrid rowClick="edit" bulkActionButtons={false} >
      <TextField label="name" source="name" />
      <TextField label="email" source="email" />
      <EditButton />
    </Datagrid>
  </List>
)

export default ManagersList