import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  useLogin,
  useNotify,
  Notification,
  useTranslate,
} from 'react-admin'

import {
  Card,
  Avatar,
  CardActions,
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core'

import CoverImg from '../../assets/gym.jpg'
import { Lock } from '../../assets/icons'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: `url(${CoverImg})`,
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary[500],
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  button: {
    marginTop: '1em',
    width: '100%',
  },
  progress: {
    marginRight: theme.spacing(1),
  },
}))

export default function LoginPage({ value, onChange, onRemove, className }) {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const translate = useTranslate()
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const login = useLogin()
  const notify = useNotify()

  const submit = async e => {
    e.preventDefault()
    setLoading(true)

    await login({ email, password }).catch(e => {
      if (e?.response?.errors?.[0]?.extensions) {
        console.log(e.response.errors[0].extensions.code)

        if (e.response.errors[0].extensions.code === "WRONG_EMAIL") notify('ra.auth.wrong_user')
        else if (e.response.errors[0].extensions.code === "WRONG_PASSWORD") notify('ra.auth.wrong_password')
      }
      else notify('ra.auth.sign_in_error')
    })
    setLoading(false)
  }

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <div className={classes.avatar}>
          <Avatar className={classes.icon}>
            <Lock />
          </Avatar>
        </div>

        <form onSubmit={submit} className={classes.form}>
          <TextField
            fullWidth
            name="email"
            label="Email"
            variant="filled"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            className={classes.input}
          />
          <TextField
            fullWidth
            name="password"
            label="Senha"
            variant="filled"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            className={classes.input}
          />
          <CardActions>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              className={classes.button}
            >
              {loading && (
                <CircularProgress
                  className={classes.progress}
                  size={18}
                  thickness={2}
                />
              )}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
        </form>
      </Card>
      <Notification />
    </div>
  )
}
