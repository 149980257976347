const notifications = (axios) => ({
  list: (params) => axios.get("/admin/notifications/list", { params }),
  many: (ids) => axios.post("/admin/notifications/many", { ids }),

  create: (params) => axios.post("/admin/notifications", params),

  show: (notificationID) => axios.get(`/admin/notifications/${notificationID}`),
  delete: (notificationID) =>
    axios.delete(`/admin/notifications/${notificationID}`),
  update: (params) => axios.patch(`/admin/notifications/${params.id}`, params),
});

export default notifications;
